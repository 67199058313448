<template>
  <div class="card">
    <video
      :src="`${VITE_API_URL}/${videoOrChild.video}`"
      class="rounded overflow-hidden"
      controls
    />
    <div class="card-body">
      <div class="card-text text-bold">
        Type : {{ types[videoOrChild.type] ? types[videoOrChild.type] : type }}
      </div>
      <div class="card-text">
        Ajoutée le : {{ normalizer.formatDate(videoOrChild.created_at) }}
      </div>
      <div v-if="type === 'motivation'">
        <div class="card-text">
          Offre N° : {{ videoOrChild.offer_id }}
        </div>
        <div
          v-if="videoOrChild.status === 'to_validate'"
          class="card-text"
        >
          Status : A valider
        </div>
        <div
          v-if="videoOrChild.status === 'accepted'"
          class="card-text"
        >
          Status : Validée
        </div>
        <div
          v-if="videoOrChild.status === 'refused'"
          class="card-text"
        >
          Status : Refusée
        </div>
        <div class="d-flex justify-content-around mt-5">
          <div
            v-if="videoOrChild.status === 'to_validate'"
            class="btn btn-green me-1 mb-2 text-bold"
            data-bs-toggle="modal"
            :data-bs-target="'.validate-video-modal' + `${videoOrChild?.id}`"
            :disabled="verifyLoading"
          >
            <span
              v-if="verifyLoading"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            <span v-if="!verifyLoading">Valider</span>
          </div>
          <div
            v-if="videoOrChild.status === 'to_validate'"
            class="btn btn-danger me-1 mb-2 text-bold"
            data-bs-toggle="modal"
            :data-bs-target="'.refuse-video-modal' + `${videoOrChild?.id}`"
            :disabled="verifyLoading"
          >
            <span
              v-if="verifyLoading"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            <span v-if="!verifyLoading">Refuser</span>
          </div>
        </div>
        <Modal
          :class="'validate-video-modal' + `${videoOrChild.id}`"
          :title="'Validation de la vidéo de motivation'"
          :text="'Etes-vous sûr de vouloir valider cette vidéo de motivation ?'"
          :action-button="'Valider'"
          @callback="verifyVideo"
        />
        <Modal
          :class="'refuse-video-modal' + `${videoOrChild?.id}`"
          :title="'Validation de la vidéo de motivation'"
          :text="'Etes-vous sûr de vouloir refuser cette vidéo de motivation ?'"
          :action-button="'Valider'"
          :have-input="true"
          @callback="refuseVideo($event)"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import normalizer from '@/utils/normalizer';
import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import Modal from '@/components/modal/Modal.vue';

const route = useRoute();
const store = useStore();
const { VITE_API_URL } = import.meta.env;
const props = defineProps({
  data: Object,
  type: String,
});
const videoOrChild = computed(() => (props.data.child ? props.data.child : props.data));
const verifyLoading = computed(() => store.state.application.application_detail_loading);
const types = {
  video_presentation: 'Présentation',
  video_hobbies: 'Hobbies',
  video_experiences: 'Expériences',
  video_courses: 'Formations',
};

const verifyVideo = () => {
  store.dispatch('application/verifyVideo', {
    id: videoOrChild.value.id,
    onSuccess: () => {
      store.dispatch('candidate/getOne', route.params.id);
    },
  });
};
const refuseVideo = (value) => {
  store.dispatch('application/refuseVideo', {
    id: videoOrChild.value.id,
    description: value,
    onSuccess: () => {
      store.dispatch('candidate/getOne', route.params.id);
    },
  });
};
</script>
