<template>
  <div>
    <h2>Liste des administrateurs</h2>
    <nav
      class="mb-5"
      style="--bs-breadcrumb-divider: '•';"
      aria-label="breadcrumb"
    >
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="#">Accueil</a>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
        >
          Les administrateurs
        </li>
      </ol>
    </nav>
    <router-link :to="{ name : 'AdminCreate' }">
      <button class="btn btn-purple-gradient mb-3">
        Ajouter un admin
      </button>
    </router-link>
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col">
            Email
          </th>
          <th scope="col">
            Date de création du compte
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="admin in adminsList"
          :key="admin.id"
          class="align-middle"
        >
          <td>{{ admin.email }}</td>
          <td>{{ normalizer.formatDate(admin.created_at) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import {
  computed, onMounted, ref, watch,
} from 'vue';
import { useStore } from 'vuex';
import normalizer from '@/utils/normalizer';

const adminsList = ref([]);
const store = useStore();
const admins = computed(() => store.state.admin.admins.data);

watch(admins, (value) => {
  if (value) {
    adminsList.value = admins.value.data;
  }
});
onMounted(() => {
  store.dispatch('admin/getAll');
});
</script>
