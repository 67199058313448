<template>
  <div class="w-100 mb-5 bg-dark_grey rounded-3 p-5">
    <div class="row g-4">
      <div class="col col-12 col-md-6 col-sm-12 col-lg-3 ">
        <label class="form-label text-purple_grey text-bold">Date de début <Required /></label>
        <Datepicker
          v-model="formData.start_date"
          month-picker
          auto-apply
          locale="fr"
          dark
          mode-height="200"
          placeholder="mm/aaaa"
        />
        <span
          v-if="profilErrors?.start_date"
          class="text-danger"
        >{{ profilErrors?.start_date[0] }}</span>
      </div>
      <div
        v-if="!formData.actual"
        class="col col-12 col-md-6 col-sm-12 col-lg-3"
      >
        <label class="form-label text-purple_grey text-bold">Date de fin <Required /></label>
        <div class="input-group has-validation">
          <Datepicker
            v-model="formData.end_date"
            month-picker
            auto-apply
            locale="fr"
            dark
            mode-height="200"
            placeholder="mm/aaaa"
          />
          <span
            v-if="profilErrors?.end_date"
            class="text-danger"
          >{{ profilErrors?.end_date[0] }}</span>
        </div>
      </div>
      <div class="col col-12 col-md-6 col-sm-12 col-lg-3 p-2 mt-5">
        <div class="form-check form-switch">
          <input
            v-model="formData.actual"
            class="form-check-input custom-switch"
            type="checkbox"
          >
          <label class="form-check-label mx-2">
            <span class="text-purple_grey text-bold">
              Formation en cours
            </span>
          </label>
        </div>
      </div>
      <div class="col col-12 col-md-6 col-sm-12 col-lg-3 ">
        <label class="form-label text-purple_grey text-bold">Diplôme <Required /></label>
        <div class="input-group has-validation">
          <select
            v-model="formData.degree_level_id"
            :class="[{ 'invalid' : profilErrors?.degree_level_id }, 'form-select', 'w-100']"
            required
          >
            <option
              v-for="level in degreeLevel"
              :key="level.id"
              :value="level.id"
            >
              {{ level.name }}
            </option>
          </select>
          <span
            v-if="profilErrors?.degree_level_id"
            class="text-danger"
          >{{ profilErrors?.degree_level_id[0] }}</span>
        </div>
      </div>
      <div class="col col-12 col-md-6 col-sm-12 col-lg-3 ">
        <label class="form-label text-purple_grey text-bold">Nom du diplôme <Required /></label>
        <div class="input-group has-validation">
          <input
            v-model="formData.degree_name"
            :class="[{ 'invalid' : profilErrors?.degree_name }, 'form-control', 'w-100']"
            placeholder="Nom du diplôme"
            required
          >
          <span
            v-if="profilErrors?.degree_name"
            class="text-danger"
          >{{ profilErrors?.degree_name[0] }}</span>
        </div>
      </div>
      <div class="col col-12 col-md-6 col-sm-12 col-lg-3">
        <label class="form-label text-purple_grey text-bold">Nom de l'école <Required /></label>
        <div class="input-group has-validation">
          <input
            v-model="formData.university_name"
            :class="[{ 'invalid' : profilErrors?.university_name }, 'form-control', 'w-100']"
            placeholder="Nom de l'école"
          >
          <span
            v-if="profilErrors?.university_name"
            class="text-danger"
          >{{ profilErrors?.university_name[0] }}</span>
        </div>
      </div>
      <div class="col col-12 col-md-6 col-sm-12 col-lg-3 p-2 mt-5">
        <div class="form-check form-switch">
          <input
            v-model="formData.obtained"
            class="form-check-input custom-switch"
            type="checkbox"
          >
          <label class="form-check-label mx-2">
            <span class="text-purple_grey text-bold">
              Diplôme obtenu
            </span>
          </label>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-end mt-5">
      <div class="col-auto mt-3">
        <button
          class="btn btn-outline-danger"
          @click="emit('onDelete')"
        >
          Supprimer
        </button>
      </div>
      <div class="col-auto mt-3">
        <button
          class="btn btn-outline-light"
          @click="emit('addTraining')"
        >
          Ajouter une autre formation
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  computed, onMounted, ref, watch,
} from 'vue';
import Required from '@/components/Required.vue';
import { useStore } from 'vuex';

const store = useStore();

const degreeLevel = computed(() => store.state?.degreeLevel?.degree_levels?.data?.data);

const props = defineProps({
  trainingData: [Array, Object],
  existingCandidate: Boolean,
  profilErrors: Object,
});
const data = computed(() => props?.trainingData);

const formData = ref({ ...data.value });

const emit = defineEmits(['onDelete', 'addTraining', 'sendData']);
const onSend = () => {
  emit('sendData', formData.value);
};
watch(formData.value, (value) => {
  if (value) {
    onSend();
  }
});

onMounted(() => {
  store.dispatch('degreeLevel/getAll');
});
</script>
