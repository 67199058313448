import Api from './Api';

export default {
  async getConversations({ id, data }) {
    return Api.get(`api/messages/${id}/subscribe`, { params: { search: data } });
  },
  async disableConversation(id) {
    return Api.post(`api/conversations/${id}/disable`);
  },
  async enableConversation(id) {
    return Api.post(`api/conversations/${id}/enable`);
  },
  async getMessages(data) {
    return Api.get(`api/messages/${data.id}/fetch`, { params: { search: data.data } });
  },
  async saveMessage({ id, data }) {
    return Api.post(`api/messages/${id}/publish`, data);
  },
  async checkJitsiAccess(id) {
    return Api.get(`api/conversations/${id}/check-jitsi-access`);
  },
  async setting(data) {
    return Api.get('api/chats/setting', data);
  },
  async saveSetting(data) {
    return Api.post('api/chats/save-setting', data);
  },
  async banMessages(data) {
    return Api.get('api/chats/ban-messages', data);
  },
};
