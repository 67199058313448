import Subscriptions from '@/services/Subscription';

export default {
  async getAll({ commit }) {
    commit('setSubscriptionsLoading', true);
    try {
      const subscriptions = await Subscriptions.getAll();
      commit('setSubscriptions', subscriptions);
    } catch (error) {
      commit('setSubscriptionsErrors', error?.response?.data?.errors);
    }
    commit('setSubscriptionsLoading', false);
  },
};
