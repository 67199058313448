export default {
  setNotifications(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.notifications = value;
  },
  setNotificationsErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.notifications_errors = value;
  },
  setNotificationsLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.notifications_loading = value;
  },
  setNotificationDetail(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.notification_detail = value;
  },
  setNotificationDetailErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.notification_detail_errors = value;
  },
  setNotificationDetailLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.notification_detail_loading = value;
  },
};
