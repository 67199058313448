<template>
  <div>
    <h2>Liste des messages innappropriés</h2>
    <nav
      class="mb-5"
      style="--bs-breadcrumb-divider: '•';"
      aria-label="breadcrumb"
    >
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="#">Accueil</a>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
        >
          Les messages innappropriés
        </li>
      </ol>
    </nav>
    <router-link :to="{ name : 'ChatDetail' }">
      <button class="btn btn-purple-gradient mb-3">
        Les termes interdits
      </button>
    </router-link>
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col">
            Utilisateur
          </th>
          <th scope="col">
            Message
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="msg in banMessagesList"
          :key="msg.id"
        >
          <td v-if="msg.sender_id === `user-${msg.user_id}`">
            {{ `${msg.user.first_name} ${msg.user.second_name}` }}
          </td>
          <td v-else>
            {{ `${msg.recruiter.first_name} ${msg.recruiter.second_name}` }}
          </td>
          <td>{{ msg.message }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import {
  computed, onMounted, ref, watch,
} from 'vue';
import { useStore } from 'vuex';

const banMessagesList = ref([]);
const store = useStore();

const banMessages = computed(() => store.state.chat.banMessages.data);

watch(banMessages, (value) => {
  if (value?.data?.length) {
    banMessagesList.value = value.data;
  }
});
onMounted(() => {
  store.dispatch('chat/banMessages');
});
</script>
