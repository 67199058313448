<template>
  <div>
    <div
      class="candidate-profil-ban-full ban-full mb-5 bg-dark_grey "
    />
    <h1
      class="d-flex title-page text-black
     flex-column align-items-end justify-content-end position-relative"
    >
      <b class="text-uppercase">Mon profil</b>
    </h1>
    <div v-if="completionProgress.verified">
      <Info
        v-if="completionProgress.completedInformations
          && (
            completionProgress.status === 'to_validate'
            || completionProgress.status === 'profile_to_validate'
          )"
        :text="'Vos modifications sont soumises à validation par les administrateurs. ' +
          'Vous pouvez continuer à utiliser la plateforme avec vos anciennes informations'"
      />
    </div>
    <div v-else>
      <Info
        v-if="completionProgress.completedInformations
          && (
            completionProgress.status === 'to_validate'
            || completionProgress.status === 'profile_to_validate'
          )"
        :text="'Vos informations sont en attente de validation par les administrateurs. ' +
          'Une fois validées, vous pouvez utiliser toutes les fonctionnalités de la plateforme'"
      />
    </div>
    <ul
      id="myTab"
      class="nav nav-pills"
      role="tablist"
    >
      <li
        class="nav-item"
        role="presentation"
      >
        <button
          id="profil-tab"
          class="nav-link active"
          data-bs-toggle="tab"
          data-bs-target="#profil"
          type="button"
          role="tab"
          aria-controls="home"
          aria-selected="true"
        >
          <span>
            Mon profil
            <div class="line-primary" />
          </span>
        </button>
      </li>
      <li
        class="nav-item"
        role="presentation"
      >
        <button
          id="account-tab"
          class="nav-link"
          data-bs-toggle="tab"
          data-bs-target="#account"
          type="button"
          role="tab"
          aria-controls="profile"
          aria-selected="false"
        >
          <span>
            Mon compte
            <div class="line-primary" />
          </span>
        </button>
      </li>
    </ul>
    <CompletionProgress />
    <div class="d-flex justify-content-end">
      <span
        v-if="success && !profileLoading && !errorExist"
        class="text-green mt-1 me-3"
      >Modifications sauvegardées!</span>
      <span
        v-if="!profileLoading && errorExist"
        class="text-danger  mt-1 me-3"
      >Il y a une erreur dans le formulaire</span>
      <button
        v-if="isNotEmpty"
        class="btn btn-purple-gradient text-bold"
        :disabled="profileLoading"
        @click="update"
      >
        <span
          v-if="profileLoading"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
        Sauvegarder
      </button>
    </div>
    <div
      v-if="profileLoading"
      class="d-flex justify-content-center mt-5"
    >
      <div
        class="spinner-border"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div
      v-show="!profileLoading"
      id="myTabContent"
      class="tab-content mt-4"
    >
      <div
        id="profil"
        class="tab-pane fade show active"
        role="tabpanel"
        aria-labelledby="profil-tab"
      >
        <div class="accordion">
          <div class="accordion-item mb-2">
            <button
              :class="[{ 'border' : personnalInfoErrorExist },
                       'border-1', 'border-danger', 'border-none',
                       'accordion-button text-bold', 'bg-purple-gradient']"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse-personnal-informations"
              aria-controls="collapse-personnal-informations"
            >
              <h5 class="mb-0">
                INFORMATIONS PERSONNELLES
              </h5>
            </button>
            <hr>
            <div
              id="collapse-personnal-informations"
              class="accordion-collapse collapse show"
            >
              <PersonalInformations
                :existing-candidate="profile"
                @send-data="personnalInformationsData($event)"
                @error-exist="showPersonnalInformationError"
              />
            </div>
          </div>
          <div class="accordion-item mb-2">
            <button
              :class="[{ 'border' : coursesErrorExist },
                       'border-1', 'border-danger', 'border-none',
                       'accordion-button text-bold', 'bg-purple-gradient', 'collapsed']"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse-courses"
              aria-controls="collapse-courses"
            >
              <h5 class="mb-0">
                MES FORMATIONS
              </h5>
            </button>
            <hr>
            <div
              id="collapse-courses"
              class="accordion-collapse collapse"
            >
              <Courses
                :existing-candidate="profile?.data?.courses"
                @send-data="coursesData($event)"
                @error-exist="showCoursesError"
              />
            </div>
          </div>
          <div class="accordion-item mb-2">
            <button
              :class="[{ 'border' : experiencesErrorExist },
                       'border-1', 'border-danger', 'border-none',
                       'accordion-button text-bold', 'bg-purple-gradient', 'collapsed']"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse-experiences"
              aria-controls="collapse-experiences"
            >
              <h5 class="mb-0">
                MES EXPERIENCES
              </h5>
            </button>
            <hr>
            <div
              id="collapse-experiences"
              class="accordion-collapse collapse"
            >
              <Experiences
                :key="experiencesKey"
                :existing-candidate="profile?.data?.experiences"
                @send-data="experiencesData($event)"
                @error-exist="showExperiencesError"
              />
            </div>
          </div>
          <div class="accordion-item mb-2">
            <button
              :class="[{ 'border' : researchErrorExist },
                       'border-1', 'border-danger', 'border-none',
                       'accordion-button text-bold', 'bg-purple-gradient', 'collapsed']"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse-research"
              aria-controls="collapse-research"
            >
              <h5 class="mb-0">
                MA RECHERCHE
              </h5>
            </button>
            <hr>
            <div
              id="collapse-research"
              class="accordion-collapse collapse"
            >
              <Research
                :existing-candidate="profile?.data?.research"
                @send-data="researchData($event)"
                @error-exist="showResearchError"
              />
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <span
            v-if="success && !profileLoading && !errorExist"
            class="text-green mt-1 me-3"
          >Modifications sauvegardées!</span>
          <span
            v-if="!success && !profileLoading && errorExist"
            class="text-danger  mt-1 me-3"
          >Il y a une erreur dans le formulaire</span>
          <button
            v-if="isNotEmpty"
            class="btn btn-purple-gradient text-bold"
            :disabled="profileLoading"
            @click="update"
          >
            <span
              v-if="profileLoading"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            Sauvegarder
          </button>
        </div>
      </div>
      <div
        id="account"
        class="tab-pane fade"
        role="tabpanel"
        aria-labelledby="account-tab"
      >
        <Account />
      </div>
    </div>
  </div>
</template>

<script setup>
import PersonalInformations from '@/components/candidate/PersonalInformations.vue';
import Courses from '@/components/candidate/Courses.vue';
import Experiences from '@/components/candidate/Experiences.vue';
import Account from '@/components/candidate/Account.vue';
import Research from '@/components/candidate/Research.vue';
import {
  computed, onMounted, ref, watch,
} from 'vue';
import { useStore } from 'vuex';
import normalizer from '@/utils/normalizer';
import CompletionProgress from '@/components/completionProgress/CompletionProgress.vue';
import Info from '@/components/Info.vue';

const store = useStore();
const profile = computed(() => store.state.candidate?.candidate_detail);
const profileLoading = computed(() => store.state.candidate.candidate_detail_loading);
const completionProgress = computed(() => store.state.candidate.candidate_detail_completion);

const allInput = ref();
const isNotEmpty = ref(false);
const success = ref(false);

const formData = ref({
  research: {},
  first_name: null,
  second_name: null,
  birth_date: null,
  languages: [],
  licenses: [],
  city_id: null,
  phone_number: null,
  external_links: [],
  rqth: null,
  experiences: [],
  courses: [],
});
const picture = ref(null);

const checkIfFormDataIsEmpty = () => {
  let result = false;
  [...allInput.value].forEach((ele) => {
    if (ele.value.length !== 0
        && ele.value !== 'on'
        && ele.value !== '25000'
        && ele.value !== '50000'
        && ele.value !== '0') {
      result = true;
    }
  });

  if (formData.value?.licenses[0] || formData.value?.languages[0]
      || formData.value?.city_id || formData.value?.research?.job
      || formData.value?.research?.degree_level_id
      || formData.value?.research?.contract_types[0]
      || formData.value?.rqth || formData.value?.research?.available_now
  ) {
    result = true;
  }

  isNotEmpty.value = result;
};
watch(() => ({ ...formData.value }), (value) => {
  if (value) {
    checkIfFormDataIsEmpty();
  }
});

let resData = {};
const researchData = (data) => {
  formData.value.research = data;
  resData = data;
};
let expData = [];
const experiencesData = (data) => {
  expData = [...data.experiences];
  if (allInput.value) {
    checkIfFormDataIsEmpty();
  }
};
let courseData = [];
const coursesData = (data) => {
  courseData = [...data.courses];
  if (allInput.value) {
    checkIfFormDataIsEmpty();
  }
};

const persoData = {};
const personnalInformationsData = (data) => {
  formData.value = { ...data };
  persoData.first_name = data.first_name;
  persoData.second_name = data.second_name;
  persoData.birth_date = data.birth_date;
  persoData.languages = data.languages;
  persoData.licenses = data.licenses;
  persoData.address1 = data.address1;
  persoData.address2 = data.address2;
  persoData.zip_code = data.zip_code;
  persoData.city_name = data.city_name;
  persoData.city = data.city;
  persoData.city_id = data.city_id;
  persoData.phone_number = data.phone_number;
  persoData.external_links = data.external_links;
  persoData.rqth = data.rqth;
  picture.value = data.picture;
};

const personnalInfoErrorExist = ref(false);
const researchErrorExist = ref(false);
const coursesErrorExist = ref(false);
const experiencesErrorExist = ref(false);

const errorExist = computed(() => personnalInfoErrorExist.value
    || researchErrorExist.value
    || coursesErrorExist.value
    || experiencesErrorExist.value);

const showPersonnalInformationError = () => {
  personnalInfoErrorExist.value = true;
};
const showResearchError = () => {
  researchErrorExist.value = true;
};
const showCoursesError = () => {
  coursesErrorExist.value = true;
};
const showExperiencesError = () => {
  experiencesErrorExist.value = true;
};

const experiencesKey = ref(1);
const update = () => {
  const tempFormData = {
    ...persoData, research: resData, experiences: [], courses: [],
  };
  for (let i = 0; i < expData.length; i += 1) {
    const {
      start_date, end_date, actual, contract_type_id,
      job_title, company_name, recommendation, document,
    } = expData[i];
    tempFormData.experiences[i] = {
      start_date:
          start_date?.year !== null && start_date?.month !== null
            ? new Date(start_date?.year, start_date?.month).toDateString()
            : null,
      end_date:
          end_date?.year !== null && end_date?.month !== null
            ? new Date(end_date?.year, end_date?.month).toDateString()
            : null,
      actual,
      contract_type_id,
      job_title,
      company_name,
      recommendation,
      document,
    };
  }
  for (let i = 0; i < courseData.length; i += 1) {
    const {
      start_date, end_date, actual, degree_name, degree_level_id,
      university_name, obtained,
    } = courseData[i];
    tempFormData.courses[i] = {
      start_date:
          start_date?.year !== null && start_date?.month !== null
            ? new Date(start_date?.year, start_date?.month).toDateString()
            : null,
      end_date:
          end_date?.year !== null && end_date?.month !== null
            ? new Date(end_date?.year, end_date?.month).toDateString()
            : null,
      actual,
      degree_name,
      degree_level_id,
      university_name,
      obtained,
    };
    if (tempFormData.courses[i].actual) {
      delete tempFormData.courses[i].end_date;
    }
  }
  if (!tempFormData.external_links?.length) {
    delete tempFormData.external_links;
  }
  if (!tempFormData.licenses?.[0]) {
    delete tempFormData.licenses;
  }
  if (!tempFormData.address2) {
    delete tempFormData.address2;
  }
  for (let i = 0; i < tempFormData.experiences.length; i += 1) {
    if (tempFormData.experiences[i]?.actual) {
      delete tempFormData.experiences[i].end_date;
    }

    if (tempFormData.experiences[i].recommendation
        && (!('can_contact_manager' in tempFormData.experiences[i].recommendation)
            || tempFormData.experiences[i].recommendation.can_contact_manager === false)
    ) {
      delete tempFormData.experiences[i].recommendation.manager_email;
      delete tempFormData.experiences[i].recommendation.manager_name;
      delete tempFormData.experiences[i].recommendation.manager_position;
      delete tempFormData.experiences[i].recommendation.manager_phone;
    }
  }

  const result = normalizer.makeFormDataWithObject(tempFormData);
  if (typeof picture.value !== 'string') {
    result.append('picture', picture.value);
  } else {
    result.append('picture', picture.value.replace('/storage', 'public'));
  }
  for (let i = 0; i < tempFormData.experiences.length; i++) {
    if (tempFormData.experiences[i].document && typeof tempFormData.experiences[i].document.document !== 'string') {
      result.append(`experiences[${i}][recommendation][document]`, tempFormData.experiences[i].document.document);
      result.append(`experiences[${i}][recommendation][document_already_exist]`, false);
    } else if (tempFormData.experiences[i]?.recommendation?.document) {
      result.append(`experiences[${i}][recommendation][document_already_exist]`, true);
    } else {
      result.delete(`experiences[${i}][recommendation]`);
      result.delete(`experiences[${i}][recommendation][can_contact_manager]`);
    }
  }

  const competionProfilElt = document.querySelector('.ban-full');
  const competionProfilEltCoord = competionProfilElt.getBoundingClientRect();
  const toScrollY = competionProfilEltCoord.bottom + window.scrollY;
  window.scroll({ top: toScrollY, behavior: 'smooth' });

  store.dispatch('candidate/update', {
    data: result,
    onSuccess: () => {
      experiencesKey.value += 1;
      success.value = true;
      store.dispatch('candidate/showProfile');
      store.dispatch('candidate/getCompletionProgress');
      personnalInfoErrorExist.value = false;
      researchErrorExist.value = false;
      coursesErrorExist.value = false;
      experiencesErrorExist.value = false;
    },
  });

  personnalInfoErrorExist.value = false;
  researchErrorExist.value = false;
  coursesErrorExist.value = false;
  experiencesErrorExist.value = false;
};

onMounted(() => {
  store.dispatch('candidate/showProfile');
  store.dispatch('candidate/getCompletionProgress');
  success.value = false;
  allInput.value = document.querySelectorAll('input');
});
</script>
