<template>
  <div class="w-100 bg-dark_grey rounded-3 mb-5 p-5">
    <div class="row g-4">
      <div class="col col-12 col-md-6 col-sm-12 col-lg-3 ">
        <label class="form-label text-purple_grey text-bold">Intitulé du poste <Required /></label>
        <div class="input-group has-validation">
          <input
            v-model="formData.job_title"
            :class="[{ 'invalid' : profilErrors?.job_title }, 'form-control', 'w-100']"
            placeholder="Intitulé du poste"
            required
          >
          <span
            v-if="profilErrors?.job_title"
            class="text-danger"
          >{{ profilErrors?.job_title[0] }}</span>
        </div>
      </div>
      <div class="col col-12 col-md-6 col-sm-12 col-lg-3">
        <label class="form-label text-purple_grey text-bold">
          Nom de l'entreprise <Required /></label>
        <div class="input-group has-validation">
          <input
            v-model="formData.company_name"
            :class="[{ 'invalid' : profilErrors?.company_name }, 'form-control', 'w-100']"
            placeholder="Nom de l'entreprise"
          >
          <span
            v-if="profilErrors?.company_name"
            class="text-danger"
          >{{ profilErrors?.company_name[0] }}</span>
        </div>
      </div>
      <div class="col col-12 col-md-6 col-sm-12 col-lg-3 ">
        <label class="form-label text-purple_grey text-bold">Date de début <Required /></label>
        <Datepicker
          v-model="formData.start_date"
          month-picker
          auto-apply
          locale="fr"
          dark
          mode-height="200"
          placeholder="mm/aaaa"
        />
        <span
          v-if="profilErrors?.start_date"
          class="text-danger"
        >{{ profilErrors?.start_date[0] }}</span>
      </div>
      <div
        v-if="!formData.actual"
        class="col col-12 col-md-6 col-sm-12 col-lg-3"
      >
        <label class="form-label text-purple_grey text-bold">Date de fin <Required /></label>
        <div

          class="input-group has-validation"
        >
          <Datepicker
            v-model="formData.end_date"
            month-picker
            auto-apply
            locale="fr"
            dark
            mode-height="200"
            select-text="Choisir"
            cancel-text="Annuler"
            placeholder="mm/aaaa"
          />
          <span
            v-if="profilErrors?.end_date"
            class="text-danger"
          >{{ profilErrors?.end_date[0] }}</span>
        </div>
      </div>
      <div class="col col-12 col-md-6 col-sm-12 col-lg-3 mt-5">
        <div class="form-check form-switch">
          <input
            v-model="formData.actual"
            class="form-check-input custom-switch"
            type="checkbox"
          >
          <label class="form-check-label mx-2">
            <span class="text-purple_grey text-bold">
              J'occupe actuellement ce poste
            </span>
          </label>
        </div>
      </div>
      <div class="col col-12 col-md-6 col-sm-12 col-lg-3">
        <label class="form-label text-purple_grey text-bold">Type de contrat <Required /></label>
        <div class="input-group has-validation">
          <select
            v-model="formData.contract_type_id"
            :class="[{ 'invalid' : profilErrors?.contract_type_id }, 'form-select', 'w-100']"
          >
            <option
              v-for="type in contractTypes"
              :key="type.id"
              :value="type.id"
            >
              {{ type.name }}
            </option>
          </select>
          <span
            v-if="profilErrors?.contract_type_id"
            class="text-danger"
          >{{ profilErrors?.contract_type_id[0] }}</span>
        </div>
      </div>
      <div class="col col-12 col-md-6 col-sm-12 col-lg-3">
        <label class="form-label text-purple_grey text-bold">Lettre de recommandation</label>
        <div class="input-group has-validation">
          <input
            v-if="!formData?.recommendation?.document"
            :class="[{ 'invalid' : profilErrors?.document }, 'form-control', 'w-100']"
            type="file"
            placeholder="Lettre de recommandation"
            @change="changeDocument"
          >
          <div v-if="formData?.recommendation?.document">
            <p>
              <a

                :href="formData?.recommendation?.document ?
                  `${VITE_API_URL}/${formData?.recommendation?.document}` :
                  `${VITE_API_URL}/${document}`"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>
                  <img
                    src="@/assets/images/icons/search.svg"
                    alt="Ouvrir la recommandation"
                    class="me-2"
                  >
                  Ouvrir la recommandation
                </span>
              </a>
              <button

                type="button"
                class="btn-close btn-close-white me-2"
                @click="removeDocument"
              />
            </p>
          </div>
          <span
            v-if="profilErrors?.recommendation?.document"
            class="text-danger"
          >{{ profilErrors?.recommendation?.document[0] }}</span>
        </div>
      </div>
      <div
        v-if="formData?.recommendation?.document || document"
        class="col col-12 col-md-6 col-sm-12 col-lg-3 p-2 mt-5"
      >
        <div class="form-check form-switch">
          <input
            v-model="formData.recommendation.can_contact_manager"
            class="form-check-input custom-switch"
            type="checkbox"
          >
          <label class="form-check-label mx-2">
            <span class="text-purple_grey text-bold">
              J'autorise que mon responsable soit contacté
            </span>
          </label>
        </div>
      </div>
    </div>
    <div
      v-if="(document || formData?.recommendation?.document ) &&
        formData?.recommendation?.can_contact_manager"
      class="row g-4 mb-5"
    >
      <div class="col col-12 col-md-6 col-sm-12 col-lg-3">
        <label class="form-label text-purple_grey text-bold">
          Email du responsable <Required />
        </label>
        <div class="input-group has-validation">
          <input
            v-model="formData.recommendation.manager_email"
            type="email"
            :class="[{ 'invalid' : profilErrors?.recommendation?.manager_email },
                     'form-control', 'w-100']"
            placeholder="Email du responsable"
          >
          <span
            v-if="profilErrors?.recommendation?.manager_email"
            class="text-danger"
          >{{ profilErrors?.recommendation?.manager_email[0] }}</span>
        </div>
      </div>
      <div class="col col-12 col-md-6 col-sm-12 col-lg-3">
        <label class="form-label text-purple_grey text-bold">
          Nom et prénom du responsable <Required />
        </label>
        <div class="input-group has-validation">
          <input
            v-model="formData.recommendation.manager_name"
            type="email"
            :class="[{ 'invalid' : profilErrors?.recommendation?.manager_name },
                     'form-control', 'w-100']"
            placeholder="Nom et prénom du responsable"
          >
          <span
            v-if="profilErrors?.recommendation?.manager_name"
            class="text-danger"
          >{{ profilErrors?.recommendation?.manager_name[0] }}</span>
        </div>
      </div>
      <div class="col col-12 col-md-6 col-sm-12 col-lg-3">
        <label class="form-label text-purple_grey text-bold">
          Poste du responsable <Required />
        </label>
        <div class="input-group has-validation">
          <input
            v-model="formData.recommendation.manager_position"
            type="email"
            :class="[{ 'invalid' : profilErrors?.recommendation?.manager_position },
                     'form-control', 'w-100']"
            placeholder="Poste du responsable"
          >
          <span
            v-if="profilErrors?.recommendation?.manager_position"
            class="text-danger"
          >{{ profilErrors?.recommendation?.manager_position[0] }}</span>
        </div>
      </div>
      <div class="col col-12 col-md-6 col-sm-12 col-lg-3">
        <label class="form-label text-purple_grey text-bold">
          Téléphone du responsable <Required />
        </label>
        <div class="input-group has-validation">
          <input
            v-model="formData.recommendation.manager_phone"
            type="email"
            :class="[{ 'invalid' : profilErrors?.recommendation?.manager_phone },
                     'form-control', 'w-100']"
            placeholder="Téléphone du responsable"
            @blur="focusPhoneNumberOut($event)"
            @focus="focusPhoneNumberIn($event)"
          >
          <span
            v-if="profilErrors?.recommendation?.manager_phone"
            class="text-danger"
          >{{ profilErrors?.recommendation?.manager_phone[0] }}</span>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-end">
      <div class="col-auto mt-3">
        <button
          class="btn btn-outline-danger"
          @click="emit('onDelete')"
        >
          Supprimer
        </button>
      </div>
      <div class="col-auto mt-3">
        <button

          class="btn btn-outline-light"
          @click="emit('addExperience')"
        >
          Ajouter une autre expérience
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import {
  computed, onMounted, ref, watch,
} from 'vue';
import Required from '@/components/Required.vue';
import normalizer from '@/utils/normalizer';

const store = useStore();
const contractTypes = computed(() => store.state?.contractType?.contract_types?.data?.data);
const props = defineProps({
  experienceData: [Array, Object],
  index: Number,
  existingCandidate: Boolean,
  profilErrors: Object,
});

const data = computed(() => props?.experienceData);

const { VITE_API_URL } = import.meta.env;

const formData = ref({
  ...data.value,
});

const emit = defineEmits(['onDelete', 'addTraining', 'sendData']);

const onSend = () => {
  emit('sendData', formData.value);
};

watch(formData.value, (value) => {
  if (value) {
    onSend();
  }
});

const document = ref(null);

const removeDocument = () => {
  document.value = null;
  delete formData.value.recommendation;
  onSend();
};

const changeDocument = (event) => {
  if (event.target.files.length > 0) {
    formData.value.recommendation
      ? formData.value.recommendation
      : formData.value.recommendation = { can_contact_manager: false };
    document.value = { index: props.index, document: event.target.files[0] };
    formData.value.document = document.value;
    onSend();
  }
  if (event.target.files.length === 0) {
    removeDocument();
    formData.value.document = null;
  }
};

const rawPhoneNumber = ref(formData.value.recommendation.manager_phone);
const focusPhoneNumberOut = (e) => {
  rawPhoneNumber.value = e.target.value;
  e.target.value = normalizer.formatPhoneNumber(e.target.value);
};
const focusPhoneNumberIn = (e) => {
  e.target.value = rawPhoneNumber.value;
};

onMounted(() => {
  store.dispatch('contractType/getAll');
  formData.value.recommendation.manager_phone = normalizer.formatPhoneNumber(
    formData.value.recommendation.manager_phone,
  );
});
</script>
