import Api from './Api';

export default {
  async getAll(page) {
    return Api.get('api/companies', { params: page });
  },
  async getAllNotVerified(page) {
    return Api.get('api/companies?unverified=true', { params: { ...page, unverified: true } });
  },
  async getOne(id) {
    return Api.get(`api/companies/${id}`);
  },
  async showProfile() {
    return Api.get('api/profile');
  },
  async update(data) {
    return Api.post('api/profile', data, { headers: { 'Content-Type': 'multipart/form-data' } });
  },
  async modify(data) {
    return Api.patch(`api/companies/${data.company_id}`, data);
  },
  async verify(id) {
    return Api.post(`api/companies/${id}/verify`);
  },
  async refuse(data) {
    return Api.post(`api/companies/${data.id}/refuse`, { reasons: data.description });
  },
  async deactivate(id) {
    return Api.post(`api/companies/${id}/deactivate`);
  },
  async delete(id) {
    return Api.delete(`api/companies/${id}`);
  },
  async addRecruiter(data) {
    return Api.post('api/recruiter/create', data);
  },
  async getAllRecruiters() {
    return Api.get('api/recruiter');
  },
};
