export default {
  matchings: [],
  matchings_errors: [],
  matchings_loading: false,
  matching_detail: {},
  matching_detail_errors: [],
  matching_detail_loading: false,
  chat: {},
  create_chat_loading: false,
  create_chat_error: [],
  my_chat_loading: {},
  my_chat: {},
  my_chat_error: null,
};
