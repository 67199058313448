export default {
  setCandidates(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.candidates = value;
  },
  setCandidatesErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.companies_errors = value;
  },
  setCandidatesLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.companies_loading = value;
  },
  setCandidateDetail(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.candidate_detail = value;
  },
  setCandidateDetailErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.candidate_detail_errors = value;
  },
  setCandidateDetailLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.candidate_detail_loading = value;
  },
  setCandidateDetailCompletion(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.candidate_detail_completion = value;
  },
  setCandidateDetailCompletionLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.candidate_detail_completion_loading = value;
  },
  setCandidateDetailCompletionErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.candidate_detail_completion_errors = value;
  },
};
