import { createApp } from 'vue';
import vSelect from 'vue-select';
import VueDatepickerUi from 'vue-datepicker-ui';
import ChatWindow from 'vue-advanced-chat';
import Datepicker from '@vuepic/vue-datepicker';
import 'vue-select/dist/vue-select.css';
import 'vue-datepicker-ui/lib/vuedatepickerui.css';
import 'vue-advanced-chat/dist/vue-advanced-chat.css';
import '@vuepic/vue-datepicker/dist/main.css';
import App from './App.vue';
import router from './router';
import store from './store';
import 'bootstrap';
import '@/assets/scss/app.scss';

createApp(App)
  .use(router)
  .use(store)
  .component('v-select', vSelect)
  .component('Datepicker', VueDatepickerUi)
  .component('ChatWindow', ChatWindow)
  .component('Datepicker', Datepicker)
  .mount('#app');
