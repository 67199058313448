import Csrf from '@/services/Csrf';
import User from '@/services/User';
import router from '@/router';

export default {
  async login({ commit }, data) {
    commit('setAuthenticationLoading', true);
    try {
      await Csrf.getCookie();
      await User.logout(data.data.guard);
      await User.login(data.data);
      const user = await User.checkAuthenticated();
      localStorage.setItem('user', JSON.stringify(user));
      commit('setUser', user);
      commit('setUnreadMessages', user.data.unread_messages > 0);
      commit('setAuthenticated', true);
      data.onSuccess();
      localStorage.setItem('authenticated', 'true');
      localStorage.setItem('scope', data.data.guard);
    } catch (error) {
      commit('setAuthenticated', false);
      commit('setAuthenticationErrors', error?.response?.data?.errors);
    }
    commit('setAuthenticationLoading', false);
  },
  async logout({ commit }, { onSuccess = () => {} }) {
    try {
      if (localStorage.getItem('scope')) {
        await User.logout(localStorage.getItem('scope'));
      } else {
        await User.logout('user');
        await User.logout('recruiter');
        await User.logout('admin');
      }
      commit('setUser', {});
      commit('setAuthenticated', false);
      onSuccess();
      localStorage.setItem('authenticated', 'false');
      localStorage.removeItem('scope');
      localStorage.removeItem('user');
    } catch (error) {
      //
    }
  },
  async register({ commit }, data) {
    commit('setRegisterLoading', true);
    try {
      await Csrf.getCookie();
      await User.logout(data.data.guard);
      const user = await User.register(data.data);
      commit('setUser', user);
      data.onSuccess();
      localStorage.setItem('authenticated', 'true');
      localStorage.setItem('scope', data.data.guard);
    } catch (error) {
      commit('setRegisterErrors', error?.response?.data?.errors);
    }
    commit('setRegisterLoading', false);
  },
  async forgotPassword({ commit }, formData) {
    commit('setForgotPasswordLoading', true);
    try {
      commit('setForgotPasswordSuccess', false);
      const data = await User.forgotPassword(formData);
      commit('setForgotPasswordSuccess', data);
      commit('setForgotPasswordErrors', []);
      formData.onSuccess();
    } catch (error) {
      commit('setForgotPasswordSuccess', false);
      commit('setForgotPasswordErrors', error?.response?.data?.errors);
    }
    commit('setForgotPasswordLoading', false);
  },
  async resetPassword({ commit }, formData) {
    commit('setResetPasswordLoading', true);
    try {
      await commit('setResetPasswordSuccess', false);
      const data = await User.resetPassword(formData);
      commit('setResetPasswordSuccess', data);
      commit('setResetPasswordErrors', []);
    } catch (error) {
      commit('setResetPasswordSuccess', false);
      commit('setResetPasswordErrors', error?.response?.data?.errors);
    }
    commit('setResetPasswordLoading', false);
  },
  async checkAuthenticated({ commit }) {
    try {
      const user = await User.checkAuthenticated();
      localStorage.setItem('user', JSON.stringify(user));
      commit('setAuthenticated', true);
      commit('setUnreadMessages', user.data.unread_messages > 0);
      localStorage.setItem('authenticated', 'true');
    } catch (error) {
      if (error.response.status === 401) {
        commit('setAuthenticated', false);
        localStorage.setItem('authenticated', 'false');
        localStorage.removeItem('scope');
        localStorage.removeItem('user');
        // Redirect to home if user is unauthenticated and trying to access an auth route
        if (router?.currentRoute?.value?.meta?.private) {
          await router.push({ name: 'Home' });
        }
      }
    }
  },
  async verifyEmail({ commit }, url) {
    commit('setEmailVerifiedLoading', true);
    try {
      await User.verifyEmail(url);
      localStorage.setItem('scope', url.scope);
      await Csrf.getCookie();
      commit('setEmailVerified', true);
    } catch (error) {
      commit('setEmailVerified', false);
    }
    commit('setEmailVerifiedLoading', false);
  },
  async resendVerifyEmail({ commit }, data) {
    commit('setEmailVerifiedLoading', true);
    try {
      await User.resendVerifyEmail(data.scope);
      await Csrf.getCookie();
      commit('setEmailVerified', true);
      data.onSuccess();
    } catch (error) {
      commit('setEmailVerified', false);
      data.onFail();
    }
    commit('setEmailVerifiedLoading', false);
  },
};
