import Api from './Api';

export default {
  async getAll(data) {
    return Api.get(`api/offers/${data.offer_id}/matchings/${data.matching_id}/comments`);
  },
  async create(data) {
    return Api.post(`api/offers/${data.offer_id}/matchings/${data.matching_id}/comments`, { comment: data.data });
  },
};
