<template>
  <div>
    <h2>Liste des candidats</h2>
    <nav
      style="--bs-breadcrumb-divider: '•';"
      aria-label="breadcrumb"
    >
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="#">Accueil</a>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
        >
          Les candidats
        </li>
      </ol>
    </nav>
    <button
      class="btn btn-light mb-5"
      @click="toggleAll"
    >
      {{ displayAll ? 'Afficher les candidats à valider uniquement' :
        'Afficher tous les candidats' }}
    </button>
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col">
            Photo
          </th>
          <th scope="col">
            Prénom
          </th>
          <th scope="col">
            Nom
          </th>
          <th scope="col">
            Date de création du compte
          </th>
          <th scope="col">
            Statut d'activation
          </th>
          <th scope="col">
            Détail
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="candidate in candidates"
          :key="candidate.id"
          class="align-middle"
        >
          <td>
            <div class="photo-profile">
              <img
                :src="`${VITE_API_URL}/${candidate.picture}`"
                alt="Photo de profile"
              >
            </div>
          </td>
          <td>{{ candidate?.first_name }}</td>
          <td>{{ candidate?.second_name }}</td>
          <td>{{ normalizer.formatDate(candidate?.created_at) }}</td>
          <td>
            <candidate-badge
              :status="
                { verified : candidate?.status?.user_verified,
                  deactivated : candidate?.status?.user_deactivated,
                  profile_status : candidate?.status?.profile_status,
                  matching_video : candidate?.status?.matching_video_status === 'to_validate' }"
            />
          </td>
          <td>
            <router-link :to="{ name: 'CandidateDetail', params: { id : candidate.id } }">
              <button class="btn btn-purple-gradient text-bold">
                Voir
              </button>
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
    <Pagination
      :meta="candidatesPaginate"
      @changePage="changePage($event)"
    />
  </div>
</template>

<script setup>
import {
  computed, onMounted, ref,
} from 'vue';
import { useStore } from 'vuex';
import normalizer from '@/utils/normalizer';
import CandidateBadge from '@/components/badge/CandidateBadge.vue';
import Pagination from '@/components/pagination/Pagination.vue';

const store = useStore();

const candidates = computed(() => store.state?.candidate?.candidates?.data?.data);
const candidatesPaginate = computed(() => store.state?.candidate?.candidates?.data?.meta);
const displayAll = ref(false);

const { VITE_API_URL } = import.meta.env;

const changePage = (p) => {
  if (displayAll.value) {
    store.dispatch('candidate/getAll', { page: p });
  } else {
    store.dispatch('candidate/getAllNotVerified', { page: p });
  }
};

const toggleAll = () => {
  displayAll.value = !displayAll.value;
  if (displayAll.value) {
    store.dispatch('candidate/getAll', { page: 1 });
  } else {
    store.dispatch('candidate/getAllNotVerified', { page: 1 });
  }
};

onMounted(() => {
  store.dispatch('candidate/getAllNotVerified', { page: 1 });
});
</script>
