<template>
  <nav>
    <ul class="pagination justify-content-end">
      <li class="page-item">
        <a
          class="page-link"
          href="#"
          aria-label="Previous"
          @click="changingPage(meta?.current_page - 1)"
        >
          <span aria-hidden="true">&laquo;</span>
        </a>
      </li>
      <li
        v-for="(item, index) in meta?.last_page"
        :key="index"
        :class="[{ 'active' : meta?.current_page === index + 1 },'page-item']"
      >
        <a
          class="page-link"
          href="#"
          @click="changingPage(index +1)"
        >
          {{ index +1 }}
        </a>
      </li>
      <li class="page-item">
        <a
          class="page-link"
          href="#"
          aria-label="Next"
          @click="meta?.current_page !== meta?.last_page ?
            changingPage(meta?.current_page + 1) : ''"
        >
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script setup>
const props = defineProps({
  meta: Object,
});
const emit = defineEmits([
  'changePage',
]);

const changingPage = (p) => {
  emit('changePage', p);
};

</script>
