export default {
  setNotes(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.notes = value;
  },
  setNotesErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.notes_errors = value;
  },
  setNotesLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.notes_loading = value;
  },
};
