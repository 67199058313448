export default {
  companies: [],
  companies_errors: [],
  companies_loading: false,
  company_detail: {},
  company_detail_errors: [],
  company_detail_loading: false,
  recruiters: [],
  recruiters_errors: [],
  recruiters_loading: false,
  add_recruiter_errors: [],
  add_recruiter_message_error: null,
  add_recruiter_loading: false,
};
