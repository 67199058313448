export default {
  setLicenses(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.licenses = value;
  },
  setLicensesErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.licenses_errors = value;
  },
  setLicensesLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.licenses_loading = value;
  },
};
