<template>
  <div>
    <h2>Les offres</h2>
    <nav
      class="mb-5"
      style="--bs-breadcrumb-divider: '•';"
      aria-label="breadcrumb"
    >
      <ol class="breadcrumb">
        <li
          class="breadcrumb-item"
          aria-current="page"
        >
          <router-link :to="{ name: 'CompanyOffersList' }">
            Mes recrutements
          </router-link>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
        >
          {{ bearCrumb }}
        </li>
      </ol>
    </nav>
    <h4 class="text-primary text-bold">
      {{ title }}
    </h4>
    <hr class="w-100 mb-5">
    <div
      v-if="offerDataLoading"
      class="d-flex justify-content-center mt-5"
    >
      <div
        class="spinner-border"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div
      v-else
      class="row g-4"
    >
      <div class="row mt-5 mb-4">
        <div class="col col-12 col-sm-6 col-md-4 col-lg-3">
          <label class="form-label text-purple_grey text-bold">Intitulé du poste <Required /></label>

          <input
            v-model="formData.title"
            :class="[{ 'invalid' : offerCreateErrors?.title }, 'form-control', 'w-100']"
            placeholder="Intitulé du poste"
            required
          >
          <span
            v-if="offerCreateErrors?.title"
            class="text-danger"
          >{{ offerCreateErrors?.title[0] }}</span>
        </div>
        <div class="col col-12 col-sm-6 col-md-6 col-lg-6">
          <SearchJobs
            :errors="offerCreateErrors?.jobs"
            :multiple="true"
            :existing-job="formData.jobs"
            @send-job="sendJob($event)"
          />
        </div>
        <div class="col col-12 col-sm-6 col-md-4 col-lg-3 mt-4">
          <div class="form-check form-switch mt-2">
            <label class="form-check-label ms-2">
              <span class="text-purple_grey text-bold">
                Vidéos de présentation obligatoires
              </span>
            </label>
            <input
              v-model="formData.p_videos_required"
              class="form-check-input custom-switch"
              type="checkbox"
            >
            <span
              v-if="offerCreateErrors?.p_videos_required"
              class="text-danger"
            > <br> {{ offerCreateErrors?.p_videos_required[0] }}</span>
          </div>
        </div>
      </div>
      <div class="col col-12 col-sm-6 col-md-4 col-lg-3">
        <label class="form-label text-purple_grey text-bold">Télétravail <Required /></label>
        <select
          v-model="formData.telework"
          :class="[{ 'invalid' : offerCreateErrors?.telework }, 'form-select', 'w-100']"
        >
          <option
            v-for="option in teleworkOptions"
            :key="option.key"
            :value="option.key"
          >
            {{ option.name }}
          </option>
        </select>
        <span
          v-if="offerCreateErrors?.telework"
          class="text-danger"
        > <br> {{ offerCreateErrors?.telework[0] }}</span>
      </div>

      <div
        v-if="formData.telework !== 'full'"
        class="col col-12 col-sm-6 col-md-4 col-lg-3"
      >
        <SearchCities
          :errors="offerCreateErrors?.city_id"
          :existing-city="formData.city"
          @sendCity="sendCity($event)"
        />
      </div>
      <div
        v-if="formData.telework !== 'full'"
        class="col col-12 col-sm-6 col-md-4 col-lg-3"
      >
        <label class="form-label text-purple_grey text-bold">
          Ville
        </label>
        <input
          v-model="formData.city_name"
          :class="['form-control', 'w-100']"
          disabled
        >
      </div>
      <div
        v-if="formData.telework !== 'full'"
        class="col col-12 col-sm-6 col-md-4 col-lg-3"
      >
        <label class="form-label text-purple_grey text-bold">
          Rayon autour de la ville : {{ formData.radius }} km
        </label>
        <input
          v-model="formData.radius"
          type="range"
          class="form-range"
          min="0"
          max="200"
          step="10"
        >
        <span
          v-if="offerCreateErrors?.radius"
          class="text-danger"
        >{{ offerCreateErrors?.radius[0] }}</span>
      </div>
      <div class="row g-4">
        <div class="col col-12 col-sm-6 col-md-4 col-lg-3">
          <label class="form-label text-purple_grey text-bold">
            Salaire annuel minimum désiré : {{ formData.salary_minimum }}€
          </label>
          <input
            v-model="formData.salary_minimum"
            type="range"
            class="form-range"
            min="15000"
            :max="formData.salary_maximum"
            step="1000"
          >
          <span
            v-if="offerCreateErrors?.salary_minimum"
            class="text-danger"
          >{{ offerCreateErrors?.salary_minimum[0] }}</span>
        </div>
        <div class="col col-12 col-sm-6 col-md-4 col-lg-3">
          <label class="form-label text-purple_grey text-bold">
            Salaire annuel maximum désiré : {{ formData.salary_maximum }}€
          </label>
          <input
            v-model="formData.salary_maximum"
            type="range"
            class="form-range"
            :min="formData.salary_minimum"
            max="50000"
            step="1000"
          >
          <span
            v-if="offerCreateErrors?.salary_maximum"
            class="text-danger"
          >{{ offerCreateErrors?.salary_maximum[0] }}</span>
        </div>
        <div class="col col-12 col-sm-6 col-md-4 col-lg-3">
          <label class="form-label text-purple_grey text-bold">Avantages</label>
          <div class="input-group has-validation">
            <textarea
              v-model="formData.advantages"
              :class="[{ 'invalid' : offerCreateErrors?.advantages }, 'form-control', 'w-100']"
              placeholder="Avantages (300 caractères max)"
              maxlength="300"
            />
            <span
              v-if="offerCreateErrors?.advantages"
              class="text-danger"
            >{{ offerCreateErrors?.advantages[0] }}</span>
          </div>
        </div>
        <div class="col col-12 col-sm-6 col-md-4 col-lg-3">
          <label class="form-label text-purple_grey text-bold">Type de contrat <Required /></label>
          <div class="input-group has-validation">
            <v-select
              v-model="formData.contract_types"
              :class="[{ 'invalid' : offerCreateErrors?.contract_type }, 'w-100']"
              label="name"
              multiple
              value="option"
              :options="contractTypes"
            />
            <span
              v-if="offerCreateErrors?.contract_types"
              class="text-danger"
            >{{ offerCreateErrors?.contract_types[0] }}</span>
          </div>
        </div>
      </div>
      <div class="col col-12 col-sm-6 col-md-4 col-lg-3">
        <label class="form-label text-purple_grey text-bold">
          Vidéo de présentation de l'offre <Required />
        </label>
        <div
          v-if="!formData.video"
          class="input-group has-validation"
        >
          <input
            :class="[{ 'invalid' : offerCreateErrors?.video }, 'form-control', 'w-100']"
            type="file"
            @change="changeVideo"
          >
          <span
            v-if="offerCreateErrors?.video"
            class="text-danger"
          >{{ offerCreateErrors?.video[0] }}</span>
        </div>
        <div
          v-else
          class="d-flex"
        >
          <div class="video-company-profile">
            <video controls>
              <source
                :src="`${VITE_API_URL}/${formData.video}`"
                type="video/mp4"
              >
            </video>
          </div>
          <button
            type="button"
            class="btn-close btn-close-white ms-2"
            @click="removeVideo"
          />
        </div>
      </div>
      <div class="col col-12 col-sm-6 col-md-4 col-lg-3">
        <label class="form-label text-purple_grey text-bold">
          Nombre d'années d'expérience: {{ formData.years_of_experience }}
        </label>
        <input
          v-model="formData.years_of_experience"
          type="range"
          class="form-range"
          min="0"
          max="30"
          step="1"
        >
      </div>
      <div class="col col-12 col-sm-6 col-md-4 col-lg-3">
        <label class="form-label text-purple_grey text-bold">Niveau d'étude <Required /></label>
        <div class="input-group has-validation">
          <v-select
            v-model="formData.degree_levels"
            :class="[{ 'invalid' : offerCreateErrors?.degree_levels }, 'w-100']"
            label="name"
            multiple
            value="option"
            :options="degreeLevels"
          />
          <span
            v-if="offerCreateErrors?.degree_levels"
            class="text-danger"
          >{{ offerCreateErrors?.degree_levels[0] }}</span>
        </div>
      </div>
      <div class="col col-12 col-sm-6 col-md-4 col-lg-3 mt-5">
        <div class="form-check form-switch mt-2">
          <label class="form-check-label ms-2">
            <span class="text-purple_grey text-bold">
              Pas de permis obligatoire
            </span>
          </label>
          <input
            v-model="formData.without_license"
            class="form-check-input custom-switch"
            type="checkbox"
          >
          <span
            v-if="offerCreateErrors?.without_license"
            class="text-danger"
          > <br> {{ offerCreateErrors?.without_license[0] }}</span>
        </div>
      </div>
      <div
        v-if="!formData.without_license"
        class="col col-12 col-sm-6 col-md-4 col-lg-3"
      >
        <label class="form-label text-purple_grey text-bold">Permis <Required /></label>
        <div class="input-group has-validation">
          <v-select
            v-model="formData.licenses"
            :class="[{ 'invalid' : offerCreateErrors?.licenses }, 'w-100']"
            label="name"
            multiple
            value="option"
            :options="licenses"
          />
          <span
            v-if="offerCreateErrors?.licenses"
            class="text-danger"
          >{{ offerCreateErrors?.licenses[0] }}</span>
        </div>
      </div>
      <div class="col col-12 col-sm-6 col-md-4 col-lg-3 mt-5">
        <div class="form-check form-switch mt-2">
          <label class="form-check-label ms-2">
            <span class="text-purple_grey text-bold">
              Disponible maintenant
            </span>
          </label>
          <input
            v-model="formData.available_now"
            class="form-check-input custom-switch"
            type="checkbox"
          >
          <span
            v-if="offerCreateErrors?.available_now"
            class="text-danger"
          > <br> {{ offerCreateErrors?.available_now[0] }}</span>
        </div>
      </div>
      <div
        v-if="!formData.available_now"
        class="col col-12 col-sm-6 col-md-4 col-lg-3 "
      >
        <label class="form-label text-purple_grey text-bold">Disponibilité <Required /></label>
        <div class="input-group has-validation">
          <input
            v-model="formData.availability"
            :class="[{ 'invalid' : offerCreateErrors?.availability }, 'form-control', 'w-100']"
            placeholder="Disponibilité"
            required
            type="date"
          >
          <span
            v-if="offerCreateErrors?.availability"
            class="text-danger"
          >{{ offerCreateErrors?.availability[0] }}</span>
        </div>
      </div>
      <div class="col col-12 col-sm-6 col-md-4 col-lg-3 ">
        <label class="form-label text-purple_grey text-bold">Langues parlées <Required /></label>
        <div class="input-group has-validation">
          <v-select
            v-model="formData.languages"
            :class="[{ 'invalid' : offerCreateErrors?.languages }, 'w-100']"
            label="name"
            multiple
            value="option.id "
            :options="languages"
          />
          <span
            v-if="offerCreateErrors?.languages"
            class="text-danger"
          >{{ offerCreateErrors?.languages[0] }}</span>
        </div>
      </div>
      <div class="col col-12 col-sm-6 col-md-4 col-lg-3">
        <label class="form-label text-purple_grey text-bold">
          Nombre de postes ouverts</label>
        <div class="input-group has-validation">
          <input
            v-model="formData.number_of_seats"
            :class="[{ 'invalid' : offerCreateErrors?.number_of_seats },
                     'form-control', 'w-100']"
            placeholder="Nombre de postes ouverts"
            required
          >
          <span
            v-if="offerCreateErrors?.number_of_seats"
            class="text-danger"
          >{{ offerCreateErrors?.number_of_seats[0] }}</span>
        </div>
      </div>
    </div>
    <div
      v-if="!offerDataLoading"
      class="row g-4 mt-1"
    >
      <div class="col col-12 col-sm-6 col-md-6 col-lg-6">
        <label class="form-label text-purple_grey text-bold">
          Description de l'offre</label>
        <div class="input-group has-validation">
          <textarea
            v-model="formData.description"
            :class="[{ 'invalid' : offerCreateErrors?.description }, 'form-control', 'w-100']"
            placeholder="Description de l'offre (500 caractères max)"
            maxlength="500"
            rows="5"
          />
          <span
            v-if="offerCreateErrors?.description"
            class="text-danger"
          >{{ offerCreateErrors?.description[0] }}</span>
        </div>
      </div>
      <div class="col col-12 col-sm-6 col-md-4 col-lg-3">
        <label class="form-label text-purple_grey text-bold">Etat <Required /></label>
        <div class="input-group has-validation">
          <select
            v-model="formData.state"
            :class="[{ 'invalid' : offerCreateErrors?.state }, 'form-select', 'w-100']"
          >
            <option
              v-for="state in states"
              :key="state.key"
              :value="state.key"
            >
              {{ state.name }}
            </option>
          </select>
          <span
            v-if="offerCreateErrors?.state"
            class="text-danger"
          >{{ offerCreateErrors?.state[0] }}</span>
        </div>
      </div>
      <div class="col col-12 col-sm-6 col-md-4 col-lg-3">
        <div class="form-check form-switch p-0 w-100">
          <label class="form-check-label">
            <span class="text-purple_grey text-bold">
              Visibilité
            </span>

          </label>
          <button
            type="button"
            class="btn btn-black mb-1 ms-2 p-1 info-icon-small_container"
            data-bs-container="body"
            data-bs-toggle="popover"
            data-bs-placement="left"
            :data-bs-content="'Si vous êtes plusieurs recruteurs au sein de votre société, ' +
              'choisissez privée pour être la ou le seul(e)' +
              ' à voir cette offre ou alors publique ' +
              'si tout le monde peut la consulter.'"
          >
            <img
              src="@/assets/images/icons/info-circle-fill.svg"
              alt=""
              class="info-icon-small"
            >
          </button>
          <!--          For Safari Browser only-->
          <div
            v-if="isSafari"
            class="d-flex justify-content-center align-items-center mt-2"
          >
            <div class="form-check form-switch mt-2">
              <label class="form-check-label ms-2">
                <span class="text-purple_grey text-bold">
                  Offre privée
                </span>
              </label>
              <input
                v-model="formData.is_private"
                class="form-check-input custom-switch"
                type="checkbox"
              >
            </div>
          </div>
          <div
            v-else
            class="d-flex justify-content-center align-items-center mt-2"
          >
            <span class="me-5">Publique</span>
            <input
              v-model="formData.is_private"
              class="form-check-input custom-switch"
              type="checkbox"
            >
            <span class="ms-2">Privée</span>
          </div>

          <span
            v-if="offerCreateErrors?.is_private"
            class="text-danger"
          > <br> {{ offerCreateErrors?.is_private[0] }}</span>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-end align-items-center mt-5">
      <div class="col-auto mt-3">
        <router-link
          :to="props.create ?{ name: 'CompanyOffersList' } :
            { name: 'CompanyOfferDetail', params : { id : route.params.id } }"
        >
          <button
            class="btn btn-outline-danger"
          >
            <span v-if="props.create">
              Retour aux offres
            </span>
            <span v-else>
              Retour à l'offre
            </span>
          </button>
        </router-link>
      </div>
      <div class="col-auto mt-3">
        <button
          v-if="props.create"
          class="btn btn-purple-gradient text-bold"
          @click="create"
        >
          {{ actionButtonText }}
        </button>
        <button
          v-else
          class="btn btn-purple-gradient text-bold"
          @click="update"
        >
          {{ actionButtonText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>

import {
  computed, onMounted, ref, watch,
} from 'vue';
import { useStore } from 'vuex';
import SearchCities from '@/components/searchCities/SearchCities.vue';
import normalizer from '@/utils/normalizer';
import { useRoute, useRouter } from 'vue-router';
import SearchJobs from '@/components/searchJobs/SearchJobs.vue';
import Required from '@/components/Required.vue';
import { Popover } from 'bootstrap';

const store = useStore();
const router = useRouter();
const route = useRoute();
const offerCreateErrors = computed(() => store.state.offer.offer_detail_errors);
const offerData = computed(() => store.state.offer.offer_detail);
const offerDataLoading = computed(() => store.state.offer.offer_detail_loading);
const contractTypes = computed(() => store.state.contractType?.contract_types?.data?.data);
const jobs = computed(() => store.state?.job?.jobs?.data?.data);
const degreeLevels = computed(() => store.state?.degreeLevel?.degree_levels?.data?.data);
const licenses = computed(() => store.state?.license?.licenses?.data?.data);
const languages = computed(() => store.state?.language?.languages?.data?.data);
const isSafari = computed(() => /^((?!chrome|android).)*safari/i.test(navigator?.userAgent));

const props = defineProps({
  bearCrumb: String,
  title: String,
  actionButtonText: String,
  create: Boolean,
  duplicateId: Number,
});

const { VITE_API_URL } = import.meta.env;

const formData = ref({
  state: null,
  is_private: false,
  title: null,
  telework: false,
  city: null,
  city_id: null,
  city_name: null,
  radius: 0,
  salary_minimum: 30000,
  salary_maximum: 31000,
  contract_types: [],
  advantages: null,
  description: null,
  years_of_experience: 0,
  availability: null,
  available_now: false,
  number_of_seats: null,
  video: null,
  jobs: [],
  licenses: [],
  degree_levels: [],
  languages: [],
  without_license: false,
  p_videos_required: false,
  job_id: null,
  job: {},
});
const states = ref([
  { key: 'active', name: 'active' },
  { key: 'inactive', name: 'inactive' },
  { key: 'archived', name: 'archivée' },
]);

const teleworkOptions = ref([
  { key: 'none', name: 'Pas de télétravail' },
  { key: 'partial', name: 'Partiel' },
  { key: 'full', name: 'Télétravail uniquement' },
]);

const advantagesOptions = [
  'Chèque repas / Tickets restaurant',
  'Chèque vacances',
  'Chèques emploi service universel',
  'Smartphone ou la tablette tactile',
  'Véhicule de fonction',
  'Intéressement',
  'Participation',
  'Abondement',
  'Comité entreprise',
  'Frais de transport partiellement pris en charge',
];

const video = ref(null);

const removeVideo = () => {
  formData.value.video = null;
  video.value = null;
};

const changeVideo = (event) => {
  video.value = event.target.files[0];
};

const sendCity = (data) => {
  if (data) {
    formData.value.city_id = data?.id;
    formData.value.city_name = data?.name;
  }
};

const sendJob = (data) => {
  if (data) {
    formData.value.jobs = data;
  }
};

const getId = (array) => {
  const result = ref([]);
  for (const elt of array) {
    result.value = [...result.value, elt.id];
  }
  return result.value;
};

const create = () => {
  const tempFormData = ref({ ...formData.value });
  delete tempFormData.value.video;
  if (formData.value.available_now) {
    delete tempFormData.value.availability;
  }
  if (formData.value.telework === 'full') {
    delete tempFormData.value.city_id;
    delete tempFormData.value.radius;
  }
  if (formData.value.without_license) {
    delete tempFormData.value.licenses;
  } else {
    tempFormData.value.licenses = [];
    tempFormData.value.licenses = getId(formData.value.licenses);
  }
  tempFormData.value.contract_types = [];
  tempFormData.value.contract_types = getId(formData.value.contract_types);
  tempFormData.value.languages = [];
  tempFormData.value.languages = getId(formData.value.languages);
  tempFormData.value.degree_levels = [];
  tempFormData.value.degree_levels = getId(formData.value.degree_levels);
  tempFormData.value.jobs = [];
  tempFormData.value.jobs = getId(formData.value.jobs);

  const result = normalizer.makeFormDataWithObject(tempFormData.value);
  if (video.value) {
    result.append('video', video.value);
  }
  store.dispatch('offer/create', {
    data: result,
    onSuccess: () => {
      router.push({ name: 'CompanyOffersList' });
    },
  });
};

const update = () => {
  const tempFormData = ref({ ...formData.value });
  if (tempFormData.value.video) {
    tempFormData.value.video = tempFormData.value.video.replace('/storage', 'public');
  } else {
    delete tempFormData.value.video;
  }
  if (formData.value.available_now) {
    delete tempFormData.value.availability;
  }
  if (formData.value.telework === 'full') {
    delete tempFormData.value.city_id;
    delete tempFormData.value.radius;
  }
  if (formData.value.without_license) {
    delete tempFormData.value.licenses;
  } else {
    tempFormData.value.licenses = [];
    tempFormData.value.licenses = getId(formData.value.licenses);
  }
  tempFormData.value.contract_types = [];
  tempFormData.value.contract_types = getId(formData.value.contract_types);
  tempFormData.value.languages = [];
  tempFormData.value.languages = getId(formData.value.languages);
  tempFormData.value.degree_levels = [];
  tempFormData.value.degree_levels = getId(formData.value.degree_levels);
  tempFormData.value.jobs = [];
  tempFormData.value.jobs = getId(formData.value.jobs);

  const result = normalizer.makeFormDataWithObject(tempFormData.value);
  if (video.value) {
    result.append('video', video.value);
  }
  store.dispatch('offer/update', {
    data: result,
    id: offerData.value?.data?.data?.child
      ? offerData.value?.data?.data?.child.id
      : offerData.value?.data?.data?.id,
    onSuccess: () => {
      router.push({ name: 'CompanyOffersList' });
    },
  });
};

watch(offerData, (value) => {
  formData.value = { ...value.data.data };
  formData.value.availability = normalizer.formatDate(formData.value.availability)
    .split('/').reverse().join('-');
  if (route.query.duplicateId) {
    formData.value.video = null;
  }
});

onMounted(() => {
  store.dispatch('contractType/getAll');
  store.dispatch('degreeLevel/getAll');
  store.dispatch('license/getAll');
  store.dispatch('language/getAll');
  if (!props.create) {
    store.dispatch('offer/getOne', route.params.id);
  }
  if (route.query.duplicateId) {
    store.dispatch('offer/getOne', route.query.duplicateId);
  }
  Array.from(document.querySelectorAll('button[data-bs-toggle="popover"]'))
    .forEach((popoverNode) => new Popover(popoverNode));
});

</script>
