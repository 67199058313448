<template>
  <div>
    <div class="row mt-3">
      <div class="col col-sm-12">
        <div class="steps d-flex">
          <div class="line-primary m-1" />
          <div class="line-primary-empty m-1" />
          <div class="line-primary-empty m-1" />
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col col-sm-12">
        <p class="mb-1">
          Inscrivez-vous en 5 min chrono !
        </p>
        <p>Et découvrez toutes les fonctionnalités Viewzme, notre solution de CV vidéo.</p>
      </div>
    </div>
    <div class="row mt-2 mt-xxl-5">
      <h2 class="text-bold text-blue mt-2 mt-xxl-5">
        Vous êtes?
      </h2>
      <div class="row mt-3 w-100">
        <input
          id="register-choice-candidate"
          v-model="formData.choice"
          type="radio"
          class="btn-check"
          name="options"
          value="candidate"
        >
        <label
          class="btn col-6 rounded-3 bg-grey d-flex flex-column
          text-center m-auto p-3 register-choice"
          for="register-choice-candidate"
        >
          <img
            src="@/assets/images/icons/briefcase.svg"
            class=""
            alt="Candidat"
          >
          <span class="text-bold text-light">Un candidat</span>
        </label>
        <input
          id="register-choice-company"
          v-model="formData.choice"
          type="radio"
          class="btn-check"
          name="options"
          value="company"
        >
        <label
          class="btn col-6 rounded-3 bg-grey d-flex flex-column
           text-center m-auto p-3 register-choice"
          for="register-choice-company"
        >
          <img
            src="@/assets/images/icons/building.svg"
            alt="Entreprise"
          >
          <span class="text-bold text-light">Une entreprise</span>
        </label>
      </div>
    </div>
    <div class="row w-100 mt-5">
      <button
        class="btn-purple-gradient w-50 text-bold p-3 mt-4 rounded-3 m-auto"
        @click="onContinue"
      >
        Continuer
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const formData = ref({
  choice: 'candidate',
});
const emit = defineEmits(['choice']);

const onContinue = () => {
  emit('choice', formData.value.choice);
};

</script>
