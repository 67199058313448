<template>
  <div class="w-100 pb-5">
    <div
      v-if="courses.length <= 0"
      class="d-flex flex-column align-items-center justify-content-center"
    >
      <div class="fst-italic">
        Aucune formation
      </div>
      <button
        class="btn btn-outline-light text-bold mt-3"
        @click="addCourse"
      >
        Ajouter une formation
      </button>
    </div>
    <Course
      v-for="(item, index) in courses"
      :key="index"
      :training-data="item"
      :existing-candidate="!!existingCandidate"
      :profil-errors="profilErrors?.[index]"
      @send-data="sendData(index, $event)"
      @on-delete="deleteCourseFromList(index)"
      @add-training="addCourse"
    />
  </div>
</template>

<script setup>
import {
  computed, onMounted, ref, watch,
} from 'vue';
import Course from '@/components/candidate/Course.vue';
import { useStore } from 'vuex';

const store = useStore();
const profilErrors = computed(() => store.state?.candidate?.candidate_detail_errors?.courses);

const props = defineProps({
  existingCandidate: [Array, Object],
});
const profileData = computed(() => props.existingCandidate);

const courses = ref([]);

const formData = ref({
  courses,
});

const addCourse = () => {
  courses.value = [...courses.value, {
    start_date: null,
    end_date: null,
    actual: false,
    degree_name: null,
    degree_level_id: null,
    university_name: null,
    obtained: false,
  }];
};

const deleteCourseFromList = (index) => {
  courses.value.splice(index, 1);
};

const emit = defineEmits(['sendData', 'errorExist']);

const sendData = (index, data) => {
  formData.value.courses[index] = data;
};

const onSend = () => {
  emit('sendData', formData.value);
};
watch(formData.value, (value) => {
  if (value) {
    onSend();
  }
});

watch(profilErrors, (value) => {
  if (value && Object.keys(value).length !== 0) {
    emit('errorExist');
  }
});

watch(profileData, (value) => {
  if (value[0]) {
    courses.value = [];
    for (const training of profileData.value) {
      courses.value = [...courses.value, {
        start_date: training.start_date,
        end_date: training.end_date,
        actual: training.actual,
        degree_name: training.degree_name,
        degree_level_id: training.degree_level.id,
        university_name: training.university_name,
        obtained: training.obtained,
      }];
    }
  }
});

onMounted(() => {
  emit('sendData', formData.value);
});

</script>
