import Notes from '@/services/Note';

export default {
  async getAll({ commit }, data) {
    commit('setNotesLoading', true);
    try {
      const notes = await Notes.getAll(data);
      commit('setNotes', notes);
    } catch (error) {
      commit('setNotesErrors', error?.response?.data?.errors);
    }
    commit('setNotesLoading', false);
  },
  async create({ commit }, data) {
    commit('setNotesLoading', true);
    try {
      await Notes.create(data);
      data.onSuccess();
      commit('setNotesErrors', []);
    } catch (error) {
      commit('setNotesErrors', error?.response?.data?.errors);
    }
    commit('setNotesLoading', false);
  },
};
