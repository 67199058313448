<template>
  <div
    class="modal fade toggleModal"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content  bg-dark_grey">
        <div class="modal-header">
          <h5 class="modal-title">
            Recommandation
          </h5>
          <button
            type="button"
            class="btn-close bg-light"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div class="modal-body my-3">
          <a
            :href="
              `${VITE_API_URL}${exp.document}`"
            target="_blank"
            rel="noopener noreferrer"
          >

            <h5> <img
              src="@/assets/images/icons/search.svg"
              alt="Ouvrir la recommandation"
              class="me-2"
            >Ouvrir la recommandation</h5>
          </a>
          <div
            v-if="exp.can_contact_manager"
            class="mt-3"
          >
            <hr>
            <h5>Le responsable</h5>
            <div>
              <div> Nom et prénom : {{ exp.manager_name }}</div>
              <div> Poste : {{ exp.manager_position }}</div>
              <div> Email : {{ exp.manager_email }}</div>
              <div> Téléphone : {{ normalizer.formatPhoneNumber(exp.manager_phone) }}</div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-purple-gradient text-bold"
            data-bs-dismiss="modal"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import normalizer from '@/utils/normalizer';

const { VITE_API_URL } = import.meta.env;

const props = defineProps({
  exp: Object,
});

</script>
