import Company from '@/services/Company';

export default {
  async getAll({ commit }, page) {
    commit('setCompaniesLoading', true);
    try {
      const companies = await Company.getAll(page);
      commit('setCompanies', companies);
    } catch (error) {
      commit('setCompaniesErrors', error?.response?.data?.errors);
    }
    commit('setCompaniesLoading', false);
  },
  async getAllNotVerified({ commit }, page) {
    commit('setCompaniesLoading', true);
    try {
      const companies = await Company.getAllNotVerified(page);
      commit('setCompanies', companies);
    } catch (error) {
      commit('setCompaniesErrors', error?.response?.data?.errors);
    }
    commit('setCompaniesLoading', false);
  },
  async getOne({ commit }, id) {
    commit('setCompanyDetailLoading', true);
    try {
      const data = await Company.getOne(id);
      commit('setCompanyDetail', data);
    } catch (error) {
      commit('setCompanyDetailErrors', error?.response?.data?.errors);
    }
    commit('setCompanyDetailLoading', false);
  },
  async showProfile({ commit }) {
    commit('setCompanyDetailLoading', true);
    try {
      const data = await Company.showProfile();
      commit('setCompanyDetail', data);
    } catch (error) {
      commit('setCompanyDetailErrors', error?.response?.data?.errors);
    }
    commit('setCompanyDetailLoading', false);
  },
  async getAllRecruiters({ commit }) {
    commit('setRecruitersLoading', true);
    try {
      const data = await Company.getAllRecruiters();
      commit('setRecruiters', data);
    } catch (error) {
      commit('setRecruitersErrors', error?.response?.data?.errors);
    }
    commit('setRecruitersLoading', false);
  },
  async update({ commit }, form) {
    commit('setCompanyDetailLoading', true);
    try {
      await Company.update(form.data);
      await form.onSuccess();
      commit('setCompanyDetailErrors', []);
    } catch (error) {
      commit('setCompanyDetailErrors', error?.response?.data?.errors);
    }
    commit('setCompanyDetailLoading', false);
  },
  async modify({ commit }, data) {
    commit('setCompanyDetailLoading', true);
    try {
      await Company.modify(data.data);
      data.onSuccess();
    } catch (error) {
      commit('setCompanyDetailErrors', error?.response?.data?.errors);
    }
    commit('setCompanyDetailLoading', false);
  },
  async verify({ commit }, data) {
    commit('setCompanyDetailLoading', true);
    try {
      await Company.verify(data.id);
      data.onSuccess();
    } catch (error) {
      commit('setCompanyDetailErrors', error?.response?.data?.errors);
    }
    commit('setCompanyDetailLoading', false);
  },
  async refuse({ commit }, data) {
    commit('setCompanyDetailLoading', true);
    try {
      await Company.refuse(data);
      data.onSuccess();
    } catch (error) {
      commit('setCompanyDetailErrors', error?.response?.data?.errors);
    }
    commit('setCompanyDetailLoading', false);
  },
  async deactivate({ commit }, data) {
    commit('setCompanyDetailLoading', true);
    try {
      await Company.deactivate(data.id);
      data.onSuccess();
    } catch (error) {
      commit('setCompanyDetailErrors', error?.response?.data?.errors);
    }
    commit('setCompanyDetailLoading', false);
  },
  async delete({ commit }, data) {
    commit('setCompanyDetailLoading', true);
    try {
      await Company.delete(data.id);
      data.onSuccess();
    } catch (error) {
      commit('setCompanyDetailErrors', error?.response?.data?.errors);
    }
    commit('setCompanyDetailLoading', false);
  },
  async addRecruiter({ commit }, data) {
    commit('setAddRecruiterLoading', true);
    try {
      await Company.addRecruiter(data.data);
      data.onSuccess();
      commit('setAddRecruiterErrors', []);
      commit('setAddRecruiterMessageError', null);
    } catch (error) {
      data.onFail();
      commit('setAddRecruiterErrors', error?.response?.data?.errors);
      commit('setAddRecruiterMessageError', error?.response?.data);
    }
    commit('setAddRecruiterLoading', false);
  },
};
