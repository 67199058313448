import Admin from '@/services/Admin';

export default {
  async getAll({ commit }) {
    commit('setAdminsLoading', true);
    try {
      const admins = await Admin.getAll();
      commit('setAdmins', admins);
    } catch (error) {
      commit('setAdminsErrors', error?.response?.data?.errors);
    }
    commit('setAdminsLoading', false);
  },
  async create({ commit }, form) {
    commit('setCreateAdminLoading', true);
    try {
      await Admin.create(form);
      commit('setCreateAdmin', true);
    } catch (error) {
      commit('setCreateAdmin', false);
      commit('setCreateAdminErrors', error?.response?.data?.errors);
    }
    commit('setCreateAdminLoading', false);
  },
};
