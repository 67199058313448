const buildRecursive = (rootKey, keys, value, acc) => {
  if (keys.length === 0) {
    return {
      ...(acc || {}),
      [`${rootKey}`]: value,
    };
  }
  const [first, ...rest] = keys;
  const sub = buildRecursive(first, rest, value, acc?.[first] || {});
  return {
    [rootKey]: {
      ...(acc || {}),
      ...sub,
    },
  };
};

const recursiveFormDataWithObject = (a, k, v) => {
  if (v === null || v instanceof Date || typeof (v) !== 'object' || v.length === 0) {
    let val = v;

    // Change null to empty string --> Because formData always send string
    if (v === null || v === undefined) {
      val = '';
    }

    // Change boolean to 0 or 1 --> Because formData always send string
    if (typeof v === 'boolean') {
      val = v ? 1 : 0;
    }

    a.append([k], val);
    return a;
  }
  return Object.entries(v).reduce((acc, [key, value]) => recursiveFormDataWithObject(acc, `${k}[${key}]`, value), a);
};

const numberWithSpaces = (value, pattern) => {
  for (let i = 0; i < value.length; i += 1) {
    // eslint-disable-next-line no-param-reassign
    pattern = pattern.replace('#', value[i]);
  }
  return pattern;
};

export default {
  formatDate: (inputDate, join = '/') => {
    const pad = (s) => ((s < 10) ? `0${s}` : s);
    const d = new Date(inputDate);
    return [
      pad(d.getDate()),
      pad(d.getMonth() + 1),
      d.getFullYear(),
    ].join(join);
  },
  formatErrors: (errors) => Object.entries(errors).reduce((acc, [keyWithDot, errorValues]) => {
    const [rootKey, ...subKeys] = keyWithDot.split('.');
    return {
      ...acc,
      ...buildRecursive(rootKey, subKeys, errorValues, acc[rootKey]),
    };
  }, {}),
  makeFormDataWithObject(datas) {
    const result = Object.entries(datas).reduce(
      (acc, [k, v]) => recursiveFormDataWithObject(acc, k, v),
      new FormData(),
    );

    return result;
  },
  formatPhoneNumber: (number) => {
    if (!number) {
      return number;
    }
    if (number[0] === '+' && number.length === 12) {
      return numberWithSpaces(number, '### # ## ## ## ##');
    }
    if (number[0] === '0' && number.length === 10) {
      return numberWithSpaces(number, '## ## ## ## ##');
    }
    return number;
  },
};
