<template>
  <div class="mt-5">
    <h4 class="text-primary text-bold">
      Informations personnelles
    </h4>
    <hr class="w-100">
    <div
      v-if="loading"
      class="d-flex justify-content-center mt-5"
    >
      <div
        class="spinner-border"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div
      v-else
      class="row g-4"
    >
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Photo de profil</label>
        <div class="photo-profile">
          <img
            :src="`${VITE_API_URL}/${profile?.picture}`"
            alt="Photo de profile"
          >
        </div>
      </div>
    </div>
    <div
      v-if="!loading"
      class="row g-4 mt-2"
    >
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Prénom</label>
        <p>{{ profile?.first_name }}</p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Nom</label>
        <p>{{ profile?.second_name }}</p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Date de naissance</label>
        <p>{{ normalizer.formatDate(profile?.birth_date) }}</p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Téléphone</label>
        <p>{{ normalizer.formatPhoneNumber(profile?.phone_number) }}</p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Adresse</label>
        <p>{{ profile?.address1 }}</p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Complément d'adresse</label>
        <p>{{ profile?.address2 }}</p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Ville</label>
        <p>{{ profile?.city?.name }}</p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Code postal</label>
        <p>{{ profile?.city?.zip_code }}</p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Permis</label>
        <p
          v-for="license in profile?.licenses"
          :key="license"
        >
          {{ license?.name + ' ' }}
        </p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Langues</label>
        <p
          v-for="language in profile?.languages"
          :key="language"
        >
          {{ language?.name + ' ' }}
        </p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">RQTH</label>
        <p>{{ profile?.rqth ? 'Oui' : 'Non' }}</p>
      </div>
      <div class="col col-12 col-sm-6">
        <label class="form-label text-purple_grey text-bold">Liens externes</label>
        <p
          v-for="link in profile?.external_links"
          :key="link"
        >
          <a
            :href="link"
            class="text-break"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ link }}
          </a>
          <br>
        </p>
        <p v-if="!profile?.external_links">
          Aucun lien
        </p>
      </div>
    </div>
    <h4 class="text-primary text-bold mt-5">
      Expériences professionnelles
    </h4>
    <hr class="w-100">
    <div
      v-if="loading"
      class="d-flex justify-content-center mt-5"
    >
      <div
        class="spinner-border"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div
      v-for="exp in profile?.experiences"
      v-else
      :key="exp"
      class="row g-4 bg-grey mt-4 p-3"
    >
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Date de début</label>
        <p>{{ normalizer.formatDate(exp?.start_date?.iso)?.substring(3) }}</p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Date de fin</label>
        <p v-if="!exp?.actual">
          {{ normalizer.formatDate(exp?.end_date?.iso)?.substring(3) }}
        </p>
        <p v-else>
          Jusqu'à maintenant
        </p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Actuallement en poste</label>
        <p>{{ exp?.actual ? 'Oui' : 'Non' }}</p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Type de contrat</label>
        <p>
          {{ exp?.contract_type?.name }}
        </p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Intitulé du poste</label>
        <p>{{ exp?.job_title }}</p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Nom de l'entreprise</label>
        <p>{{ exp?.company_name }}</p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Recommandation</label>
        <p v-if="!exp?.recommendation">
          Pas de recommandation
        </p>
        <p v-else>
          <a

            :href="`${VITE_API_URL}/${exp?.recommendation.document}`"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>
              <img
                src="@/assets/images/icons/search.svg"
                alt="Ouvrir la recommandation"
                class="me-2"
              >
              Ouvrir la recommandation
            </span>
          </a>
        </p>
      </div>
      <div
        v-if="exp?.can_contact_manager"
        class="col col-12 col-sm-3"
      >
        <label class="form-label text-purple_grey text-bold">
          Autorisation de contacter le responsable
        </label>
        <p>{{ exp?.can_contact_manager ? 'Oui' : 'Non' }}</p>
      </div>
      <div
        v-if="exp?.can_contact_manager"
        class="col col-12 col-sm-3"
      >
        <label class="form-label text-purple_grey text-bold">Email du responsable</label>
        <p>{{ exp?.manager_email }}</p>
      </div>
      <div
        v-if="exp?.can_contact_manager"
        class="col col-12 col-sm-3"
      >
        <label class="form-label text-purple_grey text-bold">Nom et prénom du responsable</label>
        <p>{{ exp?.manager_name }}</p>
      </div>
      <div
        v-if="exp?.can_contact_manager"
        class="col col-12 col-sm-3"
      >
        <label class="form-label text-purple_grey text-bold">Poste du responsable</label>
        <p>{{ exp?.manager_position }}</p>
      </div>
      <div
        v-if="exp?.can_contact_manager"
        class="col col-12 col-sm-3"
      >
        <label class="form-label text-purple_grey text-bold">Téléphone du responsable</label>
        <p>{{ normalizer.formatPhoneNumber(exp?.manager_phone) }}</p>
      </div>
    </div>
    <h4 class="text-primary text-bold mt-5">
      Formations
    </h4>
    <hr class="w-100">
    <div
      v-if="loading"
      class="d-flex justify-content-center mt-5"
    >
      <div
        class="spinner-border"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div
      v-for="course in profile?.courses"
      v-else
      :key="course"
      class="row g-4 bg-grey mt-4 p-3"
    >
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Date de début</label>
        <p>{{ normalizer.formatDate(course?.start_date?.iso)?.substring(3) }}</p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Date de fin</label>
        <p v-if="!course?.actual">
          {{ normalizer.formatDate(course?.end_date?.iso)?.substring(3) }}
        </p>
        <p v-else>
          Jusqu'à maintenant
        </p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Formation en cours</label>
        <p>{{ course?.actual ? 'Oui' : 'Non' }}</p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Diplôme</label>
        <p>
          {{ course?.degree_level?.name }}
        </p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Nom du diplôme</label>
        <p>
          {{ course?.degree_name }}
        </p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Nom de l'école</label>
        <p>{{ course?.university_name }}</p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Diplôme obtenu</label>
        <p>{{ course?.obtained ? 'Oui' : 'Non' }}</p>
      </div>
    </div>
    <h4 class="text-primary text-bold mt-5">
      Recherche
    </h4>
    <hr class="w-100">
    <div
      v-if="loading"
      class="d-flex justify-content-center mt-5"
    >
      <div
        class="spinner-border"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div
      v-else
      class="row g-4 mt-4"
    >
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Metier rechercher</label>
        <p>{{ profile?.research?.job?.name }}</p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Type de contrat</label>
        <div
          v-for="type in profile?.research?.contract_types"
          :key="type"
        >
          {{ type?.name + ' ' }}
        </div>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Télétravail</label>
        <p>
          {{ teleworkOptions?.[profile?.research?.telework] }}
        </p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Niveau d'étude souhaité</label>
        <p>
          {{ profile?.research?.degreeLevel?.name }}
        </p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Années d'expérience</label>
        <p>
          {{ profile?.research?.years_of_experience }}
        </p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Salaire minimum</label>
        <p>
          {{ profile?.research?.salary_minimum }} €
        </p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Salaire maximum</label>
        <p>
          {{ profile?.research?.salary_maximum }} €
        </p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Disponibilité</label>
        <p>
          {{ profile?.research?.availability ?
            normalizer.formatDate(profile?.research?.availability) :
            'Disponible maintenant' }}
        </p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Ville</label>
        <p>
          {{ profile?.research?.city?.name }}
        </p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Code postal</label>
        <p>
          {{ profile?.research?.city?.zip_code }}
        </p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Rayon autour de la ville</label>
        <p>
          {{ profile?.research?.radius }} km
        </p>
      </div>
    </div>
  </div>
</template>
<script setup>
import normalizer from '@/utils/normalizer';

const { VITE_API_URL } = import.meta.env;

const teleworkOptions = {
  none: 'Pas de télétravail',
  partial: 'Télétravail partiel',
  full: '100% télétravail',
};

const props = defineProps({
  profile: Object,
  loading: Boolean,
});

</script>
