import Notification from '@/services/Notification';

export default {
  async getAll({ commit }, data) {
    commit('setNotificationsLoading', true);
    try {
      const notifications = await Notification.getAll(data);
      commit('setNotifications', notifications);
    } catch (error) {
      commit('setNotificationsErrors', error?.response?.data?.errors);
    }
    commit('setNotificationsLoading', false);
  },
  async markAsRead({ commit }, data) {
    commit('setNotificationDetailLoading', true);
    try {
      const response = await Notification.markAsRead(data);
      commit('setNotificationDetail', response);
    } catch (error) {
      commit('setNotificationDetailErrors', error?.response?.data?.errors);
    }
    commit('setNotificationDetailLoading', false);
  },
};
