<template>
  <div>
    <h2 class="text-primary text-bold">
      Les tutoriels vidéos
    </h2>
    <hr class="w-100 mb-5">
    <div class="tab-content">
      <div class=" d-flex flex-wrap">
        <div
          v-for="video in tutorials"
          :key="video.id"
          class="rounded overflow-hidden bg-dark_grey m-3"
          style="width: 400px"
        >
          <video
            controls
            class="w-100"
          >
            <source
              :src="`${VITE_API_URL}/${video?.video}`"
              type="video/mp4"
            >
          </video>
          <div class="text-center p-2">
            <div class="text-center p-2">
              <div class="text-bold card-text-lg text-uppercase">
                {{ video?.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Pagination
      :meta="tutorialsPaginate"
      @changePage="changePage($event)"
    />
  </div>
</template>

<script setup>
import {
  computed, onMounted,
} from 'vue';
import { useStore } from 'vuex';
import Pagination from '@/components/pagination/Pagination.vue';

const store = useStore();
// const filter = ref();
const { VITE_API_URL } = import.meta.env;

const tutorials = computed(() => store.state?.tutorial?.tutorials?.data?.data);
const tutorialsPaginate = computed(() => store.state?.tutorial?.tutorials?.data?.meta);

const changePage = (p) => {
  store.dispatch('tutorial/getAll', { page: p });
};

onMounted(() => {
  store.dispatch('tutorial/getAll', { page: 1 });
});
</script>
