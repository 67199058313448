import CompanySizes from '@/services/CompanySize';

export default {
  async getAll({ commit }) {
    commit('setCompanySizesLoading', true);
    try {
      const companySizes = await CompanySizes.getAll();
      commit('setCompanySizes', companySizes);
    } catch (error) {
      commit('setCompanySizesErrors', error?.response?.data?.errors);
    }
    commit('setCompanySizesLoading', false);
  },
};
