export default {
  setOffers(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.offers = value;
  },
  setOffersErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.offers_errors = value;
  },
  setOffersLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.offers_loading = value;
  },
  setOfferDetail(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.offer_detail = value;
  },
  setOfferDetailErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.offer_detail_errors = value;
  },
  setOfferDetailLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.offer_detail_loading = value;
  },
};
