export default {
  setSubscriptions(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.subscriptions = value;
  },
  setSubscriptionsErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.subscriptions_errors = value;
  },
  setSubscriptionsLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.subscriptions_loading = value;
  },
};
