<template>
  <div class="min-vh-100 min-vw-100 d-flex align-items-center justify-content-center">
    <div class="text-center">
      <h1>Vous n'avez pas accès à cette partie de la plateforme !</h1>
      <h5>
        {{ message }}
      </h5>
      <button
        v-if="route.query.messageType === 'emailNotVerified'"
        class="btn btn-purple-gradient text-bold mt-4"
        :disabled="verifEmailLoading"
        @click="sendVerificationEmail"
      >
        <span
          v-if="verifEmailLoading"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
        Renvoyer l'email  de vérification
      </button>
      <p
        v-if="successMessage && !verifEmailLoading"
        class="text-green"
      >
        Email de vérification envoyé !
      </p>
      <p
        v-if="failMessage && !verifEmailLoading"
        class="text-danger"
      >
        Il y a eu un problème dans l'envoi de l'email de vérification, veuillez réessayer plus tard
      </p>
      <router-link
        v-if="redirectLink?.component"
        :to="{ name: redirectLink?.component }"
      >
        <button
          v-if="redirectLink?.component"
          class="btn btn-purple-gradient text-bold mt-4"
        >
          {{ redirectLink?.btnName }}
        </button>
      </router-link>
      <button
        v-if="route.query.messageType === 'emailNotVerified'"
        class="d-block btn btn-light text-bold mt-4 mx-auto"
        @click="handleReturn"
      >
        Retour
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

const route = useRoute();
const router = useRouter();
const store = useStore();
const scope = localStorage.getItem('scope');
const verifEmailLoading = computed(() => store.state.auth.email_verified_loading);
const successMessage = ref(false);
const failMessage = ref(false);
const queryMessageMatcher = {
  videos: 'Vous devez dabord compléter vos vidéos.',
  profile: 'Vous devez déjà compléter votre profil.',
  emailNotVerified: 'Vous devez vérifier votre email.',
};
const queryLinkMatcher = {
  user: {
    profile: { component: 'CandidateProfil', btnName: 'Compléter mon profil' },
    videos: { component: 'CandidateVideos', btnName: 'Compléter mes vidéos' },
  },
  recruiter: {
    profile: { component: 'CompanyProfil', btnName: 'Compléter mon profil' },
  },
};

const sendVerificationEmail = () => {
  store.dispatch('auth/resendVerifyEmail', {
    scope: localStorage.getItem('scope'),
    onSuccess: () => {
      failMessage.value = false;
      successMessage.value = true;
    },
    onFail: () => {
      failMessage.value = true;
      successMessage.value = false;
    },
  });
};
const message = computed(() => queryMessageMatcher?.[route.query.messageType]);
const redirectLink = computed(() => queryLinkMatcher?.[scope]?.[route.query.messageType]);

const handleReturn = async () => {
  await store.dispatch('auth/logout', {
    onSuccess: () => {
      router.push({ name: 'Home' });
    },
  });
};

</script>
