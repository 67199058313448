import Api from './Api';

export default {
  async getAll(data) {
    return Api.get('api/notifications-list', { headers: { scope: data.guard } });
  },
  async markAsRead(data) {
    return Api.post('api/notifications-mark-as-read', { headers: { scope: data.guard } });
  },
};
