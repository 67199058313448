import ContactMessage from '@/services/Contact';

export default {
  async sendMessage({ commit }, data) {
    commit('setContactMessageLoading', true);
    try {
      const contactMessage = await ContactMessage.sendMessage(data);
      commit('setContactMessage', contactMessage);
    } catch (error) {
      commit('setContactMessageErrors', error?.response?.data?.errors);
    }
    commit('setContactMessageLoading', false);
  },
};
