import Cities from '@/services/City';

export default {
  async getAll({ commit }, data) {
    commit('setCitiesLoading', true);
    try {
      const cities = await Cities.getAll(data);
      commit('setCities', cities);
    } catch (error) {
      commit('setCitiesErrors', error?.response?.data?.errors);
    }
    commit('setCitiesLoading', false);
  },
};
