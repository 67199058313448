import Chats from '@/services/Chat';

export default {
  async getConversations({ commit }, { id, data }) {
    commit('setConversationsLoading', true);
    try {
      const conversations = await Chats.getConversations({ id, data });
      commit('setConversations', conversations);
      commit('setConversationsErrors', []);
    } catch (error) {
      commit('setConversationsErrors', error?.response?.data?.errors);
    }
    commit('setConversationsLoading', false);
  },
  async disableConversation({ commit }, data) {
    commit('setConversationsLoading', true);
    try {
      await Chats.disableConversation(data.id);
      data.onSuccess();
      commit('setConversationsErrors', []);
    } catch (error) {
      commit('setConversationsErrors', error?.response?.data?.errors);
    }
    commit('setConversationsLoading', false);
  },
  async enableConversation({ commit }, data) {
    commit('setConversationsLoading', true);
    try {
      await Chats.enableConversation(data.id);
      data.onSuccess();
      commit('setConversationsErrors', []);
    } catch (error) {
      commit('setConversationsErrors', error?.response?.data?.errors);
    }
    commit('setConversationsLoading', false);
  },
  async getMessages({ commit }, data) {
    commit('setMessagesLoading', true);
    try {
      const messages = await Chats.getMessages(data);
      commit('setMessages', messages);
      data.onSuccess();
    } catch (error) {
      commit('setMessagesErrors', error?.response?.data?.errors);
    }
    commit('setMessagesLoading', false);
  },
  async saveMessage({ commit }, { id, data }) {
    commit('setMessageLoading', true);
    try {
      const messages = await Chats.saveMessage({ id, data });
      commit('setMessages', messages);
    } catch (error) {
      commit('setMessageErrors', error?.response?.data);
    }
    commit('setMessageLoading', false);
  },
  async setting({ commit }, data) {
    commit('setSettingLoading', true);
    try {
      const setting = await Chats.setting(data);
      commit('setSetting', setting);
    } catch (error) {
      commit('setSettingErrors', error?.response?.data?.errors);
    }
    commit('setSettingLoading', false);
  },

  async saveSetting({ commit }, data) {
    commit('setSaveSettingLoading', true);
    try {
      const response = await Chats.saveSetting(data);
      commit('setSetting', response);
      commit('setSaveSettingErrors', []);
    } catch (error) {
      commit('setSaveSettingErrors', error?.response?.data?.errors);
    }
    commit('setSaveSettingLoading', false);
  },

  async banMessages({ commit }, data) {
    commit('setBanMessagesLoading', true);
    try {
      const banMessages = await Chats.banMessages(data);
      commit('setBanMessages', banMessages);
    } catch (error) {
      commit('setBanMessagesErrors', error?.response?.data?.errors);
    }
    commit('setBanMessagesLoading', false);
  },

  async checkJitsiAccess({ commit }, data) {
    commit('setCheckJitsiAccessLoading', true);
    try {
      await Chats.checkJitsiAccess(data.id);
      commit('setCheckJitsiAccess', true);
    } catch (error) {
      if (error?.response?.status === 403) {
        data.onFail();
      }
      commit('setCheckJitsiAccess', false);
      commit('setCheckJitsiAccessErrors', error?.response?.data?.message);
    }
    commit('setCheckJitsiAccessLoading', false);
  },
};
