import Jobs from '@/services/Job';

export default {
  async getAll({ commit }, data) {
    commit('setJobsLoading', true);
    try {
      const jobs = await Jobs.getAll(data);
      commit('setJobs', jobs);
    } catch (error) {
      commit('setJobsErrors', error?.response?.data?.errors);
    }
    commit('setJobsLoading', false);
  },
};
