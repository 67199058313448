<template>
  <div>
    <h2>Détails de l'entreprise</h2>
    <nav
      class="mb-5"
      style="--bs-breadcrumb-divider: '•';"
      aria-label="breadcrumb"
    >
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="#">Accueil</a>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
        >
          Les entreprises
        </li>
      </ol>
    </nav>
    <div class="my-4">
      <router-link :to="{ name: 'CompaniesList' }">
        <img
          src="@/assets/images/icons/arrow-left.svg"
          alt="Retour aux entreprises"
          class="me-2"
        >
        <span>Retour aux entreprises</span>
      </router-link>
    </div>
    <div class="d-flex align-items-center mt-5">
      <h1>{{ formData.company_name }}</h1>
      <Badge
        class="ms-5"
        :status="
          { verified : formData?.status?.company_verified,
            deactivated : formData?.status?.company_deactivated,
            profile_status : formData?.status?.profile_status, }"
      />
    </div>
    <hr>
    <div class="d-flex justify-content-between flex-sm-column">
      <div class="d-flex justify-content-end flex-wrap">
        <button
          v-if="formData?.status?.profile_status === 'to_validate'"
          class="btn btn-green text-bold mx-2"
          data-bs-toggle="modal"
          data-bs-target=".validate-modal"
          :disabled="companyLoading"
        >
          <span
            v-if="companyLoading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
          Valider le compte
        </button>

        <button
          v-if="formData?.status?.profile_status === 'to_validate'"

          class="btn btn-orange text-bold mx-2"
          data-bs-toggle="modal"
          data-bs-target=".refuse-modal"
          :disabled="companyLoading"
        >
          <span
            v-if="companyLoading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
          Refuser le compte
        </button>
        <button
          class="btn btn-yellow text-bold mx-2"
          :disabled="companyLoading"
          @click="canModify = true"
        >
          <span
            v-if="companyLoading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
          Modifier les informations
        </button>
        <button
          v-if="!formData?.status?.user_deactivated"
          class="btn btn-outline-danger text-bold mx-2"
          data-bs-toggle="modal"
          data-bs-target=".deactivate-modal"
          :disabled="companyLoading"
        >
          <span
            v-if="companyLoading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
          Désactiver le compte
        </button>
      </div>
      <Modal
        class="validate-modal"
        :title="'Validation de compte'"
        :text="'Etes-vous sûr de vouloir valider ce compte entreprise ?'"
        :action-button="'Valider'"
        @callback="verifyAccount"
      />
      <Modal
        class="refuse-modal"
        :title="'Refus de compte'"
        :text="'Veuillez décrire le motif du refus'"
        :have-input="true"
        :action-button="'Refuser'"
        @callback="refuseAccount($event)"
      />
      <Modal
        class="deactivate-modal"
        :title="'Desactivation de compte'"
        :text="'Etes-vous sûr de vouloir desactiver ce compte entreprise ?'"
        :action-button="'Desactiver'"
        @callback="deactivateAccount"
      />
    </div>
    <div class="d-flex justify-content-end mt-3">
      <button
        v-if="canModify"
        class="btn btn-outline-danger text-bold mx-2"
        @click="canModify = false"
      >
        Annuler les modifications
      </button>
      <button
        v-if="canModify"
        class="btn btn-purple-gradient text-bold mx-2"
        data-bs-toggle="modal"
        data-bs-target=".modify-modal"
        :disabled="companyLoading"
      >
        <span
          v-if="companyLoading"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
        Sauvegarder les modifications
      </button>
    </div>
    <Modal
      class="modify-modal"
      :title="'Modification d\'informations'"
      :text="'Etes-vous sûr de vouloir modifier les informations de ce compte entreprise ?'"
      :action-button="'Modifier'"
      @callback="modifyAccount"
    />
    <h4 class="text-primary text-bold mt-5">
      Informations générales
    </h4>
    <hr>
    <div
      v-if="companyLoading"
      class="d-flex justify-content-center mt-5"
    >
      <div
        class="spinner-border"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div
      v-else
      class="row g-4"
    >
      <div class="col col-12 col-sm-3 ">
        <label class="form-label text-purple_grey text-bold">Nom de l'entreprise</label>
        <div class="input-group has-validation">
          <input
            v-model="formData.company_name"
            :disabled="!canModify"
            :class="[{ 'invalid' : updateCompanyErrors?.company_name }, 'form-control', 'w-100']"
            placeholder="Nom de l'entreprise"
            required
          >
          <span
            v-if="updateCompanyErrors?.company_name"
            class="text-danger"
          >{{ updateCompanyErrors?.company_name[0] }}</span>
        </div>
      </div>
      <div class="col col-12 col-sm-3 ">
        <label class="form-label text-purple_grey text-bold">Siret</label>
        <div class="input-group has-validation">
          <input
            v-model="formData.siret"
            :disabled="!canModify"
            :class="[{ 'invalid' : updateCompanyErrors?.siret }, 'form-control', 'w-100']"
            maxlength="14"
            placeholder="Siret (14 chiffres)"
            required
          >
          <span
            v-if="updateCompanyErrors?.siret"
            class="text-danger"
          >{{ updateCompanyErrors?.siret[0] }}</span>
        </div>
      </div>
      <div class="col col-12 col-sm-3 ">
        <label class="form-label text-purple_grey text-bold">Adresse</label>
        <div class="input-group has-validation">
          <input
            v-model="formData.address1"
            :disabled="!canModify"
            :class="[{ 'invalid' : updateCompanyErrors?.address1 }, 'form-control', 'w-100']"
            placeholder="Numéro et rue"
            required
          >
          <span
            v-if="updateCompanyErrors?.address1"
            class="text-danger"
          >{{ updateCompanyErrors?.address1[0] }}</span>
        </div>
      </div>
      <div class="col col-12 col-sm-3 ">
        <label class="form-label text-purple_grey text-bold">Complément d'adresse</label>
        <div class="input-group has-validation">
          <input
            v-model="formData.address2"
            :disabled="!canModify"
            :class="[{ 'invalid' : updateCompanyErrors?.address2 }, 'form-control', 'w-100']"
            placeholder="Complément d'adresse"
          >
          <span
            v-if="updateCompanyErrors?.address2"
            class="text-danger"
          >{{ updateCompanyErrors?.address2[0] }}</span>
        </div>
      </div>
      <div class="col col-6 col-sm-3 ">
        <SearchCities
          :errors="updateCompanyErrors?.city_id"
          :existing-city="company?.data?.general?.city"
          :disabled="!canModify"
          @send-city="sendCity($event)"
        />
      </div>
      <div class="col col-6 col-sm-3 ">
        <label class="form-label text-purple_grey text-bold">Ville</label>
        <div class="input-group has-validation">
          <input
            v-model="formData.city.name"
            disabled
            :class="['form-control', 'w-100']"
            placeholder="Veuillez choisir un code postal"
            required
          >
        </div>
      </div>
      <div class="col col-6 col-sm-3 ">
        <label class="form-label text-purple_grey text-bold">Téléphone</label>
        <div class="input-group has-validation">
          <input
            v-model="formData.phone_number"
            disabled
            :class="[{ 'invalid' : updateCompanyErrors?.phone_number }, 'form-control', 'w-100']"
            placeholder="Téléphone"
          >
          <span
            v-if="updateCompanyErrors?.phone_number"
            class="text-danger"
          >{{ updateCompanyErrors?.phone_number[0] }}</span>
        </div>
      </div>
      <div class="col col-6 col-sm-3 ">
        <label class="form-label text-purple_grey text-bold">Abonnements</label>
        <div class="input-group has-validation">
          <select
            v-model="formData.subscription_id"
            :disabled="!canModify"
            :class="[{ 'invalid' : updateCompanyErrors?.subscription_id }, 'form-select', 'w-100']"
          >
            <option
              v-for="(subcription,index) in subscriptions"
              :key="index"
              :value="subcription.id"
            >
              {{ subcription.name }}
            </option>
          </select>
          <span
            v-if="updateCompanyErrors?.subscription_id"
            class="text-danger"
          >{{ updateCompanyErrors?.subscription_id[0] }}</span>
        </div>
      </div>
      <div class="col col-12 col-sm-3 ">
        <label class="form-label text-purple_grey text-bold">Email</label>
        <input
          v-model="formData.email"
          disabled
          type="email"
          :class="[{ 'invalid' : updateCompanyErrors?.email }, 'form-control', 'w-100']"
          placeholder="Adresse email"
          required
        >
        <span
          v-if="updateCompanyErrors?.email"
          class="text-danger"
        >{{ updateCompanyErrors?.email[0] }}</span>
      </div>
    </div>
    <h4 class="text-primary text-bold mt-5">
      Informations détaillées
    </h4>
    <hr>
    <div
      v-if="companyLoading"
      class="d-flex justify-content-center mt-5"
    >
      <div
        class="spinner-border"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div
      v-else
      class="row g-4"
    >
      <div class="col col-12 col-md-6 col-sm-12 col-lg-3">
        <label class="form-label text-purple_grey text-bold">N° TVA intracommunautaire</label>
        <div class="input-group has-validation">
          <input
            v-model="formData.tva_number"
            :disabled="!canModify"
            :class="[{ 'invalid' : updateCompanyErrors?.tva_number }, 'form-control', 'w-100']"
            placeholder="N° TVA intracommunautaire"
            required
          >
          <span
            v-if="updateCompanyErrors?.tva_number"
            class="text-danger"
          >{{ updateCompanyErrors?.tva_number[0] }}</span>
        </div>
      </div>
      <div class="col col-12 col-md-6 col-sm-12 col-lg-3">
        <label class="form-label text-purple_grey text-bold">Effectif</label>
        <div class="input-group has-validation">
          <select
            v-model="formData.company_size_id"
            :disabled="!canModify"
            :class="[{ 'invalid' : updateCompanyErrors?.company_size_id }, 'form-select', 'w-100']"
          >
            <option
              v-for="companySize in companySizes"
              :key="companySize.id"
              :value="companySize.id"
            >
              {{ companySizeLabel(companySize) }}
            </option>
          </select>
          <span
            v-if="updateCompanyErrors?.company_size_id"
            class="text-danger"
          >{{ updateCompanyErrors?.company_size_id[0] }}</span>
        </div>
      </div>
      <div class="col col-12 col-md-6 col-sm-12 col-lg-3">
        <label class="form-label text-purple_grey text-bold">Secteur d'activité</label>
        <div class="input-group has-validation">
          <select
            v-model="formData.company_sector_id"
            :disabled="!canModify"
            :class="[
              { 'invalid' : updateCompanyErrors?.company_sector_id }, 'form-select', 'w-100']"
          >
            <option
              v-for="sector in companySectors"
              :key="sector.id"
              :value="sector.id"
            >
              {{ sector.name }}
            </option>
          </select>
          <span
            v-if="updateCompanyErrors?.company_sector_id"
            class="text-danger"
          >{{ updateCompanyErrors?.company_sector_id[0] }}</span>
        </div>
      </div>
      <div class="col col-12 col-md-6 col-sm-12 col-lg-3">
        <label class="form-label text-purple_grey text-bold">Nom du groupe</label>
        <div class="input-group has-validation">
          <input
            v-model="formData.group_name"
            :disabled="!canModify"
            :class="[{ 'invalid' : updateCompanyErrors?.group_name }, 'form-control', 'w-100']"
            placeholder="Nom du groupe"
          >
          <span
            v-if="updateCompanyErrors?.group_name"
            class="text-danger"
          >{{ updateCompanyErrors?.group_name[0] }}</span>
        </div>
      </div>
      <div class="col col-12 col-md-6 col-sm-12 col-lg-3 ">
        <label class="form-label text-purple_grey text-bold">Description de l'entreprise</label>
        <div class="input-group has-validation">
          <textarea
            v-model="formData.description"
            :disabled="!canModify"
            :class="[{ 'invalid' : updateCompanyErrors?.description }, 'form-control', 'w-100']"
            placeholder="Description de l'entreprise (300 caractères max)"
            maxlength="300"
          />
          <span
            v-if="updateCompanyErrors?.description"
            class="text-danger"
          >{{ updateCompanyErrors?.description[0] }}</span>
        </div>
      </div>
    </div>
    <div
      v-if="!companyLoading"
      class="row g-4 mt-2"
    >
      <div class="col col-12 col-md-6 col-sm-12 col-lg-3">
        <label class="form-label text-purple_grey text-bold">Logo de l'entreprise</label>
        <div
          class="d-flex"
        >
          <div class="logo-company">
            <img
              :src="`${VITE_API_URL}/${formData.logo}`"
              alt="Logo de l'entreprise"
            >
          </div>
        </div>
      </div>
      <div class="col col-12 col-md-6 col-sm-12 col-lg-6">
        <div class="col col-12 col-md-6 col-sm-12 col-lg-6">
          <div class="form-label text-purple_grey text-bold">
            Vidéo de présentation de l'entreprise
          </div>
          <div
            class="d-flex"
          >
            <div class="video-company-profile">
              <video
                v-if="formData.video"
                controls
              >
                <source
                  :src="`${VITE_API_URL}/${formData?.video}`"
                  type="video/mp4"
                >
              </video>
            </div>
          </div>
        </div>
      </div>
      <h4 class="text-primary text-bold mt-5">
        Recruteurs
      </h4>
      <hr>
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th scope="col">
              Nom
            </th>
            <th scope="col">
              email
            </th>
            <th scope="col">
              Date de création
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="recruiter in formData.recruiters"
            :key="recruiter.id"
            class="align-middle"
          >
            <td>{{ recruiter.first_name }} {{ recruiter.second_name }}</td>
            <td>{{ recruiter.email }}</td>
            <td>{{ normalizer.formatDate(recruiter.created_at) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import {
  computed, onMounted, ref, watch,
} from 'vue';
import Modal from '@/components/modal/Modal.vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import Badge from '@/components/badge/Badge.vue';
import normalizer from '@/utils/normalizer';
import SearchCities from '@/components/searchCities/SearchCities.vue';

const store = useStore();
const route = useRoute();
const router = useRouter();
const canModify = ref(false);

const company = computed(() => store.state.company.company_detail.data);
const companyLoading = computed(() => store.state.company.company_detail_loading);
const companySizes = computed(() => store?.state?.companySizes?.company_sizes?.data?.data);
const companySectors = computed(() => store?.state?.companySector?.company_sector?.data?.data);
const subscriptions = computed(() => store?.state?.subscriptions?.subscriptions?.data?.data);
const updateCompanyErrors = computed(() => store.state.company.company_detail_errors);

const { VITE_API_URL } = import.meta.env;

const formData = ref({
  company_id: null,
  company_name: null,
  siret: null,
  email: null,
  address1: null,
  address2: null,
  zip_code: null,
  city: {
    name: null,
    zip_code: null,
  },
  city_id: null,
  phone_number: null,
  subscription_id: null,
  tva_number: null,
  company_size_id: null,
  company_sector_id: null,
  description: null,
  group_name: null,
  video: null,
  recruiters: [],
  status: {},
});
const sendCity = (data) => {
  if (data) {
    formData.value.city_id = data?.id;
    formData.value.zip_code = data?.zip_code;
    formData.value.city = data;
  }
};
const reloadBadge = () => {
  formData.value.status = company.value.data.status;
};
const loadData = () => {
  formData.value.company_id = company.value.data.general.company_id;
  formData.value.company_name = company.value.data.child_profile
    ? company.value.data.child_profile.company_name : company.value.data.general.company_name;
  formData.value.siret = company.value.data.child_profile
    ? company.value.data.child_profile.siret : company.value.data.profile.siret;
  formData.value.email = company.value.data.general.email;
  formData.value.address1 = company.value.data.general.address1;
  formData.value.address2 = company.value.data.general.address2;
  formData.value.city = company.value.data.general.city;
  formData.value.zip_code = company.value.data.general.city.zip_code;
  formData.value.city_id = company.value.data.general.city.id;
  formData.value.city_name = company.value.data.general.city.name;
  formData.value.phone_number = company.value.data.general.phone_number;
  formData.value.tva_number = company.value.data.child_profile
    ? company.value.data.child_profile.tva_number : company.value.data.profile.tva_number;
  formData.value.company_size_id = company.value.data.child_profile
    ? company.value.data.child_profile.company_size.id
    : company.value.data.profile.company_size.id;
  formData.value.company_sector_id = company.value.data.child_profile
    ? company.value.data.child_profile.company_sector.id
    : company.value.data.profile.company_sector.id;
  formData.value.description = company.value.data.child_profile
    ? company.value.data.child_profile.description : company.value.data.profile.description;
  formData.value.subscription_id = company.value.data.general.subscription.id;
  formData.value.group_name = company.value.data.child_profile
    ? company.value.data.child_profile.group_name : company.value.data.profile.group_name;
  formData.value.video = company.value.data.child_profile
    ? company.value.data.child_profile.video : company.value.data.profile.video;
  formData.value.logo = company.value.data.child_profile
    ? company.value.data.child_profile.logo : company.value.data.profile.logo;
  formData.value.recruiters = company.value.data.recruiters;
  reloadBadge();
};

const verifyAccount = () => {
  store.dispatch('company/verify', {
    id: route.params.id,
    onSuccess: () => {
      router.push({ name: 'CompaniesList' });
    },
  });
};

const refuseAccount = (value) => {
  store.dispatch('company/refuse', {
    id: route.params.id,
    description: value,
    onSuccess: () => {
      router.push({ name: 'CompaniesList' });
    },
  });
};

const deactivateAccount = () => {
  store.dispatch('company/deactivate', {
    id: route.params.id,
    onSuccess: () => {
      router.push({ name: 'CompaniesList' });
    },
  });
};

const modifyAccount = () => {
  store.dispatch('company/modify', {
    data: formData.value,
    onSuccess: () => {
      canModify.value = false;
      router.push({ name: 'CompaniesList' });
    },
  });
};

const companySizeLabel = (companySize) => {
  if (companySize.minimum === null) {
    return `-${companySize.maximum}`;
  }
  if (companySize.maximum === null) {
    return `+${companySize.minimum}`;
  }
  return `${companySize.minimum}-${companySize.maximum}`;
};

watch(company, (value) => {
  if (value.data) {
    loadData();
  }
});

onMounted(() => {
  store.dispatch('company/getOne', route.params.id);
  store.dispatch('companySector/getAll');
  store.dispatch('companySizes/getAll');
  store.dispatch('subscriptions/getAll');
});

</script>
