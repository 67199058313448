<template>
  <div>
    <h2>Détails de l'offre</h2>
    <nav
      class="mb-5"
      style="--bs-breadcrumb-divider: '•';"
      aria-label="breadcrumb"
    >
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name :'CandidateDashboard' }">
            Dashboard
          </router-link>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
        >
          Détails d'une offre
        </li>
      </ol>
    </nav>
    <h2>
      <span class="text-bold ms-3">
        {{ offer?.title }}
      </span>
    </h2>
    <div class="row g-4">
      <div class="col col-12 col-lg-9 p-4">
        <video
          v-if="offer?.video"
          :src="`${VITE_API_URL}/${offer?.video}`"
          controls
          class="rounded w-100"
        />
        <div v-else>
          <div class="row g-4">
            <div class="col col-12 col-sm-8">
              <h4>Description</h4>
              <hr>
              <div>
                {{ offer?.description }}
              </div>
            </div>
            <div class="col col-12 col-sm-4">
              <h4>Avantages</h4>
              <hr>
              <div>
                {{ offer?.advantages }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col col-12 col-lg-3 p-4 d-flex flex-column text-center">
        <div v-if="offer?.state === 'active' && offer?.status !== 'deactivate'">
          <div
            v-if="offer?.user_step === 'new'"
            class="bg-blue text-light p-3 rounded-3 mb-3"
          >
            <span class="card-text-lg text-bold">
              Nouvelle offre !
            </span>
          </div>
          <div
            v-if="offer?.user_step === 'accepted'"
            class="bg-green text-light p-3 rounded-3 mb-3"
          >
            <span class="card-text-lg text-bold">
              Offre acceptée
            </span>
          </div>
          <div
            v-if="offer?.user_step === 'refused'"
            class="bg-danger text-light p-3 rounded-3 mb-3"
          >
            <span class="card-text-lg text-bold">
              Offre refusée
            </span>
          </div>
        </div>
        <div
          v-else
          class="bg-danger text-light p-3 rounded-3 mb-3"
        >
          <span class="card-text-lg text-bold">
            L' offre a été désactivée
          </span>
        </div>
        <div
          v-if="offer?.recruiter_step === 'refused'"
          class="bg-danger text-light p-3 rounded-3 mb-3"
        >
          <span class="card-text-lg text-bold">
            Candidature refusée
          </span>
        </div>
        <div
          v-if="offer?.recruiter_step === 'refused'"
          class="border-2 border border-danger text-danger p-3 rounded-3 mb-3"
        >
          <span>
            {{ offer?.matching_refused_notification?.description }} <br>
            Motif : {{ offer?.matching_refused_notification?.ground_of_refusal }}
          </span>
        </div>
        <span
          class="score-bar-progress-label"
          :style="
            [offer?.score > 75 ? { 'color': '#63c555' }
              : [ offer?.score > 50 ? { 'color': '#ebfa74' }
                : { 'color': '#f05745' }] ] "
        >MATCHING {{ offer?.score }}%</span>
        <div class="progress bg-white_grey rounded-pill mb-4">
          <div
            class="progress-bar"
            role="progressbar"
            :style="[ { 'width': offer?.score + '%' },
                      [offer?.score > 75 ? { 'background-color': '#63c555' }
                        : [ offer?.score > 50 ? { 'background-color': '#ebfa74' }
                          : { 'background-color': '#f05745' }] ] ]"
            :aria-valuenow="offer?.score"
            aria-valuemin="0"
            aria-valuemax="100"
          />
        </div>
        <div class="bg-grey p-3">
          <span class="text-purple_grey text-bold">Localisation : </span>
          <span>{{ offer?.city?.name ? offer?.city?.name + ' ' + offer?.city?.zip_code
            : offer?.telework === 'full'
              ? '100% Télétravail' : 'Télétravail partiel' }}</span>
        </div>
        <div class="bg-dark_grey p-3">
          <span class="text-purple_grey text-bold">Secteur : </span>
          <span>{{ offer?.company_sector.name }}</span>
        </div>
        <div class="bg-grey p-3">
          <span class="text-purple_grey text-bold">Salaire : </span>
          <span>{{ offer?.salary_minimum + '-' + offer?.salary_maximum + ' €' }}</span>
        </div>
        <div class="bg-dark_grey p-3">
          <span class="text-purple_grey text-bold">Type de contrat : </span>
          <span
            v-for="type in offer?.contract_types"
            :key="type.id"
          >
            {{ type.name }}
          </span>
        </div>
        <div class="bg-dark_grey p-3">
          <span class="text-purple_grey text-bold">Vidéos de présentation obligatoires : </span>
          <span> {{ offer?.p_videos_required ? 'Oui' : 'Non' }}</span>
        </div>

        <div
          v-if="offer?.state === 'active' && offer?.status !== 'deactivate'"
          class="d-flex flex-column"
        >
          <div
            v-if="offer?.mv_refused_notification"
            class="border border-danger border-3 text-danger p-3 rounded-3 mt-2"
          >
            <span class="text-bold">
              Vidéo de motivation refusée <br>
              motif : {{ offer?.mv_refused_notification.ground_of_refusal }}
            </span>
          </div>
          <div
            v-if="offer?.matching_video?.status === 'accepted'"
            class="border border-green border-3 text-green p-3 rounded-3 mt-2"
          >
            <span class="text-bold">
              Vidéo de motivation acceptée
            </span>
          </div>
          <button
            v-if="offer?.user_step === 'new' ||
              offer?.user_step ==='refused'"
            class="btn btn-green mt-2 text-bold text-light"
            @click="acceptOffer"
          >
            Candidater
          </button>
          <button
            v-if="offer?.user_step === 'new'"
            class="btn btn-danger mt-2 text-bold"
            @click="refuseOffer"
          >
            Refuser l'offre
          </button>
          <VideoModal
            v-if="(offer?.user_step ==='accepted' && offer?.recruiter_step ==='new') ||
              (offer?.user_step ==='accepted' && offer?.recruiter_step ==='accepted')"
            class="mt-3"
            :video="offer?.matching_video?.video"
            :cancel-button="'Quitter'"
          />
          <button
            v-if="offer?.recruiter_step ==='accepted'"
            class="btn btn-purple-gradient text-bold my-2"
            @click="chatWithRecruiter"
          >
            Echanger sur la messagerie
          </button>
          <span
            v-if="myChatError"
            class="text-danger"
          >{{ myChatError }}</span>
        </div>
      </div>
    </div>
    <div v-if="offer?.video">
      <div class="row g-4">
        <div class="col col-12 col-sm-8">
          <h4>Description</h4>
          <hr>
          <div>
            {{ offer?.description }}
          </div>
        </div>
        <div class="col col-12 col-sm-4">
          <h4>Avantages</h4>
          <hr>
          <div>
            {{ offer?.advantages }}
          </div>
        </div>
      </div>
    </div>
    <div class="row  mt-4  g-4">
      <h4>L'entreprise</h4>
      <hr>
      <div class="row g-4 w-100">
        <div class="col col-12 d-flex flex-column align-items-center">
          <video
            v-if="offer?.company_video && showVideo"
            controls
            :src="`${VITE_API_URL}/${offer?.company_video}`"
            class="fit-cover w-100 video-container"
          />
          <div>
            <span
              v-if="!showVideo || !offer?.company_video"
              @click="showVideo = true"
            >
              <img
                :src="`${VITE_API_URL}/${offer?.company_logo}`"
                alt="Logo de l'entreprise"
                class="logo-resizer logo-container cursor-pointer fit-contain"
              >
              <img
                v-if="offer?.company_video"
                src="@/assets/images/play.png"
                alt="Lancer la vidéo de l'offre"
                class="position-absolute play-video-offer_candidate-detail"
              >
            </span>
          </div>

          <h3 class="mt-4">
            Nom : <span class="text-bold">{{ offer?.company_name }}</span>
          </h3>
          <h4 class="mt-2">
            Secteur : <span class="text-bold">{{ offer?.company_sector.name }}</span>
          </h4>
          <span class="text-center mt-2">Description : {{ offer?.company_description }}</span>
        </div>
      </div>
    </div>
  </div>
  <info-modal
    :show="updateMatchingFailure"
    class="cannot-apply-modal"
    :title="'Impossible de postuler'"
    :text="applicationUpdateStepErrors?.message"
    :cancel-button="`C'est compris`"
    @close="updateMatchingFailure = false"
  />
</template>

<script setup>

import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import VideoModal from '@/components/modal/VideoModal.vue';
import InfoModal from '@/components/modal/ModalInfo.vue';

const store = useStore();
const route = useRoute();
const router = useRouter();
const showVideo = ref(false);
const offer = computed(() => store.state.application.application_detail?.data?.data);
const applicationUpdateStepErrors = computed(
  () => store.state.application.application_update_step_errors ?? null,
);
const myChat = computed(() => store.state.matching?.my_chat?.data);
const myChatError = computed(() => store.state.matching?.my_chat_error);
const { VITE_API_URL } = import.meta.env;

const updateMatchingFailure = ref(false);

const acceptOffer = async () => {
  await store.dispatch('application/update', { id: route.params.id, data: { step: 'accepted' } });
  updateMatchingFailure.value = applicationUpdateStepErrors.value !== null;
};

const refuseOffer = () => {
  store.dispatch('application/update', { id: route.params.id, data: { step: 'refused' } });
};
const chatWithRecruiter = async () => {
  await store.dispatch('matching/myChat', {
    id: route.params.id,
    onSuccess: () => {
      if (myChat.value?.data?.id) {
        router.push({ name: 'CandidateChat', params: { roomId: myChat.value?.data?.id } });
      }
    },
  });
};

onMounted(() => store.dispatch('application/getOne', route.params.id));

</script>
