import Tutorial from '@/services/Tutorial';

export default {
  async getAll({ commit }, filter) {
    commit('setTutorialsLoading', true);
    try {
      const tutorials = await Tutorial.getAll(filter);
      commit('setTutorials', tutorials);
      commit('setTutorialsErrors', []);
    } catch (error) {
      commit('setTutorialsErrors', error?.response?.data?.errors);
    }
    commit('setTutorialsLoading', false);
  },
  async getOne({ commit }, id) {
    commit('setTutorialDetailLoading', true);
    try {
      const data = await Tutorial.getOne(id);
      commit('setTutorialDetail', data);
      commit('setTutorialDetailErrors', []);
    } catch (error) {
      commit('setTutorialDetailErrors', error?.response?.data?.errors);
    }
    commit('setTutorialDetailLoading', false);
  },
  async create({ commit }, form) {
    commit('setTutorialDetailLoading', true);
    try {
      await Tutorial.create(form.data);
      form.onSuccess();
    } catch (error) {
      commit('setTutorialDetailErrors', error?.response?.data?.errors);
    }
    commit('setTutorialDetailLoading', false);
  },
  async update({ commit }, form) {
    commit('setTutorialDetailLoading', true);
    try {
      await Tutorial.update(form);
      form.onSuccess();
    } catch (error) {
      commit('setTutorialDetailErrors', error?.response?.data?.errors);
    }
    commit('setTutorialDetailLoading', false);
  },
  async delete({ commit }, data) {
    commit('setTutorialDetailLoading', true);
    try {
      await Tutorial.delete(data.id);
      data.onSuccess();
    } catch (error) {
      commit('setTutorialDetailErrors', error?.response?.data?.errors);
    }
    commit('setTutorialDetailLoading', false);
  },
};
