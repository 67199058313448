export default {
  setCompanies(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.companies = value;
  },
  setCompaniesErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.companies_errors = value;
  },
  setCompaniesLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.companies_loading = value;
  },
  setCompanyDetail(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.company_detail = value;
  },
  setCompanyDetailErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.company_detail_errors = value;
  },
  setCompanyDetailLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.company_detail_loading = value;
  },
  setRecruiters(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.recruiters = value;
  },
  setRecruitersErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.recruiters_errors = value;
  },
  setRecruitersLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.recruiters_loading = value;
  },
  setAddRecruiterErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.add_recruiter_errors = value;
  },
  setAddRecruiterMessageError(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.add_recruiter_message_error = value;
  },
  setAddRecruiterLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.add_recruiter_loading = value;
  },
};
