export default {
  applications: [],
  applications_errors: [],
  applications_loading: false,
  application_detail: {},
  application_errors: [],
  application_loading: false,
  application_detail_errors: [],
  application_update_step_errors: null,
};
