<template>
  <div class="card overflow-hidden">
    <video
      v-if="data.video && showVideo"
      controls
      :src="`${VITE_API_URL}/${data.video}`"
      class="card-img-top fit-cover w-100"
    />
    <span
      v-if="!showVideo || !data.video"
      @click="showVideo = true"
    >
      <img
        :src="`${VITE_API_URL}/${data?.company_logo}`"
        alt="Logo de l'entreprise"
        class="card-img-top fit-contain"
      >
      <img
        v-if="data.video"
        src="@/assets/images/play.png"
        alt="Lancer la vidéo de l'offre"
        class="position-absolute play-video-offer"
      >
    </span>
    <div class="card-body">
      <router-link :to="{ name: 'CandidateOfferDetail', params: { id: data?.matching_id } }">
        <div class="h-100 d-flex flex-column justify-content-between">
          <div>
            <div class="card-text-md text-bold">
              {{ data?.company_name }}
            </div>
            <div class="card-text-lg text-bold">
              {{ data?.title }}
            </div>
            <div class="">
              <img
                src="@/assets/images/icons/marker.svg"
                alt="city"
                class="me-1"
              >{{ data?.city?.name ? data?.city?.name : data?.telework === 'full'
                ? '100% Télétravail' : 'Télétravail partiel' }}
            </div>
            <div class="mt-1">
              <div class="w-100">
                <div
                  v-for="type in data?.contract_types"
                  :key="type.id"
                  class="badge rounded-pill bg-black text-yellow text-bold"
                >
                  {{ type.name }}
                </div>
              </div>

              <div
                class="badge rounded-pill bg-black text-primary text-bold"
              >
                {{ data?.salary_minimum + '-' + data?.salary_maximum + ' €' }}
              </div>
            </div>
            <div class="mt-2 text-end">
              <!--              <div-->
              <!--                v-if="data.recruiter_step === null && data.user_step === 'new'"-->
              <!--                class="badge bg-blue"-->
              <!--              >-->
              <!--                Nouvelle-->
              <!--              </div>-->
              <div
                v-if="data.recruiter_step === 'new' && data.user_step === 'accepted'"
                class="badge bg-blue"
              >
                J'ai postulé
              </div>
              <div
                v-if="data.recruiter_step === 'accepted' && data.user_step === 'accepted'"
                class="badge bg-green"
              >
                Shortlistée
              </div>
              <div
                v-if="data.recruiter_step === null && data.user_step === 'refused'"
                class="badge bg-orange"
              >
                Je ne souhaite pas postuler
              </div>
              <div
                v-if="data.recruiter_step === 'refused' && data.user_step === 'accepted'"
                class="badge bg-danger"
              >
                Candidature rejetée
              </div>
            </div>
          </div>
          <div>
            <hr class="w-150 ms-n5">
            <span
              class="score-bar-progress-label"
              :style="[{ 'left': [data.score === 100 ? (data.score*250/100 - 88) :
                         (data.score*250/100 - 75) > 0 ?
                           (data.score*250/100 - 75) :
                           5]+ 'px' },
                       [data.score > 75 ? { 'color': '#63c555' }
                         : [ data.score > 50 ? { 'color': '#ebfa74' }
                           : { 'color': '#f05745' }] ]] "
            >MATCHING {{ data.score }}%</span>
            <div class="progress score-bar-progress">
              <div
                class="progress-bar"
                role="progressbar"
                :style="[ { 'width': data.score + '%' },
                          [data.score > 75 ? { 'background-color': '#63c555' }
                            : [ data.score > 50 ? { 'background-color': '#ebfa74' }
                              : { 'background-color': '#f05745' }] ] ]"
                :aria-valuenow="data.score"
                aria-valuemin="0"
                aria-valuemax="100"
              />
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue';

const { VITE_API_URL } = import.meta.env;
const showVideo = ref(false);
const props = defineProps({
  data: Object,
});
</script>
