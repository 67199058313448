export default {
  setApplications(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.applications = value;
  },
  setApplicationsErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.applications_errors = value;
  },
  setApplicationsLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.applications_loading = value;
  },
  setApplicationDetail(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.application_detail = value;
  },
  setApplicationDetailErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.application_detail_errors = value;
  },
  setApplicationUpdateStepErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.application_update_step_errors = value;
  },
  setApplicationDetailLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.application_detail_loading = value;
  },
};
