import CompanySector from '@/services/CompanySector';

export default {
  async getAll({ commit }) {
    commit('setCompanySectorLoading', true);
    try {
      const companySector = await CompanySector.getAll();
      commit('setCompanySector', companySector);
    } catch (error) {
      commit('setCompanySectorErrors', error?.response?.data?.errors);
    }
    commit('setCompanySectorLoading', false);
  },
};
