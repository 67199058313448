<template>
  <div>
    <div
      class="videos-ban-full ban-full mb-5 bg-dark_grey "
    />
    <h1
      class="d-flex title-page text-black
     flex-column align-items-end justify-content-end position-relative"
    >
      <b class="text-uppercase">Mes vidéos</b>
    </h1>
    <div class="page-content">
      <Info
        v-if="count >= 4 && !completionProgress.completedInformations"
        :text="`Pour que vos vidéos soient validées
          par nos administrateurs, vous devez d'abord remplir vos informations de profil`"
      />
      <Info
        v-if="count < 4"
        :text="'Pour que vos vidéos soient validées par nos administrateurs,' +
          ' il ne vous reste plus qu\'à enregistrer vos quatre vidéos. '"
        :subtext="`Nombre de vidéos complétées : ${count}`"
      />
      <Info
        v-if="count === 4
          && completionProgress.completedInformations
          && (
            completionProgress.status === 'videos_to_validate'
            || completionProgress.status === 'to_validate'
          )"
        :text="`Vos vidéos sont en attente de validation par nos administrateurs`"
      />
      <ul
        id="myTab"
        class="nav nav-pills d-flex flex-md-row flex-sm-column
      justify-content-center align-items-center row"
        role="tablist"
      >
        <li
          class="nav-item col-12 col-md-3 p-1"
          role="presentation"
        >
          <button
            id="presentation-tab"
            class="nav-link active d-flex flex-column justify-content-center
          align-items-center video-min bg-grey rounded  m-auto w-100"
            data-bs-toggle="tab"
            data-bs-target="#presentation"
            type="button"
            role="tab"
            aria-controls="presentation"
            aria-selected="true"
          >
            <span
              v-if="!videoPresentation"
              class="btn-black rounded"
            >
              <video
                v-show="!videoPresentation"
                src=""
                class="w-100 video-min"
                style="max-height: 350px; background-color: black"
              />
            </span>

            <video
              v-else
              controls
              :src="`${VITE_API_URL}/${videoPresentation?.child?.video ?
                videoPresentation?.child?.video : videoPresentation?.video}`"
              class="w-100 video-min"
              style="max-height: 350px; background-color: black"
            />
            <span class=" mt-2 d-flex justify-content-center align-items-center">
              <img
                v-if="videoPresentation"
                src="@/assets/images/icons/icon-validate.svg"
                alt="Vidéo enregistrée"
              >
              <img
                v-else
                src="@/assets/images/icons/icon-block.svg"
                alt="Vidéo enregistrée"
              >
              <span class="ms-1">
                Présentation et postes recherchés
              </span>

            </span>
          </button>
        </li>
        <li
          class="nav-item col-12 col-md-3 p-1"
          role="presentation"
        >
          <button
            id="experiences-tab"
            class="nav-link d-flex flex-column justify-content-center
          align-items-center video-min  bg-grey rounded  m-auto w-100"
            data-bs-toggle="tab"
            data-bs-target="#experiences"
            type="button"
            role="tab"
            aria-controls="experiences"
            aria-selected="false"
          >
            <span
              v-if="!videoExperiences"
              class="btn-black rounded"
            >
              <video
                v-show="!videoExperiences"
                src=""
                class="w-100 video-min"
                style="max-height: 350px; background-color: black"
              />
            </span>

            <video
              v-else
              controls
              :src="`${VITE_API_URL}/${videoExperiences?.child?.video ?
                videoExperiences?.child?.video : videoExperiences?.video}`"
              class="w-100 video-min"
              style="max-height: 350px; background-color: black"
            />
            <span class=" mt-2 d-flex justify-content-center align-items-center">
              <img
                v-if="videoExperiences"
                src="@/assets/images/icons/icon-validate.svg"
                alt="Vidéo enregistrée"
              >
              <img
                v-else
                src="@/assets/images/icons/icon-block.svg"
                alt="Vidéo enregistrée"
              >
              <span class="ms-1">
                Expériences professionnelles
              </span>
            </span>
          </button>
        </li>
        <li
          class="nav-item col-12 col-md-3 p-1"
          role="presentation"
        >
          <button
            id="trainings-tab"
            class="nav-link d-flex flex-column justify-content-center
          align-items-center video-min  bg-grey rounded  m-auto w-100"
            data-bs-toggle="tab"
            data-bs-target="#trainings"
            type="button"
            role="tab"
            aria-controls="trainings"
            aria-selected="false"
          >
            <span
              v-if="!videoCourses"
              class="btn-black rounded"
            >
              <video
                v-show="!videoCourses"
                src=""
                class="w-100 video-min"
                style="max-height: 350px; background-color: black"
              />
            </span>
            <video
              v-else
              controls
              :src="`${VITE_API_URL}/${videoCourses?.child?.video ?
                videoCourses?.child?.video : videoCourses?.video}`"
              class="w-100 video-min"
              style="max-height: 350px; background-color: black"
            />
            <span class=" mt-2 d-flex justify-content-center align-items-center">
              <img
                v-if="videoCourses"
                src="@/assets/images/icons/icon-validate.svg"
                alt="Vidéo enregistrée"
              >
              <img
                v-else
                src="@/assets/images/icons/icon-block.svg"
                alt="Vidéo enregistrée"
              >
              <span class="ms-1">
                Formations
              </span>
            </span>
          </button>
        </li>
        <li
          class="nav-item col-12 col-md-3 p-1"
          role="presentation"
        >
          <button
            id="hobbies-tab"
            class="nav-link d-flex flex-column justify-content-center
           align-items-center video-min  bg-grey rounded  m-auto w-100"
            data-bs-toggle="tab"
            data-bs-target="#hobbies"
            type="button"
            role="tab"
            aria-controls="hobbies"
            aria-selected="false"
          >
            <span
              v-if="!videoHobbies"
              class="btn-black rounded"
            >
              <video
                v-show="!videoHobbies"
                src=""
                class="w-100 video-min"
                style="max-height: 350px; background-color: black"
              />
            </span>

            <video
              v-else
              controls
              :src="`${VITE_API_URL}/${videoHobbies?.child?.video ?
                videoHobbies?.child?.video : videoHobbies?.video}`"
              class="w-100 video-min"
              style="max-height: 350px; background-color: black"
            />
            <span class=" mt-2 d-flex justify-content-center align-items-center">
              <img
                v-if="videoHobbies"
                src="@/assets/images/icons/icon-validate.svg"
                alt="Vidéo enregistrée"
              >
              <img
                v-else
                src="@/assets/images/icons/icon-block.svg"
                alt="Vidéo enregistrée"
              >
              <span class="ms-1">
                Loisirs et atouts
              </span>
            </span>
          </button>
        </li>
      </ul>
      <div
        id="myTabContent"
        class="tab-content"
      >
        <div
          id="presentation"
          class="tab-pane fade show active"
          role="tabpanel"
          aria-labelledby="presentation-tab"
        >
          <VideoPage
            :id="videoPresentation?.child?.id ? videoPresentation?.child?.id : videoPresentation?.id"
            :video="videoPresentation?.child ?
              videoPresentation?.child?.video : videoPresentation?.video"

            :title="'La vidéo de présentation et postes recherchés'"
            :type="'video_presentation'"
            :text="text"
            @onChangeVideo="reload"
          />
        </div>
        <div
          id="experiences"
          class="tab-pane fade"
          role="tabpanel"
          aria-labelledby="experiences-tab"
        >
          <VideoPage
            :id="videoExperiences?.child?.id ? videoExperiences?.child?.id : videoExperiences?.id"
            :title="'La vidéo des expériences professionnelles'"
            :video="videoExperiences?.child?.video ?
              videoExperiences?.child?.video : videoExperiences?.video"
            :type="'video_experiences'"
            :text="text"
            @onChangeVideo="reload"
          />
        </div>
        <div
          id="trainings"
          class="tab-pane fade"
          role="tabpanel"
          aria-labelledby="trainings-tab"
        >
          <VideoPage
            :id="videoCourses?.child?.id ? videoCourses?.child?.id : videoCourses?.id"
            :title="'La vidéo des formations'"
            :video="videoCourses?.child?.video ?
              videoCourses?.child?.video : videoCourses?.video"
            :type="'video_courses'"
            :text="text"
            @onChangeVideo="reload"
          />
        </div>
        <div
          id="hobbies"
          class="tab-pane fade"
          role="tabpanel"
          aria-labelledby="hobbies-tab"
        >
          <VideoPage
            :id="videoHobbies?.child ? videoHobbies?.child?.id : videoHobbies?.id "
            :title="'La vidéo des loisirs et atouts'"
            :video="videoHobbies?.child?.video ?
              videoHobbies?.child?.video : videoHobbies?.video"
            :type="'video_hobbies'"
            :text="text"
            @onChangeVideo="reload"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>

import { computed, onMounted, ref } from 'vue';
import VideoPage from '@/components/candidate/videoPage.vue';
import { useStore } from 'vuex';
import Info from '@/components/Info.vue';

const store = useStore();
const { VITE_API_URL } = import.meta.env;

const text = "" +
    "<strong>Nos conseils :</strong> Pensez à vous tenir droit et restez stable durant toute la durée de " +
    "l'enregistrement.<br />Pour plus d'astuces, vous pouvez visualiser notre vidéo explicative en cliquant" +
    " <a href='https://www.youtube.com/channel/UCSLSWcEz4JJOg0q3dNmVvLA' target='_blank'>ici</a>."
;

const videos = computed(() => store.state?.candidateVideos?.candidate_videos?.data?.data);
const videoPresentation = computed(() => videos.value?.filter((elt) => elt.type === 'video_presentation')[0]);
const videoExperiences = computed(() => videos.value?.filter((elt) => elt.type === 'video_experiences')[0]);
const videoCourses = computed(() => videos.value?.filter((elt) => elt.type === 'video_courses')[0]);
const videoHobbies = computed(() => videos.value?.filter((elt) => elt.type === 'video_hobbies')[0]);
const completionProgress = computed(() => store.state.candidate.candidate_detail_completion);

const count = computed(() => videos?.value?.length);
const reload = () => {
  store.dispatch('candidateVideos/getAll');
};
onMounted(() => {
  store.dispatch('candidateVideos/getAll');
});

</script>
