import Api from './Api';
import Csrf from './Csrf';

export default {
  async login(form) {
    await Csrf.getCookie();
    return Api.post('login', form, { headers: { Scope: form.guard } });
  },
  async register(form) {
    return Api.post('register', form, { headers: { Scope: form.guard } });
  },
  async forgotPassword(form) {
    return Api.post('forgot-password', { email: form.email }, { headers: { Scope: form.guard } });
  },
  async resetPassword(form) {
    return Api.post('reset-password', form, { headers: { Scope: form.guard } });
  },
  logout(guard) {
    return Api.post('logout', '', { headers: { Scope: guard } });
  },
  async checkAuthenticated() {
    await Csrf.getCookie();
    return Api.get('api/me');
  },
  async verifyEmail(form) {
    return Api.get(form.url, { headers: { Scope: form.scope } });
  },
  async resendVerifyEmail(scope) {
    return Api.post('email/verification-notification', '', { headers: { Scope: scope } });
  },
};
