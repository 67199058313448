export default {
  conversations: [],
  conversationsLoading: false,
  conversationsErrors: [],
  messages: [],
  messagesLoading: false,
  messagesErrors: [],
  message: {},
  messageLoading: false,
  messageErrors: [],
  checkJitsiAccess: false,
  checkJitsiAccessLoading: false,
  checkJitsiAccessErrors: [],
  settingLoading: false,
  setting: {},
  settingErrors: [],
  saveSettingLoading: false,
  saveSetting: {},
  saveSettingErrors: [],
  banMessagesLoading: false,
  banMessages: [],
  banMessagesErrors: [],

};
