<template>
  <button
    :id="`close-import-video-collapse-${id}`"
    class="btn btn-light text-bold d-flex
        justify-content-center align-items-center mt-2"
    data-bs-toggle="collapse"
    :data-bs-target="`#import-video-collapse-${id}`"
    :aria-controls="`#import-video-collapse-${id}`"
    aria-expanded="false"
  >
    {{ updateMode ? 'Importer une nouvelle vidéo' : 'Importer une vidéo' }}
  </button>
  <div
    v-if="successImportMessage"
    class="text-green mt-2"
  >
    Vidéo importée !
  </div>
  <div
    :id="`import-video-collapse-${id}`"
    class="collapse w-100 collapse-import-video"
    data-bs-backdrop="false"
  >
    <div class="collapse-body my-4 w-100">
      <input
        :class="[{ 'invalid' : updateErrors?.video || createErrors?.video }, 'form-control', 'btn-dark text-bold']"
        type="file"
        @change="changeVideo"
      >
      <span
        v-if="loading && !(updateErrors || createErrors)"
        class="spinner-border spinner-border-sm mt-2"
        role="status"
        aria-hidden="true"
      />
      <p
        v-if="updateErrors || createErrors"
        class="text-danger"
      >
        {{ updateErrors?.video ? updateErrors.video[0] : '' }}
        {{ createErrors?.video ? createErrors.video[0] : '' }}
      </p>
      <button
        class="mx-auto btn btn-purple-gradient text-bold mt-1"
        @click="sendVideo()"
      >
        Envoyer
      </button>
    </div>
  </div>
</template>
<script setup>
import {
  ref, defineExpose,
} from 'vue';

// Component properties
const props = defineProps({
  updateMode: Boolean,
  loading: Boolean,
  successImportMessage: Boolean,
  updateErrors: {
    default: null,
    type: Object,
  },
  createErrors: {
    default: null,
    type: Object,
  },
  id: {
    default: '1',
    type: String,
  },
});

// Component emitters
const emit = defineEmits([
  'sendVideo',
]);

const video = ref(null);
const changeVideo = (event) => {
  video.value = event.target.files[0];
};

const hideCollapse = () => {
  document.getElementById(`close-import-video-collapse-${props.id}`).click();
};

const sendVideo = () => {
  const blob = new Blob([video.value]);
  emit('sendVideo', blob);
};

defineExpose({
  hideCollapse,
});

</script>
