import DegreeLevel from '@/services/DegreeLevel';

export default {
  async getAll({ commit }) {
    commit('setDegreeLevelsLoading', true);
    try {
      const DegreeLevels = await DegreeLevel.getAll();
      commit('setDegreeLevels', DegreeLevels);
    } catch (error) {
      commit('setDegreeLevelsErrors', error?.response?.data?.errors);
    }
    commit('setDegreeLevelsLoading', false);
  },
};
