import Api from './Api';

export default {
  async getAll(data) {
    return Api.get('api/users', { params: data });
  },
  async getAllNotVerified(data) {
    return Api.get('api/users', { params: { ...data, unverified: true } });
  },
  async getOne(id) {
    return Api.get(`api/users/${id}`);
  },
  async update(data) {
    return Api.post('api/profile', data);
  },
  async verify(id) {
    return Api.post(`api/users/${id}/verify`);
  },
  async refuse(data) {
    return Api.post(`api/users/${data.id}/refuse`, { reasons: data.description });
  },
  async deactivate(id) {
    return Api.post(`api/users/${id}/deactivate`);
  },
  async delete(data) {
    return Api.delete(`api/users/${data.id}`);
  },
  async showProfile() {
    return Api.get('api/profile');
  },
  async getCompletionProgress() {
    return Api.get('api/completion-status');
  },
};
