<template>
  <div>
    <h2>Détails de l'offre</h2>
    <nav
      class="mb-5"
      style="--bs-breadcrumb-divider: '•';"
      aria-label="breadcrumb"
    >
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="#">Accueil</a>
        </li>
        <li
          class="breadcrumb-item"
          aria-current="page"
        >
          <router-link :to="{ name: 'BackofficeOffersList' }">
            Les offres
          </router-link>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
        >
          Détails d'une offre
        </li>
      </ol>
    </nav>
    <div class="my-4">
      <router-link :to="{ name: 'BackofficeOffersList' }">
        <img
          src="@/assets/images/icons/arrow-left.svg"
          alt="Retour aux entreprises"
          class="me-2"
        >
        <span>Retour aux offres</span>
      </router-link>
    </div>
    <div class="d-flex align-items-center mt-5">
      <h1>{{ offer?.title }}</h1>
      <Badge
        class="ms-5"
        :status="
          { verified : offerStatus?.offer_verified,
            profile_status : offerStatus?.offer_status, }"
      />
    </div>
    <div class="d-flex justify-content-between flex-sm-column">
      <div
        v-if="offerStatus?.offer_status === 'to_validate'"
        class="d-flex justify-content-end flex-wrap"
      >
        <button
          class="btn btn-green text-bold mx-2"
          data-bs-toggle="modal"
          data-bs-target=".validate-modal"
          :disabled="offerLoading"
        >
          <span
            v-if="offerLoading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
          Valider l'offre
        </button>
        <button
          class="btn btn-orange text-bold mx-2"
          data-bs-toggle="modal"
          data-bs-target=".refuse-modal"
          :disabled="offerLoading"
        >
          <span
            v-if="offerLoading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
          Refuser l'offre
        </button>
      </div>
      <Modal
        class="validate-modal"
        :title="'Validation de l\'offre'"
        :text="'Etes-vous sûr de vouloir valider cette offre ?'"
        :action-button="'Valider'"
        @callback="verifyOffer"
      />
      <Modal
        class="refuse-modal"
        :title="'Refus de l\'offre'"
        :text="'Veuillez décrire le motif du refus'"
        :have-input="true"
        :action-button="'Refuser'"
        @callback="refuseOffer($event)"
      />
    </div>
    <h4 class="text-primary text-bold mt-5">
      Informations de l'offre
    </h4>
    <hr>
    <div
      v-if="offerLoading"
      class="d-flex justify-content-center mt-5"
    >
      <div
        class="spinner-border"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div
      v-else
      class="row g-4"
    >
      <div class="col col-12 col-sm-6 col-md-3 ">
        <label class="form-label text-purple_grey text-bold">Intitulé du poste</label>
        <p>{{ offer?.title }}</p>
      </div>
      <div class="col col-12 col-sm-6 col-md-3">
        <label class="form-label text-purple_grey text-bold">Télétravail</label>
        <p v-if="offer?.telework === 'full'">
          {{ '100% télétravail' }}
        </p>
        <p v-else-if="offer?.telework === 'partial'">
          {{ 'Télétravail partiel' }}
        </p>
        <p v-else>
          {{ 'Pas de télétravail' }}
        </p>
      </div>
      <div
        v-if="offer?.city"
        class="col col-12 col-sm-6 col-md-3"
      >
        <label class="form-label text-purple_grey text-bold">Localisation</label>
        <p>{{ offer?.city?.name + ' ' + offer?.city?.zip_code }}</p>
      </div>
      <div class="col col-12 col-sm-6 col-md-3">
        <label class="form-label text-purple_grey text-bold">Rayon souhaité</label>
        <p>
          {{ offer?.radius }}
        </p>
      </div>
      <div class="col col-12 col-sm-6 col-md-3">
        <label class="form-label text-purple_grey text-bold">Salaire minimum</label>
        <p>
          {{ offer?.salary_minimum }} €
        </p>
      </div>
      <div class="col col-12 col-sm-6 col-md-3">
        <label class="form-label text-purple_grey text-bold">Salaire maximum</label>
        <p>
          {{ offer?.salary_maximum }} €
        </p>
      </div>
      <div class="col col-12 col-sm-6 col-md-3">
        <label class="form-label text-purple_grey text-bold">Avantages</label>
        <p>{{ offer?.advantages }}</p>
      </div>
      <div class="col col-12 col-sm-6 col-md-3">
        <label class="form-label text-purple_grey text-bold">Types de contrat</label>
        <p
          v-for="type in offer?.contract_types"
          :key="type.id"
        >
          <span> {{ type.name }}</span>
        </p>
      </div>
      <div class="col col-12 col-sm-6 col-md-3">
        <label class="form-label text-purple_grey text-bold">Description de l'offre</label>
        <p>{{ offer?.description }}</p>
      </div>
      <div class="col col-12 col-sm-6 col-md-3">
        <label class="form-label text-purple_grey text-bold">
          Nombre d'années d'expérience
        </label>
        <p>{{ offer?.years_of_experience }}</p>
      </div>
      <div class="col col-12 col-sm-6 col-md-3">
        <label class="form-label text-purple_grey text-bold">Niveau d'étude</label>
        <p
          v-for="level in offer?.degree_levels"
          :key="level.id"
        >
          <span> {{ level.name }}</span>
        </p>
      </div>
      <div class="col col-12 col-sm-6 col-md-3">
        <label class="form-label text-purple_grey text-bold">Metier</label>
        <p
          v-for="job in offer?.jobs"
          :key="job.id"
        >
          <span> {{ job.name }}</span>
        </p>
      </div>
      <div class="col col-12 col-sm-6 col-md-3">
        <label class="form-label text-purple_grey text-bold">Permis</label>
        <div v-if="offer?.licenses[0]">
          <p
            v-for="license in offer?.licenses"
            :key="license.id"
          >
            <span> {{ license.name }}</span>
          </p>
        </div>
        <p v-else>
          Pas de permis obligatoire
        </p>
      </div>
      <div class="col col-12 col-sm-6 col-md-3 ">
        <label class="form-label text-purple_grey text-bold">Disponibilité</label>
        <p v-if="offer?.available_now">
          Immédiate
        </p>
        <p v-else>
          {{ normalizer.formatDate(offer?.availability) }}
        </p>
      </div>
      <div class="col col-12 col-sm-6 col-md-3 ">
        <label class="form-label text-purple_grey text-bold">Langues parlées</label>
        <p
          v-for="language in offer?.languages"
          :key="language.id"
        >
          <span> {{ language.name }}</span>
        </p>
      </div>
      <div class="col col-12 col-sm-6 col-md-3">
        <label class="form-label text-purple_grey text-bold">Nombre de postes ouverts</label>
        <p>{{ offer?.number_of_seats }}</p>
      </div>
      <div class="col col-12 col-sm-6 col-md-3">
        <label class="form-label text-purple_grey text-bold">
          Vidéo de présentation de l'offre
        </label>
        <div
          class="d-flex"
        >
          <div class="video-company-profile">
            <video
              v-if="offer?.video"
              controls
            >
              <source
                :src="`${VITE_API_URL}/${offer?.video}`"
                type="video/mp4"
              >
            </video>
            <p v-else>
              Pas de video
            </p>
          </div>
        </div>
      </div>
      <div class="col col-12 col-sm-6 col-md-3 ">
        <label class="form-label text-purple_grey text-bold">
          Vidéos de présentation obligatoires
        </label>
        <p>{{ offer?.p_videos_required ? 'Oui' : 'Non' }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  computed, onMounted, ref,
} from 'vue';
import Modal from '@/components/modal/Modal.vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import Badge from '@/components/badge/Badge.vue';
import normalizer from '@/utils/normalizer';

const store = useStore();
const route = useRoute();
const router = useRouter();

const offer = computed(() => (store.state.offer?.offer_detail?.data?.data?.child
  ? store.state.offer?.offer_detail?.data?.data?.child
  : store.state.offer?.offer_detail?.data?.data));
const offerStatus = computed(() => store.state.offer?.offer_detail?.data?.data?.status);
const offerLoading = computed(() => store.state.offer?.offer_detail_loading);

const { VITE_API_URL } = import.meta.env;

const verifyOffer = () => {
  store.dispatch('offer/verify', {
    id: route.params.id,
    onSuccess: () => {
      router.push({ name: 'BackofficeOffersList' });
    },
  });
};

const refuseOffer = (value) => {
  store.dispatch('offer/refuse', {
    id: route.params.id,
    description: value,
    onSuccess: () => {
      router.push({ name: 'BackofficeOffersList' });
    },
  });
};

onMounted(() => {
  store.dispatch('offer/getOne', route.params.id);
});

</script>
