export default {
  setCities(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.cities = value;
  },
  setCitiesErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.cities_errors = value;
  },
  setCitiesLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.cities_loading = value;
  },
};
