<template>
  <div
    ref="refModal"
    :class="['modal fade', { show }]"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content  bg-dark_grey">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ title }}
          </h5>
          <button
            type="button"
            class="btn-close bg-light"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div class="modal-body my-3 text-center">
          {{ text }}
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-info text-bold mx-auto"
            data-bs-dismiss="modal"
            @click="emit('cancelCallback')"
          >
            {{ cancelButton ? cancelButton: 'Annuler' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  computed, onMounted, ref, watch,
} from 'vue';
import { Modal } from 'bootstrap';

const props = defineProps({
  title: String,
  text: String,
  cancelButton: String,
  show: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['callback', 'cancelCallback', 'close']);

const modal = ref();
const refModal = ref(null);

onMounted(() => {
  modal.value = new Modal(refModal.value);
  refModal.value.addEventListener('hidden.bs.modal', () => {
    emit('close');
  });
});

const reactiveShow = computed(() => props.show);
watch(reactiveShow, (value) => {
  if (value) {
    modal.value.show();
  } else {
    modal.value.hide();
  }
});

</script>
