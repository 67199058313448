export default {
  setJobs(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.jobs = value;
  },
  setJobsErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.jobs_errors = value;
  },
  setJobsLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.jobs_loading = value;
  },
};
