<template>
  <div class="w-100 pb-5">
    <div class="row g-4 mt-3">
      <div class="col col-12 col-md-6 text-center">
        <label class="form-label text-purple_grey text-bold">Photo de profil <Required /></label>
        <div
          v-if="!formData.picture || picture"
          class="input-group has-validation"
        >
          <input
            :class="[{ 'invalid' : profilErrors?.picture }, 'form-control', 'w-100']"
            type="file"
            @change="changePicture"
          >
          <span
            v-if="profilErrors?.picture"
            class="text-danger"
          >{{ profilErrors?.picture[0] }}</span>
        </div>
        <div
          v-else
          class="d-flex"
        >
          <div class="m-auto d-flex">
            <div class="photo-profile-big me-0">
              <img
                :src="`${VITE_API_URL}/${formData.picture}`"
                alt="Photo de profile"
              >
            </div>
            <button
              type="button"
              class="btn-close btn-close-white ms-2"
              @click="removePicture"
            />
          </div>
        </div>
      </div>
      <div class="col col-12 col-md-6">
        <div class="row g-4">
          <div class="col col-12 col-md-8">
            <label class="form-label text-purple_grey text-bold">Prénom <Required /></label>
            <input
              v-model="formData.first_name"
              :class="[{ 'invalid' : profilErrors?.first_name }, 'form-control', 'w-100']"
              placeholder="Votre prénom"
              required
            >
            <span
              v-if="profilErrors?.first_name"
              class="text-danger"
            >{{ profilErrors?.first_name[0] }}</span>
          </div>
          <div class="col col-12 col-md-8">
            <label class="form-label text-purple_grey text-bold">Nom <Required /></label>
            <div class="input-group has-validation">
              <input
                v-model="formData.second_name"
                :class="[{ 'invalid' : profilErrors?.second_name }, 'form-control', 'w-100']"
                placeholder="Votre nom"
                required
              >
              <span
                v-if="profilErrors?.second_name"
                class="text-danger"
              >{{ profilErrors?.second_name[0] }}</span>
            </div>
          </div>
          <div class="col col-12 col-md-8">
            <label class="form-label text-purple_grey text-bold">Date de naissance <Required /></label>
            <div class="input-group has-validation">
              <input
                v-model="formData.birth_date"
                type="date"
                :class="[{ 'invalid' : profilErrors?.birth_date }, 'form-control', 'w-100']"
                placeholder="Date de naissance"
                required
              >
              <span
                v-if="profilErrors?.birth_date"
                class="text-danger"
              >{{ profilErrors?.birth_date[0] }}</span>
            </div>
          </div>
          <div class="col col-12 col-md-8">
            <label class="form-label text-purple_grey text-bold">Téléphone <Required /></label>
            <div class="input-group has-validation">
              <input
                v-model="formData.phone_number"
                :class="[{ 'invalid' : profilErrors?.phone_number }, 'form-control', 'w-100']"
                placeholder="Téléphone"
                @blur="focusPhoneNumberOut($event)"
                @focus="focusPhoneNumberIn($event)"
              >
              <span
                v-if="profilErrors?.phone_number"
                class="text-danger"
              >{{ profilErrors?.phone_number[0] }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row g-4 mt-md-5">
      <div class="col col-12 col-md-6 col-sm-12 col-lg-3">
        <label class="form-label text-purple_grey text-bold">Adresse <Required /></label>
        <div class="input-group has-validation">
          <input
            v-model="formData.address1"
            :class="[{ 'invalid' : profilErrors?.address1 }, 'form-control', 'w-100']"
            placeholder="Numéro et rue"
            required
          >
          <span
            v-if="profilErrors?.address1"
            class="text-danger"
          >{{ profilErrors?.address1[0] }}</span>
        </div>
      </div>
      <div class="col col-12 col-md-6 col-sm-12 col-lg-3">
        <label class="form-label text-purple_grey text-bold">Complément d'adresse</label>
        <div class="input-group has-validation">
          <input
            v-model="formData.address2"

            :class="[{ 'invalid' : profilErrors?.address2 }, 'form-control', 'w-100']"
            placeholder="Complément d'adresse"
          >
          <span
            v-if="profilErrors?.address2"
            class="text-danger"
          >{{ profilErrors?.address2[0] }}</span>
        </div>
      </div>
      <div class="col col-12 col-md-6 col-sm-12 col-lg-3">
        <SearchCities
          :errors="profilErrors?.city_id"
          :existing-city="formData.city"
          @sendCity="sendCity($event)"
        />
      </div>
      <div class="col col-12 col-md-6 col-sm-12 col-lg-3">
        <label class="form-label text-purple_grey text-bold">Ville</label>
        <div class="input-group has-validation">
          <input
            v-model="formData.city_name"
            disabled
            :class="[{ 'invalid' : profilErrors?.city_name }, 'form-control', 'w-100']"
            placeholder="Veuillez choisir une ville"
          >
          <span
            v-if="profilErrors?.city_name"
            class="text-danger"
          >{{ profilErrors?.city_name[0] }}</span>
        </div>
      </div>
      <div class="col col-12 col-md-6 col-sm-12 col-lg-3">
        <label class="form-label text-purple_grey text-bold">Permis</label>
        <div class="input-group has-validation">
          <v-select
            v-model="formData.licenses"
            :class="[{ 'invalid' : profilErrors?.licenses }, 'w-100']"
            label="name"
            multiple
            value="option"
            :options="licenses"
          />
          <span
            v-if="profilErrors?.licenses"
            class="text-danger"
          >{{ profilErrors?.licenses[0] }}</span>
        </div>
      </div>
      <div class="col col-12 col-md-6 col-sm-12 col-lg-3">
        <label class="form-label text-purple_grey text-bold">Langues parlées <Required /></label>
        <div class="input-group has-validation">
          <v-select
            v-model="formData.languages"
            :class="[{ 'invalid' : profilErrors?.languages }, 'w-100']"
            label="name"
            multiple
            value="option"
            :options="languages"
          />
          <span
            v-if="profilErrors?.languages"
            class="text-danger"
          >{{ profilErrors?.languages[0] }}</span>
        </div>
      </div>
      <div class="col col-12 col-md-6 col-sm-12 col-lg-3">
        <label class="form-label text-purple_grey text-bold">Vos pages
          <span class="fst-italic">(LinkedIn...)</span>
        </label>
        <div class="input-group has-validation">
          <span
            v-for="(item, index) in formData.external_links"
            :key="index"
            class="w-100 mb-1"
          >
            <span class="input-group d-flex flex-nowrap align-items-center">
              <input
                :key="index"
                v-model="formData.external_links[index]"
                type="text"
                :class="[{ 'invalid' : profilErrors?.external_links?.[index] },
                         'form-control', 'w-100']"
                placeholder="Lien vers votre page"
              >
              <button
                type="button"
                class="remove-external-link position-absolute btn-close btn-close-white end-0 me-2"
                @click="removeExternalLink(index)"
              />
            </span>
            <span
              v-if="profilErrors?.external_links?.[index]"
              class="text-danger"
            >{{ profilErrors?.external_links?.[index][0] }}</span>
          </span>
        </div>
        <button
          class="btn btn-outline-light"
          @click="addExternalLink"
        >
          Ajouter un lien
        </button>
      </div>
      <div class="col col-12 col-md-6 col-sm-12 col-lg-3 mt-5 p-2">
        <div class="form-check form-switch">
          <input
            v-model="formData.rqth"
            class="form-check-input custom-switch"
            type="checkbox"
          >
          <label class="form-check-label mx-2">
            <span class="text-purple_grey text-bold">
              Reconnaissance RQTH
            </span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import {
  computed, onMounted, ref, watch,
} from 'vue';
import SearchCities from '@/components/searchCities/SearchCities.vue';
import Required from '@/components/Required.vue';
import normalizer from '@/utils/normalizer';

const store = useStore();
const profilErrors = computed(() => store.state?.candidate?.candidate_detail_errors);
const licenses = computed(() => store.state?.license?.licenses?.data?.data);
const languages = computed(() => store.state?.language?.languages?.data?.data);
const formData = ref({
  first_name: null,
  second_name: null,
  birth_date: null,
  languages: [],
  licenses: [],
  address1: null,
  address2: null,
  zip_code: null,
  city: null,
  city_id: null,
  city_name: null,
  phone_number: null,
  external_links: [''],
  rqth: false,
  picture: null,
});

const props = defineProps({
  existingCandidate: Object,
});
const profileData = computed(() => props.existingCandidate);

const picture = ref(null);

const emit = defineEmits(['sendData', 'errorExist']);

watch(profilErrors, (value) => {
  if (value && Object.keys(value).length !== 0) {
    if (profilErrors.value.first_name || profilErrors.value.second_name
        || profilErrors.value.birth_date || profilErrors.value.languages
        || profilErrors.value.licences || profilErrors.value.address1
        || profilErrors.value.zip_code || profilErrors.value.city_id
        || profilErrors.value.phone_number || profilErrors.value.external_links
        || profilErrors.value.picture) { emit('errorExist'); }
  }
});

const selectInferiorLang = () => {
  const lang = [...formData.value.languages];
  const allLang = [];
  for (let i = 0; i < lang.length; i++) {
    const sameLang = languages?.value?.filter((elt) => elt.code.split('-')[0] === lang[i].code.split('-')[0]);
    sameLang?.filter((elt) => (elt.code.split('-')[1] <= lang[i].code.split('-')[1] && allLang.indexOf(elt) === -1
      ? allLang.push(elt) : ''));
  }
  return allLang;
};

const onSend = () => {
  if (formData.value) {
    const tempFormData = { ...formData.value };
    tempFormData.licenses = [];
    tempFormData.languages = [];
    const allLang = selectInferiorLang();
    if (formData.value.licenses?.[0]) {
      for (const elt of formData.value.licenses) {
        tempFormData.licenses = [...tempFormData.licenses, elt.id];
      }
    }
    if (formData.value.languages?.[0]) {
      for (const elt of allLang) {
        tempFormData.languages = [...tempFormData.languages, elt.id];
      }
    }
    emit('sendData', tempFormData);
  }
};

watch(() => ({ ...formData.value }), () => {
  onSend();
});

const { VITE_API_URL } = import.meta.env;

const changePicture = (event) => {
  picture.value = event.target.files[0];
  formData.value.picture = picture.value;
};

const removePicture = () => {
  formData.value.picture = null;
  picture.value = null;
};

const addExternalLink = () => {
  if (!formData.value.external_links) {
    formData.value.external_links = [];
  }
  formData.value.external_links.push('');
};
const removeExternalLink = (index) => {
  formData.value.external_links.splice(index, 1);
};

const sendCity = (data) => {
  if (data) {
    formData.value.city_id = data?.id;
    formData.value.zip_code = data?.zip_code;
    formData.value.city_name = data?.name;
    formData.value.city = data;
  }
};

const rawPhoneNumber = ref(formData.value.phone_number);
const focusPhoneNumberOut = (e) => {
  rawPhoneNumber.value = e.target.value;
  e.target.value = normalizer.formatPhoneNumber(e.target.value);
};
const focusPhoneNumberIn = (e) => {
  e.target.value = rawPhoneNumber.value;
};

watch(profileData, (value) => {
  if (value) {
    formData.value = { ...profileData.value.data };
    formData.value.birth_date = normalizer.formatDate(profileData.value.data.birth_date)
      .split('/').reverse().join('-');
    formData.value.phone_number = normalizer.formatPhoneNumber(profileData.value.data.phone_number);
    rawPhoneNumber.value = profileData.value.data.phone_number;
    picture.value = null;
    onSend();
  }
});

onMounted(() => {
  store.dispatch('license/getAll');
  store.dispatch('language/getAll');
});

</script>
