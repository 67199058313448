<template>
  <div class="w-100 pb-5">
    <div
      v-if="experiences.length <= 0"
      class="d-flex flex-column align-items-center justify-content-center"
    >
      <div class="fst-italic">
        Aucune expérience
      </div>
      <button
        class="btn btn-outline-light text-bold mt-3"
        @click="addExperience"
      >
        Ajouter une expérience
      </button>
    </div>
    <Experience
      v-for="(item, index) in experiences"
      :key="index"
      :experience-data="item"
      :index="index"
      :existing-candidate="!!existingCandidate"
      :profil-errors="profilErrors?.[index]"
      @send-data="sendData(index, $event)"
      @add-experience="addExperience"
      @on-delete="deleteExperienceFromList(index)"
    />
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import {
  computed, onMounted, ref, watch,
} from 'vue';
import Experience from '@/components/candidate/Experience.vue';

const store = useStore();

const props = defineProps({
  existingCandidate: [Object, Array],
});
const profileData = computed(() => props.existingCandidate);

const profilErrors = computed(() => store.state?.candidate?.candidate_detail_errors?.experiences);

const experiences = ref([]);

const formData = ref({
  experiences,
});
const sendData = (index, data) => {
  formData.value.experiences[index] = data;
};

const addExperience = () => {
  experiences.value = [...experiences.value, {
    start_date: null,
    end_date: null,
    actual: false,
    contract_types: '',
    job_title: null,
    company_name: null,
    recommendation: {
      can_contact_manager: false,
      manager_email: null,
      manager_name: null,
      manager_position: null,
      manager_phone: null,
    },
    document: null,
  }];
};
const deleteExperienceFromList = (index) => {
  experiences?.value?.splice(index, 1);
};

const emit = defineEmits(['sendData', 'errorExist']);

const onSend = () => {
  emit('sendData', formData.value);
};

watch(profilErrors, (value) => {
  if (value && Object.keys(value).length !== 0) {
    emit('errorExist');
  }
});

watch(formData.value, (value) => {
  if (value) {
    onSend();
  }
});

watch(profileData, (value) => {
  if (value[0]) {
    experiences.value = [];
    for (const experience of profileData.value) {
      experiences.value = [...experiences.value, {
        start_date: experience.start_date,
        end_date: experience.end_date,
        actual: experience.actual,
        contract_type_id: experience.contract_type.id,
        recommendation: experience.recommendation
          ? experience.recommendation : { can_contact_manager: false },
        document: experience.document,
        job_title: experience.job_title,
        company_name: experience.company_name,
      }];
    }
  }
});

onMounted(() => {
  emit('sendData', formData.value);
});

</script>
