<template>
  <div
    class="modal fade toggleModal"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content  bg-dark_grey">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ title }}
          </h5>
          <button
            type="button"
            class="btn-close bg-light"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div class="modal-body my-3">
          {{ text }}
          <div v-if="haveInput">
            <label class="col-form-label">{{ inputLabel }}</label>
            <textarea
              v-model="inputContent"
              type="text"
              class="form-control"
              placeholder="Motif du refus"
            />
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-danger text-bold"
            data-bs-dismiss="modal"
            @click="emit('cancelCallback')"
          >
            {{ cancelButton ? cancelButton: 'Annuler' }}
          </button>
          <button
            type="button"
            class="btn btn-purple-gradient text-bold"
            data-bs-dismiss="modal"
            @click="emit('callback', inputContent)"
          >
            {{ actionButton }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
  title: String,
  text: String,
  haveInput: Boolean,
  inputLabel: String,
  actionButton: String,
  cancelButton: String,
});

const inputContent = ref('');

const emit = defineEmits(['callback', 'cancelCallback']);

</script>
