export default {
  candidates: [],
  candidates_errors: [],
  candidates_loading: false,
  candidate_detail: {},
  candidate_detail_errors: [],
  candidate_detail_loading: false,
  candidate_detail_completion: '',
  candidate_detail_completion_loading: false,
  candidate_detail_completion_errors: [],
};
