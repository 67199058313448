<template>
  <div>
    <NavbarTop />
    <Navbar />
    <img
      class="position-absolute top-0 start-0 logo-viewzme-auth m-3"
      src="@/assets/images/logo-viewzme-opti.png"
      alt=""
    >
    <div class="position-absolute top-0 authenticatedSection w-100">
      <router-view />
    </div>
  </div>
</template>

<script setup>
import Navbar from '@/components/navbar/Navbar.vue';
import NavbarTop from '@/components/navbar/NavbarTop.vue';
import { onMounted, ref } from 'vue';

import { useStore } from 'vuex';

const notificationList = ref([]);
const store = useStore();

onMounted(() => {
  if (localStorage.getItem('scope') === 'user') {
    store.dispatch('candidate/getCompletionProgress');
  }
});

</script>
