<template>
  <div class="position-absolute top-0 visio-page">
    <div class="visio-back-to-menu">
      <router-link
        :to="scope === 'recruiter' ? { name : 'CompanyChat' } : { name : 'CandidateChat' } "
      >
        <img
          src="@/assets/images/icons/arrow-left.svg"
          alt="Retour à l'accueil"
          class="me-2"
        >
        <span>Retour à la messagerie</span>
      </router-link>
    </div>
    <div
      id="meet"
    />
  </div>
</template>
<script setup>
import {
  computed, onBeforeUnmount, onMounted, ref, watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

const store = useStore();
const route = useRoute();
const router = useRouter();
const parent = ref();
const iframe = ref();
const screenWidth = computed(() => `${window.innerWidth - (window.innerWidth * 0.3)}px`);
const screenHeight = computed(() => `${window.innerHeight - (window.innerHeight * 0.35)}px`);
const domain = 'meet.jit.si';
const scope = computed(() => localStorage.getItem('scope'));

const options = ref({
  roomName: route.query.roomlink,
  width: screenWidth.value,
  height: screenHeight.value,
  parentNode: parent.value,
  configOverwrite: {
    enableCalendarIntegration: false,
    toolbarButtons: [
      'camera',
      'chat',
      'closedcaptions',
      'desktop',
      'filmstrip',
      'fullscreen',
      'security',
      'microphone',
      'profile',
      'select-background',
      'settings',
      'tileview',
      'toggle-camera',
      'videoquality',
      // '__end',
    ],
  },
  interfaceConfigOverwrite: {
    SETTINGS_SECTIONS: ['devices', 'language', 'moderator', 'profile', 'sounds'],
    SHOW_PROMOTIONAL_CLOSE_PAGE: false,
  },

});
const api = new JitsiMeetExternalAPI(domain, options.value);

onMounted(() => {
  options.value.roomName = `${route.query.roomlink}`;
  store.dispatch('chat/checkJitsiAccess', {
    id: route.query.cid,
    onFail: () => {
      router.push('/not-found');
    },
  });
  parent.value = document.getElementById('meet');
  iframe.value = document.getElementsByTagName('iframe');
});

onBeforeUnmount(() => {
  const meet = document.querySelector('iframe');
  meet.remove();
});

</script>
