import Language from '@/services/Language';

export default {
  async getAll({ commit }) {
    commit('setLanguagesLoading', true);
    try {
      const language = await Language.getAll();
      commit('setLanguages', language);
    } catch (error) {
      commit('setLanguagesErrors', error?.response?.data?.errors);
    }
    commit('setLanguagesLoading', false);
  },
};
