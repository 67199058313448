export default {
  setCompanySizes(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.company_sizes = value;
  },
  setCompanySizesErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.company_sizes_errors = value;
  },
  setCompanySizesLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.company_sizes_loading = value;
  },
};
