import Api from './Api';

export default {
  async getAll(filter) {
    return Api.get('api/tutorials', { params: filter });
  },
  async getOne(id) {
    return Api.get(`api/tutorials/${id}`);
  },
  async create(data) {
    return Api.post('api/tutorials/create', data);
  },
  async update(data) {
    return Api.post(`api/tutorials/${data.id}/update`, data.data);
  },
  async delete(id) {
    return Api.delete(`api/tutorials/${id}/delete`);
  },

};
