<template>
  <ul
    class="nav d-flex bg-dark_grey rounded logged-navbar-horizontal justify-content-between
  align-content-between position-fixed flex-nowrap"
  >
    <li
      v-for="link in content"
      :key="link.title"
      :class="[{ 'active' : route.fullPath.split('/').includes(link.navIcon) },'nav-item']"
    >
      <div
        :class="[{ 'line-primary-horizontal' : route.fullPath.split('/').includes('candidate') },
                 { 'line-primary-horizontal-company' :
                   route.fullPath.split('/').includes('company') }]"
      />
      <router-link :to="link.to">
        <div class="nav-link">
          <img
            class="text-light"
            :src="link.icon"
            :alt="link.title"
          >
          <span
            v-if="link.unread"
            class="unread_chip rounded-circle"
          />
        </div>
      </router-link>
    </li>
    <li class="nav-item">
      <div
        :class="[{ 'line-primary-horizontal' : route.fullPath.split('/').includes('candidate') },
                 { 'line-primary-horizontal-company' :
                   route.fullPath.split('/').includes('company') }]"
      />
      <div
        class="nav-link"
        @click="logout"
      >
        <img
          class="text-light"
          src="@/assets/images/icons/sign-out.svg"
          alt="Se déconnecter"
        >
      </div>
    </li>
  </ul>
</template>
<script setup>
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

const store = useStore();
const router = useRouter();
const route = useRoute();
const props = defineProps({
  content: Array,
});

const logout = async () => {
  await store.dispatch('auth/logout', {
    onSuccess: () => {
      router.push({ name: 'Home' });
    },
  });
};

</script>
