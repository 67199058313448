export default {
  setConversations(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.conversations = value;
  },
  setConversationsLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.conversationsLoading = value;
  },
  setConversationsErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.conversationsErrors = value;
  },
  setMessages(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.messages = value;
  },
  setMessagesLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.messagesLoading = value;
  },
  setMessagesErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.messagesErrors = value;
  },
  setMessage(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.message = value;
  },
  setMessageLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.messageLoading = value;
  },
  setMessageErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.messageErrors = value;
  },
  setCheckJitsiAccess(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.checkJitsiAccess = value;
  },
  setCheckJitsiAccessLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.checkJitsiAccessLoading = value;
  },
  setCheckJitsiAccessErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.checkJitsiAccessErrors = value;
  },
  setSettingLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.settingLoading = value;
  },
  setSetting(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.setting = value;
  },
  setSettingErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.settingErrors = value;
  },
  setSaveSettingLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.saveSettingLoading = value;
  },
  setSaveSetting(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.saveSetting = value;
  },
  setSaveSettingErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.saveSettingErrors = value;
  },
  setBanMessagesLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.saveSettingLoading = value;
  },
  setBanMessages(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.banMessages = value;
  },
  setBanMessagesErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.BanMessagesErrors = value;
  },
};
