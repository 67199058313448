import axios from 'axios';
import router from '@/router';

const Api = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});
Api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    }
    const { response } = error;
    // const path = response.config.url.replace(response.config.baseURL, '');
    // Unauthenticated 401
    if (response.status === 403) {
      if (response.data.message === 'incomplete profile') {
        router.push({ name: 'not-authorize', query: { messageType: 'profile' } }).then((r) => r);
      } else if (response.data.message === 'incomplete videos') {
        router.push({ name: 'not-authorize', query: { messageType: 'videos' } }).then((r) => r);
      } else if (response.data.message === 'Your email address is not verified.') {
        router.push({ name: 'not-authorize', query: { messageType: 'emailNotVerified' } })
          .then((r) => r);
      }
    }
    return Promise.reject(error);
  },
);

export default Api;
