<template>
  <div>
    <Visio />
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import Visio from '@/components/jitsi/Visio.vue';

const store = useStore();
const { VITE_API_URL } = import.meta.env;

</script>
