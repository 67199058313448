export default {
  setContactMessage(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.contact_message = value;
  },
  setContactMessageErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.contact_message_errors = value;
  },
  setContactMessageLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.contact_message_loading = value;
  },
};
