import ContractType from '@/services/ContractType';

export default {
  async getAll({ commit }) {
    commit('setContractTypesLoading', true);
    try {
      const contractTypes = await ContractType.getAll();
      commit('setContractTypes', contractTypes);
    } catch (error) {
      commit('setContractTypesErrors', error?.response?.data?.errors);
    }
    commit('setContractTypesLoading', false);
  },
};
