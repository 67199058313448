export default {
  setContractTypes(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.contract_types = value;
  },
  setContractTypesErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.contract_types_errors = value;
  },
  setContractTypesLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.contract_types_loading = value;
  },
};
