<template>
  <router-view />
</template>

<script setup>
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

const store = useStore();
const route = useRoute();
const router = useRouter();

onMounted(async () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  if (urlParams.has('verify_url') && window.location.pathname.startsWith('/email/verify/')) {
    let verifyUrl = null;
    try {
      verifyUrl = new URL(urlParams.get('verify_url'));
    } catch (error) {
      return router.push({ name: 'not-authorize' });
    }

    if (`${verifyUrl.protocol}//${verifyUrl.hostname}` !== import.meta.env.VITE_API_URL) {
      return router.push({ name: 'not-authorize' });
    }

    const scope = window.location.pathname.split('/').at(-1);

    await store.dispatch('auth/checkAuthenticated');
    const isAuth = JSON.parse(localStorage.getItem('authenticated'));

    const profilScope = { user: 'candidate', recruiter: 'company', admin: 'admin' };

    if (!isAuth) {
      router.push({ name: 'Login', query: { ...route.query }, params: { profil: profilScope[scope] } });
    } else {
      store.dispatch(
        'auth/verifyEmail',
        { url: verifyUrl, scope },
      );
    }
  }
  await store.dispatch('auth/checkAuthenticated');
});
</script>
