import Api from './Api';

export default {
  async getAll() {
    return Api.get('api/videos');
  },
  async create(data) {
    return Api.post('api/videos/create', data);
  },
  async update(data) {
    return Api.post(`api/videos/${data.id}/update`, data.data);
  },
};
