export default {
  setMatchings(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.matchings = value;
  },
  setMatchingsErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.matchings_errors = value;
  },
  setMatchingsLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.matchings_loading = value;
  },
  setMatchingDetail(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.matching_detail = value;
  },
  setMatchingDetailErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.matching_detail_errors = value;
  },
  setMatchingDetailLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.matching_detail_loading = value;
  },
  setCreateChatLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.create_chat_loading = value;
  },
  setChat(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.chat = value;
  },
  setCreateChatErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.create_chat_error = value;
  },
  setMyChatLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.my_chat_loading = value;
  },
  setMyChat(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.my_chat = value;
  },
  setMyChatErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.my_chat_error = value;
  },
};
