export default {
  setStats(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.stats = value;
  },
  setStatsErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.stats_errors = value;
  },
  setStatsLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.stats_loading = value;
  },
};
