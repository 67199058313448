import Api from './Api';

export default {
  async getAll(filter) {
    return Api.get('api/matching', { params: filter });
  },
  async getOne(id) {
    return Api.get(`api/matching/${id}`);
  },
  async update(data) {
    return Api.patch(`api/matching/${data.id}/update-step`, data.data);
  },
  async addVideo(data) {
    return Api.post(`api/matching/${data.id}/add-video`, data.video);
  },
  async updateVideo(data) {
    return Api.post(`api/matching/${data.id}/update-video`, data.video);
  },
  async verifyVideo(id) {
    return Api.post(`api/matching-videos/${id}/accept`);
  },
  async refuseVideo(data) {
    return Api.post(`api/matching-videos/${data.id}/refuse`, { reasons: data.description });
  },
};
