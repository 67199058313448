import Application from '@/services/Application';

export default {
  async getAll({ commit }, filter) {
    commit('setApplicationsLoading', true);
    try {
      const applications = await Application.getAll(filter);
      commit('setApplications', applications);
      commit('setApplicationsErrors', []);
    } catch (error) {
      commit('setApplicationsErrors', error?.response?.data?.errors);
    }
    commit('setApplicationsLoading', false);
  },
  async getOne({ commit }, id) {
    commit('setApplicationDetailLoading', true);
    try {
      const appplication = await Application.getOne(id);
      commit('setApplicationDetail', appplication);
      commit('setApplicationDetailErrors', []);
    } catch (error) {
      commit('setApplicationDetailErrors', error?.response?.data?.errors);
    }
    commit('setApplicationDetailLoading', false);
  },
  async update({ commit }, data) {
    commit('setApplicationDetailLoading', true);
    try {
      const appplication = await Application.update(data);
      commit('setApplicationDetail', appplication);
      data.onSuccess();
    } catch (error) {
      commit('setApplicationUpdateStepErrors', error?.response?.data);
    }
    commit('setApplicationDetailLoading', false);
  },
  async addVideo({ commit }, data) {
    commit('setApplicationDetailLoading', true);
    try {
      await Application.addVideo(data);
      // commit('setApplicationDetail', appplication);
      data.onSuccess();
      commit('setApplicationDetailErrors', []);
    } catch (error) {
      commit('setApplicationDetailErrors', error?.response?.data?.errors);
    }
    commit('setApplicationDetailLoading', false);
  },
  async updateVideo({ commit }, data) {
    commit('setApplicationDetailLoading', true);
    try {
      await Application.updateVideo(data);
      data.onSuccess();
      commit('setApplicationDetailErrors', []);
    } catch (error) {
      commit('setApplicationDetailErrors', error?.response?.data?.errors);
    }
    commit('setApplicationDetailLoading', false);
  },
  async verifyVideo({ commit }, data) {
    commit('setApplicationDetailLoading', true);
    try {
      await Application.verifyVideo(data.id);
      data.onSuccess();
    } catch (error) {
      commit('setApplicationDetailErrors', error?.response?.data?.errors);
    }
    commit('setApplicationDetailLoading', false);
  },
  async refuseVideo({ commit }, data) {
    commit('setApplicationDetailLoading', true);
    try {
      await Application.refuseVideo(data);
      data.onSuccess();
    } catch (error) {
      commit('setApplicationDetailErrors', error?.response?.data?.errors);
    }
    commit('setApplicationDetailLoading', false);
  },
};
