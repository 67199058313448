<template>
  <ul
    class="nav flex-column bg-dark_grey rounded logged-navbar
  justify-content-center align-content-center py-3  position-fixed"
  >
    <router-link
      v-for="link in content"
      :key="link.title"
      :class="[{ 'active' : route.fullPath.split('/').includes(link.navIcon) }, 'nav-item']"

      :to="link.to"
    >
      <li>
        <div class="nav-link">
          <div class="line-primary-vertical" />
          <img
            class="text-light"
            :src="link.icon"
            :alt="link.title"
          >
          <span
            v-if="link.unread"
            class="unread_chip rounded-circle"
          />
          <p class="text-bold text-white">
            {{ link.title }}
          </p>
        </div>
      </li>
    </router-link>

    <li class="nav-item">
      <div
        class="nav-link"
        @click="logout"
      >
        <div class="line-primary-vertical" />
        <img
          class="text-light"
          src="@/assets/images/icons/sign-out.svg"
          alt="Se déconnecter"
        >
        <p class="text-bold text-white">
          Se déconnecter
        </p>
      </div>
    </li>
  </ul>
</template>
<script setup>
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

const store = useStore();
const route = useRoute();
const router = useRouter();
const props = defineProps({
  content: Array,
});

const logout = async () => {
  await store.dispatch('auth/logout', {
    onSuccess: () => {
      router.push({ name: 'Home' });
    },
  });
};

</script>
