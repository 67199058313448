import Stats from '@/services/Stats';

export default {
  async getAll({ commit }, data) {
    commit('setStatsLoading', true);
    try {
      const stats = await Stats.getAll(data);
      commit('setStats', stats);
    } catch (error) {
      commit('setStatsErrors', error?.response?.data?.errors);
    }
    commit('setStatsLoading', false);
  },
};
