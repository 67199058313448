<template>
  <form
    class="needs-validation"
    novalidate
    @submit.prevent="forgotPassword"
  >
    <div class="container p-md-5 p-sm-3 min-vh-100 d-flex flex-column">
      <div class="row">
        <div class="col col-sm-12">
          <div class="mb-4">
            <router-link :to="{ name: 'Home' }">
              <img
                src="@/assets/images/icons/arrow-left.svg"
                alt="Retour à l'accueil"
                class="me-2"
              >
              <span>Retour à l'accueil</span>
            </router-link>
          </div>
          <h1 class="lh-1">
            <span class="text-medium">Réinitialiser</span>
            <br> <span class="text-bold">son mot de passe</span>
          </h1>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col col-sm-12">
          <div class="line-primary" />
        </div>
      </div>
      <div
        v-if="!forgotPasswordSuccess"
        class="row d-flex flex-column mt-5"
      >
        <div class="row my-5">
          <div class="col col-sm-12">
            <p>Pour réinitialiser votre mot de passe, veuillez renseigner votre adresse email.</p>
          </div>
        </div>
        <div class="row">
          <div class="col col-12 ">
            <label class="form-label text-purple_grey text-bold">Adresse email</label>
            <input
              v-model="formData.email"
              type="email"
              :class="[{ 'invalid' : forgotPasswordErrors?.email }, 'form-control', 'w-100']"
              placeholder="Votre adresse email"
              required
            >
            <span
              v-if="forgotPasswordErrors?.email"
              class="text-danger"
            >{{ forgotPasswordErrors?.email[0] }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col col-sm-12 mt-4">
            <button
              class="btn btn-purple-gradient w-100 text-bold p-3"
              type="submit"
              :disabled="forgotPasswordLoading"
            >
              <span
                v-if="forgotPasswordLoading"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
              Réinitialiser le mot de passe
            </button>
          </div>
        </div>
      </div>
      <div
        v-else
        class="row d-flex flex-column justify-content-center mt-5 text-center"
      >
        <img
          src="@/assets/images/icons/mail.svg"
          alt="Mail envoyé"
          class="w-50 mt-5 m-auto"
        >
        <h1>Vous avez reçu un mail !</h1>
      </div>
    </div>
  </form>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

const store = useStore();
const route = useRoute();
const forgotPasswordSuccess = computed(() => store.state.auth.forgot_password_success);
// const forgotPasswordSuccess = true;
const forgotPasswordLoading = computed(() => store.state.auth.forgot_password_loading);
const forgotPasswordErrors = computed(() => store.state.auth.forgot_password_errors);

const formData = ref({
  email: '',
  guard: route.params.scope,
});
const forgotPassword = () => {
  store.dispatch('auth/forgotPassword', formData.value);
};

onMounted(() => {
  store.commit('auth/setForgotPasswordErrors', {});
});

</script>
