<template>
  <div>
    <h2>Administrateur</h2>
    <nav
      class="mb-5"
      style="--bs-breadcrumb-divider: '•';"
      aria-label="breadcrumb"
    >
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="#">Accueil</a>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
        >
          Les administrateurs
        </li>
      </ol>
    </nav>
    <div class="my-4">
      <router-link :to="{ name: 'AdminsList' }">
        <img
          src="@/assets/images/icons/arrow-left.svg"
          alt="Retour aux administrateurs"
          class="me-2"
        >
        <span>Retour aux administrateurs</span>
      </router-link>
    </div>
    <h1 class="mt-5">
      Ajouter un administrateur
    </h1>
    <hr>
    <div>
      <label class="form-label text-purple_grey text-bold">Email</label>
      <input
        v-model="formData.email"
        type="email"
        :class="[{ 'invalid' : create_admin_errors?.email }, 'form-control', 'input-sizer']"
        placeholder="Adresse email"
        required
      >
      <span
        v-if="create_admin_errors?.email"
        class="text-danger"
      >{{ create_admin_errors?.email[0] }}</span>
    </div>
    <button
      class="btn btn-purple-gradient text-bold mt-3"
      :disabled="create_admin_loading"
      @click="createAdmin"
    >
      <span
        v-if="create_admin_loading"
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      />
      Ajouter l'administrateur
    </button>
    <div
      v-if="create_admin"
      class="text-green"
    >
      Email Envoyé !
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

const create_admin_errors = computed(() => store.state.admin.create_admin_errors);
const create_admin_loading = computed(() => store.state.admin.create_admin_loading);
const create_admin = computed(() => store.state.admin.create_admin);

const store = useStore();
const formData = ref({
  email: null,
  password: 'temp_password',
});

const createAdmin = () => {
  store.dispatch('admin/create', formData.value);
};

</script>
