<template>
  <div class="vh-100 w-100 mx-0 p-0">
    <div class="row  mx-0 p-0">
      <div
        class="col-12 col-lg-4 col-md-6 bg-black min-vh-100
      rounded-end-fat z-index2 position-absolute"
      >
        <Register v-if="action === 'register'" />
        <Login
          v-if="action === 'login'"
          :profil="loginProfil"
        />
        <!--        <email-verified v-if="action === 'emailVerified'" />-->
        <forgot-password v-if="action === 'forgotPassword'" />
        <reset-password v-if="action === 'resetPassword'" />
      </div>
    </div>
    <div class="position-fixed end-0 mx-0 p-0">
      <img
        v-if="route.params.profil === 'company'"
        class="fit-cover vh-100 min-vw-100"
        src="@/assets/images/bg-company-full-res.jpg"
        alt=""
      >
      <img
        v-if="action === 'register'"
        class="fit-cover vh-100"
        src="@/assets/images/bg-register-full-res_cropped.webp"
        alt=""
      >
      <img
        v-else
        class="fit-cover vh-100 w-100"
        src="@/assets/images/bg-candidate-full-res.jpg"
        alt=""
      >
    </div>
  </div>
</template>

<script setup>
import Register from '@/components/register/Main.vue';
import Login from '@/components/login/Main.vue';
import ResetPassword from '@/components/password/ResetPassword.vue';
import ForgotPassword from '@/components/password/ForgotPassword.vue';
import { useRoute } from 'vue-router';
import { computed } from 'vue';

const route = useRoute();

const props = defineProps({
  action: String,
});

const loginProfil = computed(() => {
  if (route.params.profil === 'candidate') {
    return {
      title: 'Vous êtes un candidat ?',
      text: "Identifiez-vous et trouvez l'offre d'emploi qui vous correspond le plus !",
      guard: 'user',
    };
  } if (route.params.profil === 'company') {
    return {
      title: 'Vous êtes une entreprise ?',
      text: "Déposez vos offres d'emploi et trouvez vos employés de demain !",
      guard: 'recruiter',
    };
  }
  return {
    text: 'Identifiez-vous pour pouvoir administrer la plateforme ViewzMe',
    guard: 'admin',
  };
});

</script>
