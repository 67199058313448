<template>
  <div>
    <div
      class="candidate-dashboard-ban-full ban-full mb-5 bg-dark_grey "
    />
    <h1
      class="d-flex title-page text-black
     flex-column align-items-end justify-content-end position-relative"
    >
      <b class="text-uppercase">Dashboard</b>
    </h1>
    <Info
      v-if="completionProgress.completion === 100 && completionProgress.status === 'to_validate'"
      :text="'Votre profil est complété à 100%, les administrateurs de ViewzMe ' +
        'vont très bientôt le valider pour que vous ayez accès aux offres d\'emploi. '"
    />
    <ul
      id="myTab"
      class="nav nav-pills"
      role="tablist"
    >
      <li
        class="nav-item"
        role="presentation"
      >
        <button
          id="all-offer-tab"
          class="nav-link active"
          data-bs-toggle="tab"
          data-bs-target="#all-offer"
          type="button"
          role="tab"
          aria-controls="all-offer"
          aria-selected="true"
          @click="allOfferSelect"
        >
          <span>
            Toutes les offres
            <span class="line-primary" />
          </span>
        </button>
      </li>
      <li
        class="nav-item"
        role="presentation"
      >
        <button
          id="new-offer-tab"
          class="nav-link"
          data-bs-toggle="tab"
          data-bs-target="#new-offer"
          type="button"
          role="tab"
          aria-controls="new-offer"
          aria-selected="true"
          @click="newOfferSelect"
        >
          <span>
            Nouvelles offres
            <span class="line-primary" />
          </span>
        </button>
      </li>
      <li
        class="nav-item"
        role="presentation"
      >
        <button
          id="accepted-offer-tab"
          class="nav-link"
          data-bs-toggle="tab"
          data-bs-target="#accepted-offer"
          type="button"
          role="tab"
          aria-controls="accepted-offer"
          aria-selected="false"
          @click="acceptedOfferSelect"
        >
          <span>
            Offres acceptées
            <span class="line-primary" />
          </span>
        </button>
      </li>
      <li
        class="nav-item"
        role="presentation"
      >
        <button
          id="refused-offer-tab"
          class="nav-link"
          data-bs-toggle="tab"
          data-bs-target="#refused-offer"
          type="button"
          role="tab"
          aria-controls="refused-offer"
          aria-selected="false"
          @click="refusedOfferSelect"
        >
          <span>
            Offres refusées
            <span class="line-primary" />
          </span>
        </button>
      </li>
      <li
        class="nav-item"
        role="presentation"
      >
        <button
          id="shortlisted-offer-tab"
          class="nav-link"
          data-bs-toggle="tab"
          data-bs-target="#shortlisted-offer"
          type="button"
          role="tab"
          aria-controls="shortlisted-offer"
          aria-selected="false"
          @click="shortlistedOfferSelect"
        >
          <span>
            Offre shortlistées
            <span class="line-primary" />
          </span>
        </button>
      </li>
    </ul>
    <div
      v-if="offersLoading"
      class="d-flex justify-content-center mt-5"
    >
      <div
        class="spinner-border"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div
      v-else
    >
      <div
        v-if="offers?.[0]"
        class="tab-content"
      >
        <div
          id="all-offer"
          class="tab-pane fade show active"
          role="tabpanel"
          aria-labelledby="all-offer-tab"
        >
          <OffersList
            :profile="'candidate'"
            :list="offers"
          />
        </div>
        <div
          id="new-offer"
          class="tab-pane fade"
          role="tabpanel"
          aria-labelledby="new-offer-tab"
        >
          <OffersList
            :profile="'candidate'"
            :list="offers"
          />
        </div>
        <div
          id="accepted-offer"
          class="tab-pane fade"
          role="tabpanel"
          aria-labelledby="accepted-offer-tab"
        >
          <OffersList
            :profile="'candidate'"
            :list="offers"
          />
        </div>
        <div
          id="refused-offer"
          class="tab-pane fade show"
          role="tabpanel"
          aria-labelledby="refused-offer-tab"
        >
          <OffersList
            :profile="'candidate'"
            :list="offers"
          />
        </div>
        <div
          id="shortlisted-offer"
          class="tab-pane fade"
          role="tabpanel"
          aria-labelledby="shortlisted-offer-tab"
        >
          <OffersList
            :profile="'candidate'"
            :list="offers"
          />
        </div>
      </div>
      <div
        v-else
        class=" d-flex justify-content-center mt-5"
      >
        <h5>Pas encore d'offre...</h5>
      </div>
    </div>
  </div>
</template>

<script setup>

import { computed, onMounted } from 'vue';
import OffersList from '@/components/offers/OffersList.vue';
import { useStore } from 'vuex';
import Info from '@/components/Info.vue';

const store = useStore();

const offers = computed(() => store.state.application.applications.data?.data);
const offersLoading = computed(() => store.state.application.applications_loading);
const completionProgress = computed(() => store.state.candidate.candidate_detail_completion);

const allOfferSelect = () => {
  store.dispatch('application/getAll');
};

const newOfferSelect = () => {
  store.dispatch('application/getAll', { user_step: 'new' });
};

const acceptedOfferSelect = () => {
  store.dispatch('application/getAll', { user_step: 'accepted', recruiter_step: 'new' });
};

const refusedOfferSelect = () => {
  store.dispatch('application/getAll', { user_step: 'refused' });
};

const shortlistedOfferSelect = () => {
  store.dispatch('application/getAll', { user_step: 'accepted', recruiter_step: 'accepted' });
};

onMounted(() => {
  store.dispatch('application/getAll');
});
</script>
