import License from '@/services/License';

export default {
  async getAll({ commit }) {
    commit('setLicensesLoading', true);
    try {
      const licenses = await License.getAll();
      commit('setLicenses', licenses);
    } catch (error) {
      commit('setLicensesErrors', error?.response?.data?.errors);
    }
    commit('setLicensesLoading', false);
  },
};
