export default {
  setCandidateVideos(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.candidate_videos = value;
  },
  setCandidateVideosErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.candidate_videos_errors = value;
  },
  setCandidateVideosLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.candidate_videos_loading = value;
  },
  setCandidateVideosCreateErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.candidate_videos_create_errors = value;
  },
  setCandidateVideosCreateLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.candidate_videos_create_loading = value;
  },
  setCandidateVideosUpdateErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.candidate_videos_update_errors = value;
  },
  setCandidateVideosUpdateLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.candidate_videos_update_loading = value;
  },
};
