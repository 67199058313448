export default {
  setDegreeLevels(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.degree_levels = value;
  },
  setDegreeLevelsErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.degree_levels_errors = value;
  },
  setDegreeLevelsLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.degree_levels_loading = value;
  },
};
