<template>
  <div class="card company-offer-card">
    <video
      v-if="data.video && showVideo"
      controls
      :src="`${VITE_API_URL}/${data.video}`"
      class="card-img-top fit-cover w-100"
    />
    <span
      v-if="!showVideo || !data.video"
      @click="showVideo = true"
    >
      <img
        :src="`${VITE_API_URL}/${data.company_logo}`"
        alt="Logo de l'entreprise"
        class="card-img-top fit-contain "
      >
      <img
        v-if="data.video"
        src="@/assets/images/play.png"
        alt="Lancer la vidéo de l'offre"
        class="position-absolute play-video-offer"
      >
    </span>

    <div class="card-body pt-0 pb-1 d-flex flex-column justify-content-between">
      <router-link :to="{ name: 'CompanyOfferDetail', params: { id: data?.id } }">
        <div
          class="text-bold m-auto text-center text-uppercase "
        >
          <div
            class="d-inline-block text-truncate"
            style="max-width: 216px;"
          >
            {{ data.title }}
          </div>
        </div>
        <div class="card-text text-center card-text-md">
          {{ data.verified_at ? 'validée le : ' +
            normalizer.formatDate(data.verified_at) : 'Non validée' }}
        </div>
        <div class="card-text mt-2">
          <img
            src="@/assets/images/icons/marker.svg"
            alt="city"
            class="me-1"
          >
          <span class="">
            {{ data.city ?
              data.city.name : '100% télétravail' }}
          </span>
        </div>
        <div class="card-text">
          <span class="text-bold">Types de contrat</span> :
          <span
            v-for="type in data.contract_types"
            :key=" type.id"
            class="badge rounded-pill bg-black text-yellow text-bold"
          >
            {{ type.name }}
          </span>
        </div>

        <div class="card-text">
          <span class="text-bold">Candidats</span> : {{ data.number_of_matches }}
        </div>
        <div class="card-text">
          <span class="text-bold">Auteur</span> :
          {{ data.author?.first_name + ' ' + data.author?.second_name }}
        </div>
      </router-link>
      <div
        v-if="data.new_matches_count > 0"
        class="badge bg-blue w-50 mt-3"
      >
        {{ data.new_matches_count }} Nouvelle(s) <br> candidature(s)
      </div>
      <div class="row align-items-center">
        <!--        With love-->
        <!--        <div class="col-7 d-flex flex-column">-->
        <!--          <span :class="['badge', `bg-${translate?.[data.state]?.color}`, 'w-50', 'my-1']">-->
        <!--            {{ translate?.[data.state]?.text }}</span>-->
        <!--          <span :class="['badge', `bg-${translate?.[data.status]?.color}`, 'w-50']">-->
        <!--            {{ translate?.[data.status]?.text }}</span>-->
        <!--        </div>-->
        <div class="col-5 p-0">
          <router-link :to="{ name : 'CompanyOfferCreate', query : { duplicateId: data.id } }">
            <button class="btn btn-green card-button position-absolute text-bold">
              Dupliquer
            </button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue';
import normalizer from '@/utils/normalizer';

const { VITE_API_URL } = import.meta.env;
const showVideo = ref(false);
const props = defineProps({
  data: Object,
});

const translate = {
  refused: {
    color: 'danger',
    text: 'Refusé',
  },
  to_validate: {
    color: 'info',
    text: 'A valider',
  },
  archived: {
    color: 'info',
    text: 'Archivé',
  },
  active: {
    color: 'green',
    text: 'Actif',
  },
  inactive: {
    color: 'info',
    text: 'Inactif',
  },
};
</script>
