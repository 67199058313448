import Matching from '@/services/Matching';

export default {
  async getAll({ commit }, data) {
    commit('setMatchingsLoading', true);
    try {
      const matchings = await Matching.getAll(data);
      commit('setMatchings', matchings);
      commit('setMatchingsErrors', []);
    } catch (error) {
      commit('setMatchingsErrors', error?.response?.data?.errors);
    }
    commit('setMatchingsLoading', false);
  },
  async getOne({ commit }, data) {
    commit('setMatchingDetailLoading', true);
    try {
      const matching = await Matching.getOne(data);
      commit('setMatchingDetail', matching);
      commit('setMatchingDetailErrors', []);
    } catch (error) {
      commit('setMatchingDetailErrors', error?.response?.data?.errors);
    }
    commit('setMatchingDetailLoading', false);
  },
  async acceptCandidate({ commit }, data) {
    commit('setMatchingDetailLoading', true);
    try {
      const matching = await Matching.acceptCandidate(data);
      commit('setMatchingDetail', matching);
      commit('setMatchingDetailErrors', []);
    } catch (error) {
      commit('setMatchingDetailErrors', error?.response?.data?.errors);
    }
    commit('setMatchingDetailLoading', false);
  },
  async refuseCandidate({ commit }, data) {
    commit('setMatchingDetailLoading', true);
    try {
      const matching = await Matching.refuseCandidate(data);
      commit('setMatchingDetail', matching);
      commit('setMatchingDetailErrors', []);
    } catch (error) {
      commit('setMatchingDetailErrors', error?.response?.data?.errors);
    }
    commit('setMatchingDetailLoading', false);
  },
  async recruitCandidate({ commit }, data) {
    commit('setMatchingDetailLoading', true);
    try {
      const matching = await Matching.recruitCandidate(data);
      commit('setMatchingDetail', matching);
      commit('setMatchingDetailErrors', []);
    } catch (error) {
      commit('setMatchingDetailErrors', error?.response?.data?.errors);
    }
    commit('setMatchingDetailLoading', false);
  },
  async createChat({ commit }, data) {
    commit('setCreateChatLoading', true);
    try {
      const chat = await Matching.createChat(data);
      commit('setChat', chat);
      commit('setCreateChatErrors', []);
    } catch (error) {
      commit('setCreateChatErrors', error?.response?.data?.errors);
    }
    commit('setCreateChatLoading', false);
  },
  async myChat({ commit }, data) {
    commit('setMyChatLoading', true);
    try {
      const conversation = await Matching.myChat(data.id);
      commit('setMyChat', conversation);
      commit('setMyChatErrors', []);
      data.onSuccess();
    } catch (error) {
      commit('setMyChatErrors', error?.response?.data?.message);
    }
    commit('setMyChatLoading', false);
  },
};
