<template>
  <div class="px-sm-5 rounded-3 my-5 d-flex flex-column flex-sm-row align-items-center">
    <div
      :class="[{ 'border-green' : progress.verified }, { 'border-danger' : !progress.verified },
               'col', 'col-12', 'col-md-2', 'bg-grey', 'rounded-circle', 'd-flex',
               'flex-column', 'align-items-center', 'justify-content-center', 'border',
               'border-3', 'user-profil-state']"
    >
      <span class="text-center position-absolute">
        Etat du compte
      </span>
      <h3
        v-if="progress.verified"
        class="text-bold text-green"
      >
        Actif
      </h3>
      <h3
        v-else
        class="text-bold text-danger"
      >
        Inactif
      </h3>
    </div>
    <div class="d-flex flex-column justify-content-center ps-sm-5">
      <h4>
        Étapes de complétion du profil
      </h4>
      <div class="d-flex align-items-center">
        <img
          v-if="progress.completedInformations"
          src="@/assets/images/icons/icon-validate.svg"
          alt=""
        >
        <img
          v-else
          src="@/assets/images/icons/icon-block.svg"
          alt=""
        >
        <span class="text-bold mx-1">1 - </span>
        Compléter le profil
      </div>
      <div class="d-flex align-items-center">
        <img
          v-if="progress.completedVideos"
          src="@/assets/images/icons/icon-validate.svg"
          alt=""
        >
        <img
          v-else
          src="@/assets/images/icons/icon-block.svg"
          alt=""
        >
        <span class="text-bold mx-1">2 - </span>

        Compléter les vidéos (facultatif)
        <span class="ms-2 redirect-link">
          <router-link
            :to="{ name : 'CandidateVideos' }"
            class="text-primary fst-italic"
          >
            Aller vers mes vidéos
            <img
              src="@/assets/images/icons/chevron-right-primary.svg"
              alt=""
            >
          </router-link>
        </span>
      </div>
      <div class="d-flex align-items-center">
        <img
          v-if="progress.verified"
          src="@/assets/images/icons/icon-validate.svg"
          alt=""
        >
        <img
          v-else
          src="@/assets/images/icons/icon-block.svg"
          alt=""
        >
        <span class="text-bold mx-1">3 - </span>
        Compte validé par les administrateurs
      </div>
    </div>
    <div class="p-3 rounded mb-3 bg-grey d-none">
      <div class="">
        <h3>Informations de fonctionnement :</h3>
        <p>
          Afin de pouvoir postuler il vous faut compléter votre profil et vos vidéos. <br>
          Votre profil doit ensuite être validé par les administrateurs. <br>

          Une fois validé, votre profil devient actif et
          est pris en compte par le moteur matching! <br>

          Attention : Certaines modifications à votre profil nécessiterons d'être valider par les
          administrateurs, mais pas d'inquiétude, votre profil avant modification est encore actif !
        </p>
      </div>
    </div>
  </div>
  <div
    v-if="progress.adminFeedback?.data?.type === 'up_refused'"
    class="alert alert-danger alert-dismissible fade show"
    role="alert"
  >
    <h4>
      Les dernières modifications
      de votre profil ont été refusé
    </h4>
    Motif : {{ progress.adminFeedback?.data?.ground_of_refusal }}
  </div>
  <div
    v-if="progress.adminFeedback?.data?.type === 'up_accepted'"
    class="alert alert-green alert-dismissible fade show"
    role="alert"
  >
    <h4>
      Les dernières modifications de votre profil ont été validées
    </h4>
  </div>
</template>
<script setup>
import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';
import normalizer from '@/utils/normalizer';

const store = useStore();

const progress = computed(() => store.state.candidate.candidate_detail_completion);

onMounted(() => {
  store.dispatch('candidate/getCompletionProgress');
});
</script>
