export default {
  authenticated(state) {
    return state.authenticated;
  },
  user(state) {
    return state.user;
  },
  authenticationErrors(state) {
    return state.authentication_errors;
  },
  authenticationLoading(state) {
    return state.authentication_loading;
  },
};
