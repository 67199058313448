import Candidate from '@/services/Candidate';
import normalizers from '@/utils/normalizer';

export default {
  async getAll({ commit }, data) {
    commit('setCandidatesLoading', true);
    try {
      const companies = await Candidate.getAll(data);
      commit('setCandidates', companies);
    } catch (error) {
      commit('setCandidatesErrors', error?.response?.data?.errors);
    }
    commit('setCandidatesLoading', false);
  },
  async getAllNotVerified({ commit }, data) {
    commit('setCandidatesLoading', true);
    try {
      const companies = await Candidate.getAllNotVerified(data);
      commit('setCandidates', companies);
    } catch (error) {
      commit('setCandidatesErrors', error?.response?.data?.errors);
    }
    commit('setCandidatesLoading', false);
  },
  async getOne({ commit }, id) {
    commit('setCandidateDetailLoading', true);
    try {
      const { data } = await Candidate.getOne(id);
      commit('setCandidateDetail', { data });
      commit('setCandidateDetailErrors', []);
    } catch (error) {
      commit('setCandidateDetailErrors', error?.response?.data?.errors);
    }
    commit('setCandidateDetailLoading', false);
  },
  async update({ commit }, form) {
    commit('setCandidateDetailLoading', true);
    try {
      await Candidate.update(form.data);
      form.onSuccess();
      commit('setCandidateDetailErrors', []);
    } catch (error) {
      commit('setCandidateDetailErrors', normalizers.formatErrors(error?.response?.data?.errors));
    }
    commit('setCandidateDetailLoading', false);
  },
  async verify({ commit }, data) {
    commit('setCandidateDetailLoading', true);
    try {
      await Candidate.verify(data.id);
      data.onSuccess();
    } catch (error) {
      commit('setCandidateDetailErrors', error?.response?.data?.errors);
    }
    commit('setCandidateDetailLoading', false);
  },
  async refuse({ commit }, data) {
    commit('setCandidateDetailLoading', true);
    try {
      await Candidate.refuse(data);
      data.onSuccess();
    } catch (error) {
      commit('setCandidateDetailErrors', error?.response?.data?.errors);
    }
    commit('setCandidateDetailLoading', false);
  },
  async deactivate({ commit }, data) {
    commit('setCandidateDetailLoading', true);
    try {
      await Candidate.deactivate(data.id);
      data.onSuccess();
    } catch (error) {
      commit('setCandidateDetailErrors', error?.response?.data?.errors);
    }
    commit('setCandidateDetailLoading', false);
  },
  async delete({ commit }, form) {
    commit('setCandidateDetailLoading', true);
    try {
      await Candidate.delete(form);
    } catch (error) {
      commit('setCandidateDetailErrors', error?.response?.data?.errors);
    }
    commit('setCandidateDetailLoading', false);
  },
  async showProfile({ commit }) {
    commit('setCandidateDetailLoading', true);
    try {
      const data = await Candidate.showProfile();
      commit('setCandidateDetail', data.data);
      commit('setCandidateDetailErrors', []);
    } catch (error) {
      commit('setCandidateDetailErrors', error?.response?.data?.errors);
    }
    commit('setCandidateDetailLoading', false);
  },
  async getCompletionProgress({ commit }) {
    commit('setCandidateDetailCompletionLoading', true);
    try {
      const data = await Candidate.getCompletionProgress();
      commit('setCandidateDetailCompletion', data.data);
    } catch (error) {
      commit('setCandidateDetailCompletionErrors', error?.response?.data?.errors);
    }
    commit('setCandidateDetailCompletionLoading', false);
  },
};
