export default {
  setLanguages(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.languages = value;
  },
  setLanguagesErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.languages_errors = value;
  },
  setLanguagesLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.languages_loading = value;
  },
};
