import Api from './Api';

export default {
  async getAll(data) {
    return Api.get(`api/offers/${data.id}/matchings`, { params: data.filter });
  },
  async getOne(data) {
    return Api.get(`api/offers/${data.offer_id}/matchings/${data.matching_id}`);
  },
  async acceptCandidate(data) {
    return Api.post(`api/offers/${data.offer_id}/matchings/${data.matching_id}/accept-user`);
  },
  async refuseCandidate(data) {
    return Api.post(`api/offers/${data.offer_id}/matchings/${data.matching_id}/refuse-user`, {
      reasons: data.reason,
    });
  },
  async recruitCandidate(data) {
    return Api.post(`api/offers/${data.offer_id}/matchings/${data.matching_id}/recruit-user`, {
      deactivate_offer: data.deactivate_offer,
    });
  },
  async createChat(data) {
    return Api.post(`api/offers/${data.offer_id}/matchings/${data.matching_id}/create-chat`, { user_id: data.user_id });
  },
  async myChat(id) {
    return Api.get(`api/matching/${id}/my-chat`);
  },
};
