export default {
  setTutorials(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.tutorials = value;
  },
  setTutorialsErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.tutorials_errors = value;
  },
  setTutorialsLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.tutorials_loading = value;
  },
  setTutorialDetail(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.tutorial_detail = value;
  },
  setTutorialDetailErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.tutorial_detail_errors = value;
  },
  setTutorialDetailLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.tutorial_detail_loading = value;
  },
};
