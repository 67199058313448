<template>
  <div class="">
    <label class="form-label text-purple_grey text-bold">Code postal <Required /></label>
    <div class="input-group has-validation">
      <v-select
        v-model="formData.city"
        class="form-control v-selector"
        label="zip_code"
        :options="cities"
        value="option"
        :disabled="disabled"
        @search="search"
        @emptied="formData.city = null"
      >
        <template #option="option">
          {{ option.name }} {{ option.zip_code }}
        </template>
        <template #no-options>
          <span v-if="inputValue.length >= 3">Pas de resultat.</span>
          <span v-else> Veuillez saisir au moins 3 caractères</span>
        </template>
      </v-select>
    </div>
    <span
      v-if="errors?.[0]"
      class="text-danger"
    >Veuillez choisir une ville valide</span>
  </div>
</template>
<script setup>
import { useStore } from 'vuex';
import {
  computed, onMounted, ref, watch,
} from 'vue';
import Required from '@/components/Required.vue';

const store = useStore();

const cities = computed(() => store.state.city?.cities?.data?.data);
const debounce = ref(500);
const props = defineProps({
  errors: Object,
  existingCity: [Object, String],
  disabled: Boolean,
});

const formData = ref({
  city: null,
});

const inputValue = ref('');

const emit = defineEmits(['sendCity']);

const search = (value) => {
  if (value) {
    inputValue.value = value;
  }
  if (value.length >= 3) {
    setTimeout(() => {
      store.dispatch(
        'city/getAll',
        value,
      );
    }, debounce.value);
  }
};
watch(() => formData.value.city, () => {
  emit('sendCity', formData.value.city);
});

watch(() => props.existingCity, () => {
  formData.value.city = props.existingCity;
});

onMounted(() => {
  if (props.existingCity) {
    formData.value.city = props.existingCity;
  }
});

</script>
