<template>
  <div
    id="pdf-content"
    class="bg-dark_grey"
    style="display: none;"
  >
    <div class="col bg-dark_grey p-2 ps-5 h-100">
      <div class="row my-4">
        <div class="col col-12 col-sm-3 p-4 d-flex flex-column justify-content-center">
          <img
            :src="'data:image/jpeg;base64,'+ profil?.user_profile?.base64_picture"
            alt="Photo du candidat"
            class="rounded-3 w-100"
          >
        </div>
        <div class="col col-12 col-sm-6 p-4 d-flex flex-column justify-content-center">
          <h2 class="text-bold">
            {{ profil?.user_profile?.first_name + ' ' + profil?.user_profile?.second_name }}
          </h2>
          <span>
            <span class="text-bold">Date de naissance :</span>
            {{ normalizer.formatDate(profil?.user_profile?.birth_date) }}
          </span>
          <span>
            <span class="text-bold">Localisation :</span>
            {{ profil?.user_profile?.city?.name }}
          </span>
          <span class="mt-3">
            <span class="text-bold">
              Email :
            </span>
            <a :href="`mailto:${profil?.user_profile?.email}`">
              {{ profil?.user_profile?.email }}
            </a>
          </span>
          <span>
            <span class="text-bold">
              Numéro de téléphone :
            </span>
            {{ profil?.user_profile?.phone_number }}
          </span>

          <span class="mt-3">
            <span class="text-bold">Langue(s) : </span>
            <span
              v-for="language in profil?.user_profile?.languages"
              :key="language.id"
            >{{ language.name + ' ' }}
            </span>
          </span>
          <span>
            <span class="text-bold">Permis obtenu(s) : </span>
            <span
              v-for="license in profil?.user_profile?.licenses"
              :key="license.id"
            >
              {{ license.name + ' ' }}
            </span>
            <span v-if="profil?.user_profile?.licenses.length <= 0"> Aucun </span>
          </span>

          <span
            v-if="profil?.user_profile?.external_links"
            class="text-truncate-250"
          ><span class="text-bold">Liens externes : </span>
            <span
              v-for="link in profil?.user_profile?.external_links"
              :key="link"
            >
              <br><a :href="link">{{ link }}</a>
            </span>
          </span>
        </div>
      </div>
      <div class="row mt-3 g-4 pb-4">
        <h4 class="text-primary text-bold">
          Sa recherche
        </h4>
        <hr>
        <span class="col col-4">
          <span class="text-bold text-white_grey">Métier </span> <br>
          <span class="text-bold">{{ profil?.user_research?.job?.name }}</span>
        </span>
        <span class="col col-4">
          <span class="text-bold text-white_grey">Nombre d'années d'expérience </span> <br>
          <span class="text-bold">{{ profil?.user_research?.years_of_experience }}</span>
        </span>
        <span class="col col-4">
          <span class="text-bold text-white_grey">Type de contrat </span><br>
          <span
            v-for="type in profil?.user_research?.contract_types"
            :key="type.id"
            class="text-bold"
          >{{ type.name + ' ' }}
          </span>
        </span>
        <span class="col col-4">
          <span class="text-bold text-white_grey">Fourchette de salaire</span><br>
          <span class="text-bold">
            {{ profil?.user_research?.salary_minimum
              + "€ - "
              + profil?.user_research?.salary_maximum
              + "€"
            }}
          </span>
        </span>
        <span class="col col-4">
          <span class="text-bold text-white_grey">Disponibilité </span><br>
          <span class="text-bold">&nbsp;
            {{
              profil?.user_research?.available_now
                ? "Disponible maintenant"
                : normalizer.formatDate(profil?.user_research?.availability)
            }}
          </span>
        </span>
      </div>
      <div class="row g-4 mt-5">
        <div class="col col-12 col-sm-6">
          <h4 class="text-primary text-bold">
            Expériences professionnelles
          </h4>
          <hr>
          <ul class="timeline">
            <li
              v-for="(item) in profil?.user_profile?.experiences"
              :key="item.id"
            >
              <span class="text-white_grey text-bold">
                {{
                  normalizer.formatDate(item.start_date?.iso)?.substring(3) + ' - ' +
                    (item.end_date?.iso
                      ? normalizer.formatDate(item.end_date?.iso)?.substring(3)
                      : 'à aujourd\'hui')
                }}
              </span>
              <div>
                <span class="text-bold">
                  {{
                    item.job_title + ', ' + item.company_name + ', ' + item.contract_type?.name
                  }}
                </span>
              </div>
            </li>
          </ul>
          <div
            v-if="profil?.user_profile?.experiences?.length <= 0"
            class="text-center"
          >
            Aucune expérience renseignée
          </div>
        </div>
        <div class="col col-12 col-sm-6">
          <h4 class="text-primary text-bold">
            Formations
          </h4>
          <hr>
          <ul class="timeline">
            <li
              v-for="course in profil?.user_profile?.courses"
              :key="course.id"
            >
              <span class="text-white_grey text-bold">
                {{
                  normalizer.formatDate(course.start_date?.iso)?.substring(3) + ' - ' +
                    (course.end_date?.iso
                      ? normalizer.formatDate(course.end_date?.iso)?.substring(3)
                      : 'à aujourd\'hui')
                }}
              </span>
              <p class="text-bold">
                {{
                  course.university_name + ', '
                    + course.degree_name + ', '
                    + course.degree_level?.name
                    + (course.degree_level?.name ? ', ' : '')
                    + (course.obtained ? 'Diplôme obtenu' : 'Diplôme non  obtenu')
                }}
              </p>
            </li>
          </ul>
          <div
            v-if="profil?.user_profile?.courses?.length <= 0"
            class="text-center"
          >
            Aucune formation renseignée
          </div>
        </div>
      </div>
    </div>
    <div class="html2pdf__page-break" />
  </div>
</template>

<script>
import {
  computed, onMounted,
} from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import normalizer from '@/utils/normalizer';
import html2pdf from 'html2pdf.js';

export default {
  setup() {
    const route = useRoute();
    const store = useStore();

    const profil = computed(() => store.state.matching?.matching_detail?.data?.data);

    const { VITE_API_URL } = import.meta.env;

    onMounted(() => {
      store.dispatch('matching/getOne', { matching_id: route.params.matching_id, offer_id: route.params.offer_id });
    });

    const toPDF = async () => {
      const domElement = document.getElementById('pdf-content');
      const element = domElement.cloneNode(true);

      element.style.display = 'block';

      html2pdf()
        .set({
          margin: 0,
          filename:
              `fiche_candidat_${profil.value.user_profile.first_name}_${profil.value.user_profile.second_name}.pdf`,
          image: { type: 'png' },
          html2canvas: { useCORS: true, scale: 2 },
          hotfixes: ['px_scaling'],
          jsPDF: { format: 'a4', orientation: 'portrait' },
          useCORS: true,
        })
        .from(element)
        .save();
    };
    return {
      toPDF,
      VITE_API_URL,
      normalizer,
      profil,
    };
  },
};
</script>
