<template>
  <div class="p-3 p-md-5 p-sm-3 min-vh-100 ">
    <div class="row mt-5 text-center vh-70">
      <div class="m-auto">
        <div
          v-if="emailVerifiedLoading"
          class="d-flex justify-content-center"
        >
          <div
            class="spinner-border"
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div v-if="emailVerified && !emailVerifiedLoading">
          <h1>Email Vérifié !</h1>
          <p>Vous pouvez dorénavant accéder à la plateforme.</p>
          <button
            class="btn btn-purple-gradient text-bold"
            @click="redirect"
          >
            Accéder à la plateforme
          </button>
        </div>
        <div v-if="realScope && !emailVerified && !emailVerifiedLoading">
          <h1>Il y a eu un problème...</h1>
          <p>Si le problème persiste, merci de nous contacter</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const store = useStore();
const router = useRouter();
const emailVerified = computed(() => store.state.auth.email_verified);
const emailVerifiedLoading = computed(() => store.state.auth.email_verified_loading);
const scope = computed(() => (localStorage.getItem('scope') === 'recruiter' ? 'company' : 'candidate'));
const realScope = computed(() => (localStorage.getItem('scope')));
const redirect = () => {
  if (emailVerified.value && scope.value === 'company') {
    router.push({ name: 'CompanyProfil' });
  } else { router.push({ name: 'CandidateProfil' }); }
};
</script>
