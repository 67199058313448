<template>
  <div>
    <button
      class="btn btn-purple-gradient text-bold"
      type="button"
      data-bs-toggle="modal"
      data-bs-target="#subscription-modal"
    >
      Choisir mon abonnement
    </button>
    <div
      id="subscription-modal"
      class=" modal fade"
      data-bs-backdrop="false"
    >
      <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content  bg-dark_grey">
          <div class="modal-header">
            <h5 class="modal-title">
              Choisir un abonnement
            </h5>
          </div>
          <div class="modal-body my-3 d-flex justify-content-center align-items-center flex-wrap">
            <div
              v-for="sub in subscriptions"
              :key="sub.id"
              class="m-2"
            >
              <input
                :id="`subscription-card-input-${sub.id}`"
                v-model="choice.subscription"
                type="radio"
                class="btn-check"
                name="options "
                :value="sub"
                aria-pressed="false"
              >
              <label
                class="btn subscription-card p-0 shadow-none"
                :for="`subscription-card-input-${sub.id}`"
              >
                <subscription-card
                  :data="sub"
                  @click="setChoice(sub.id)"
                />
              </label>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-danger text-bold"
              data-bs-dismiss="modal"
            >
              Annuler
            </button>
            <button
              type="button"
              class="btn btn-purple-gradient text-bold"
              data-bs-dismiss="modal"
              @click="emit('sendToInfos', choice.subscription)"
            >
              Choisir l'abonnement
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import SubscriptionCard from '@/components/cards/SubscriptionCard.vue';
import { useStore } from 'vuex';

const choice = ref({
  subscription: null,
});

const store = useStore();

const setChoice = (value) => {
  choice.value.subscription = value;
};

const subscriptions = computed(() => store.state?.subscriptions?.subscriptions?.data?.data);

const emit = defineEmits(['sendToInfos']);

onMounted(() => {
  store.dispatch('subscriptions/getAll');
});

</script>
