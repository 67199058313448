<template>
  <div>
    <h2>Nouveau tutoriel</h2>
    <nav
      class="mb-5"
      style="--bs-breadcrumb-divider: '•';"
      aria-label="breadcrumb"
    >
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="#">Accueil</a>
        </li>
        <li
          class="breadcrumb-item"
          aria-current="page"
        >
          <router-link :to="{ name: 'BackofficeTutorialsList' }">
            Les tutoriels
          </router-link>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
        >
          Modifier le tutoriel
        </li>
      </ol>
    </nav>
    <div class="my-4">
      <router-link :to="{ name: 'BackofficeTutorialsList' }">
        <img
          src="@/assets/images/icons/arrow-left.svg"
          alt="Retour aux entreprises"
          class="me-2"
        >
        <span>Retour aux tutoriels</span>
      </router-link>
    </div>

    <div class="d-flex justify-content-between flex-sm-column">
      <div class="d-flex justify-content-end flex-wrap">
        <button
          class="btn btn-green text-bold mx-2"
          data-bs-toggle="modal"
          data-bs-target=".add-tuto-modal"
          :disabled="tutoLoading"
        >
          <span
            v-if="tutoLoading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
          Modifier le tutoriel
        </button>
        <button
          class="btn btn-danger text-bold mx-2"
          data-bs-toggle="modal"
          data-bs-target=".delete-tuto-modal"
          :disabled="tutoLoading"
        >
          <span
            v-if="tutoLoading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
          Supprimer le tutoriel
        </button>
      </div>
      <Modal
        class="add-tuto-modal"
        :title="'Modifier un tutoriel'"
        :text="'Etes-vous sûr de vouloir modifier ce tutoriel ?'"
        :action-button="'Ajouter'"
        @callback="update"
      />
      <Modal
        class="delete-tuto-modal"
        :title="'Supprimer un tutoriel'"
        :text="'Etes-vous sûr de vouloir supprimer ce tutoriel ?'"
        :action-button="'Supprimer'"
        @callback="deleteTuto"
      />
    </div>
    <h4 class="text-primary text-bold mt-5">
      Informations du tutoriel
    </h4>
    <hr>
    <div
      v-if="tutoLoading"
      class="d-flex justify-content-center mt-5"
    >
      <div
        class="spinner-border"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div
      v-else
      class="row g-4"
    >
      <div class="col col-12 col-sm-6 col-md-4 col-lg-3">
        <label class="form-label text-purple_grey text-bold">Titre</label>
        <div class="input-group has-validation">
          <input
            v-model="formData.title"
            :class="[{ 'invalid' : tutoErrors?.title }, 'form-control', 'w-100']"
            placeholder="Titre du tutoriel"
          >
          <span
            v-if="tutoErrors?.title"
            class="text-danger"
          >{{ tutoErrors?.title[0] }}</span>
        </div>
      </div>
      <div class="col col-12 col-sm-6 col-md-4 col-lg-3">
        <label class="form-label text-purple_grey text-bold">Type</label>
        <div class="input-group has-validation">
          <select
            v-model="formData.type"
            :class="[{ 'invalid' : tutoErrors?.type }, 'form-select', 'w-100']"
          >
            <option
              v-for="type in types"
              :key="type.name"
              :value="type.value"
            >
              {{ type.name }}
            </option>
          </select>
          <span
            v-if="tutoErrors?.type"
            class="text-danger"
          >{{ tutoErrors?.type[0] }}</span>
        </div>
      </div>
      <div class="col col-12 col-sm-6 col-md-4 col-lg-3">
        <label class="form-label text-purple_grey text-bold">Position</label>
        <div class="input-group has-validation">
          <input
            v-model="formData.position"
            :class="[{ 'invalid' : tutoErrors?.position }, 'form-control', 'w-100']"
            placeholder="Position"
          >
          <span
            v-if="tutoErrors?.position"
            class="text-danger"
          >{{ tutoErrors?.position[0] }}</span>
        </div>
      </div>
      <div class="row g-4">
        <div class="col col-12 col-sm-6">
          <label class="form-label text-purple_grey text-bold">
            Vidéo
          </label>
          <div
            v-if="!formData.video"
            class="input-group has-validation"
          >
            <input
              :class="[{ 'invalid' : tutoErrors?.video }, 'form-control', 'w-100']"
              type="file"
              @change="changeVideo"
            >
            <span
              v-if="tutoErrors?.video"
              class="text-danger"
            >{{ tutoErrors?.video[0] }}</span>
          </div>
          <div
            v-else
            class="d-flex"
          >
            <div class="video-company-profile">
              <video controls>
                <source
                  :src="`${VITE_API_URL}/${formData.video}`"
                  type="video/mp4"
                >
              </video>
            </div>
            <button
              type="button"
              class="btn-close btn-close-white ms-2"
              @click="removeVideo"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  computed, onMounted, ref, watch,
} from 'vue';
import Modal from '@/components/modal/Modal.vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import normalizer from '@/utils/normalizer';

const store = useStore();
const route = useRoute();
const router = useRouter();

const formData = ref({
  title: null,
  type: null,
  position: null,
  video: null,
});

const tuto = computed(() => (store.state.tutorial?.tutorial_detail?.data?.data));
const tutoLoading = computed(() => store.state.tutorial?.tutorial_detail_loading);
const tutoErrors = computed(() => store.state.tutorial?.tutorial_detail_errors);

const { VITE_API_URL } = import.meta.env;

const types = ref([
  {
    name: 'recruteur',
    value: 'recruiter',
  },
  {
    name: 'candidat',
    value: 'candidate',
  },
]);

const update = () => {
  const tempFormData = ref({ ...formData.value });
  if (tempFormData.value.video) {
    tempFormData.value.video = tempFormData.value.video.replace('/storage', 'public');
  } else {
    delete tempFormData.value.video;
  }
  const result = normalizer.makeFormDataWithObject(tempFormData.value);
  if (video.value) {
    result.append('video', video.value);
  }
  store.dispatch('tutorial/update', {
    id: route.params.id,
    data: result,
    onSuccess: () => {
      store.dispatch('tutorial/getAll', { type: 'recruiter' });
      router.push({ name: 'BackofficeTutorialsList' });
    },
  });
};

const deleteTuto = () => {
  store.dispatch('tutorial/delete', {
    id: route.params.id,
    onSuccess: () => {
      router.push({ name: 'BackofficeTutorialsList' });
    },
  });
};

const video = ref(null);

const removeVideo = () => {
  formData.value.video = null;
  video.value = null;
};

const changeVideo = (event) => {
  video.value = event.target.files[0];
};

watch(tuto, (value) => {
  formData.value = { ...value };
});

onMounted(() => {
  store.dispatch('tutorial/getOne', route.params.id);
});

</script>
