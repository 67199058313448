<template>
  <button
    v-if="!mediaRecorder"
    class="btn btn-purple-gradient text-bold d-flex
        justify-content-center align-items-center mt-2"
    @click="runRecord"
  >
    {{ updateMode ? 'Enregistrer une nouvelle vidéo' : 'Enregistrer une vidéo' }}
  </button>
  <div v-if="recordingError">
    {{ recordingErrorMessage }}
  </div>
  <div
    v-show="showRecord"
    id="webcam-container"
    class="webcam-container mt-5 bg-black p-2 p-md-4 rounded-3"
  >
    <h5 class="mb-4">
      Enregistrer une nouvelle vidéo
    </h5>

    <video
      id="video-source"
      ref="videoSource"
      :width="widthRecordingVideo"
      :height="heightRecordingVideo"
      autoplay
      playsinline
      hidden
      muted
    />
    <video
      id="video-output"
      ref="videoOutput"
      :width="widthRecordingVideo"
      :height="heightRecordingVideo"
      autoplay
      playsinline
      muted
      onload="runRecord"
      @loadeddata="onVideoLoadedData"
    />
    <canvas
      id="canvas-output"
      ref="canvasOutput"
      hidden
      :width="widthRecordingVideo"
      :height="heightRecordingVideo"
    />
    <div
      v-if="mediaRecorder"
      class="d-flex align-items-center justify-content-center position-relative"
    >
      <div
        v-if="timeBegan"
        class="mt-4 me-3"
      >
        {{ time }}
      </div>
      <div
        v-if="mediaRecorderState === 'recording'"
        class="mt-4 recording-svg"
      />
      <button
        v-if="mediaRecorderState !== 'recording'"
        class="btn btn-purple-gradient text-bold mt-4"
        @click="startRecording(); start()"
      >
        Démarrer l'enregistrement
      </button>
      <button
        v-else
        class="btn btn-purple-gradient text-bold mt-4"
        @click="stopRecording(); stop()"
      >
        Stopper l'enregistrement
      </button>
      <span
        v-if="loading && mediaRecorderState !== 'recording'"
        class="spinner-border spinner-border-sm position-absolute bottom-0 end-0 mb-1 me-4"
        role="status"
        aria-hidden="true"
      />
      <div
        v-if="!loading && successMessage && mediaRecorderState !== 'recording'"
        class="text-green position-absolute bottom-0 end-0"
      >
        Vidéo enregistrée !
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref, defineExpose, onUnmounted,
} from 'vue';
import backgroundImage from '@/assets/images/record-background-1.jpg';
import { SelfieSegmentation } from '@mediapipe/selfie_segmentation';
import { Camera } from '@mediapipe/camera_utils';

// Component properties
const props = defineProps({
  updateMode: Boolean,
  successMessage: Boolean,
  loading: Boolean,
});

// Component emitters
const emit = defineEmits([
  'recordOutput',
]);

// Video recording variables
const canvasOutput = ref(null);
const videoSource = ref(null);
const videoOutput = ref(null);
const showRecord = ref(false);
const widthRecordingVideo = ref(0);
const heightRecordingVideo = ref(0);
const recording = ref(false);

let videoStream = null;

// Duration variables
const time = ref('00:00:00');
const timeBegan = ref(null);
let timeStopped = null;
let stoppedDuration = 0;
let started = null;
let running = false;

// Media recorder variables
const mediaRecorder = ref(null);
const mediaRecorderState = ref(null);

defineExpose({
  videoSource, videoOutput, canvasOutput, recording,
});

const recordingError = ref(false);
const recordingErrorMessage = ref('');
const setError = (message) => {
  recordingError.value = true;
  recordingErrorMessage.value = message;
};

const selectedBackground = new Image();
selectedBackground.src = backgroundImage;

// Timer section
const zeroPrefix = (num, digit) => {
  let zero = '';
  for (let i = 0; i < digit; i++) {
    zero += '0';
  }
  return (zero + num).slice(-digit);
};

function clockRunning() {
  const currentTime = new Date();
  const timeElapsed = new Date(currentTime - timeBegan.value - stoppedDuration);
  const hour = timeElapsed.getUTCHours();
  const min = timeElapsed.getUTCMinutes();
  const sec = timeElapsed.getUTCSeconds();

  time.value = `${zeroPrefix(hour, 2)}:${
    zeroPrefix(min, 2)}:${
    zeroPrefix(sec, 2)}`;
}

function reset() {
  running = false;
  clearInterval(started);
  stoppedDuration = 0;
  timeBegan.value = null;
  timeStopped = null;
  time.value = '00:00:00';
}

function start() {
  if (running) return;

  if (timeBegan.value === null) {
    reset();
    timeBegan.value = new Date();
  }

  if (timeStopped !== null) {
    stoppedDuration += (new Date() - timeStopped);
  }

  started = setInterval(clockRunning, 10);
  running = true;
}

function stop() {
  running = false;
  timeStopped = new Date();
  clearInterval(started);
  reset();
}

let context = null;
let stream = null;

const stopTracks = () => {
  stream?.getTracks().forEach((track) => {
    track.stop();
  });
};

const startRecording = () => {
  if (mediaRecorder.value) {
    mediaRecorder.value.start();
    mediaRecorderState.value = mediaRecorder.value.state;
  }
};

const stopRecording = () => {
  if (mediaRecorder.value && (mediaRecorder.value.state !== 'inactive')) {
    mediaRecorder.value.stop();
    mediaRecorderState.value = mediaRecorder.value.state;
  }
};

const onVideoLoadedData = async () => {
  const mediaStream = await navigator.mediaDevices
    .getUserMedia({ audio: true, video: false });
  const audioTrack = await mediaStream.getTracks().filter((track) => track.kind === 'audio')[0];
  videoStream = canvasOutput.value.captureStream(60);
  videoStream.addTrack(audioTrack);

  mediaRecorder.value = new MediaRecorder(videoStream);
  mediaRecorder.value.ondataavailable = function (e) {
    const blob = new Blob([e.data]);
    emit('recordOutput', blob);
  };
};

const startVideoStream = async () => {
  stream = await navigator.mediaDevices
    .getUserMedia({
      // video: true,
      video: {
        width: {
          max: Math.round(window.screen.width * 0.9),
        },
        height: {
          max: Math.round(window.screen.width * (4 / 3)),
        },
      },
      audio: false,
    })
    .then(async (userMediaStream) => {
      const { height, width } = userMediaStream.getVideoTracks()[0].getSettings();
      widthRecordingVideo.value = width;
      heightRecordingVideo.value = height;
      videoSource.value.srcObject = userMediaStream;
      await videoSource.value.play();
      videoSource.value.hidden = true;

      videoOutput.value.srcObject = userMediaStream;
      await videoOutput.value.play();
    })
    .catch((err) => {
      setError("Impossible de démarrer l'enregistrement vidéo. Assurez-vous d'accepter la captation audio et vidéo");
      console.log(`Start video stream: ${err}`);
    });
};

// Transform output section
const clearCanvas = () => {
  context.clearRect(0, 0, canvasOutput.value.width, canvasOutput.value.height);
};

const drawSegmentationMask = (segmentation) => {
  context.drawImage(segmentation, 0, 0, canvasOutput.value.width, canvasOutput.value.height);
};

const blurBackground = (image, blurAmount) => {
  context.globalCompositeOperation = 'destination-over';
  context.filter = `blur(${blurAmount}px)`;
  context.drawImage(image, 0, 0, canvasOutput.value.width, canvasOutput.value.height);
};

const runPostProcessing = (image, segmentation, blurAmount) => {
  clearCanvas();

  context.globalCompositeOperation = 'copy';
  context.filter = 'none';

  context.filter = 'blur(5px)';
  drawSegmentationMask(segmentation);
  context.globalCompositeOperation = 'source-in';
  context.filter = 'none';

  context.drawImage(image, 0, 0, canvasOutput.value.width, canvasOutput.value.height);

  blurBackground(selectedBackground, 0);

  context.restore();
};

let selfieSegmentation = null;

const onResults = (results) => {
  runPostProcessing(
    results.image,
    results.segmentationMask,
    5,
  );
};

const createSelfieSegmentation = () => {
  selfieSegmentation = new SelfieSegmentation({
    locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}`,
  });
  selfieSegmentation.setOptions({
    selfieMode: true,
    modelSelection: 0,
    effect: 'background',
  });
  selfieSegmentation.onResults(onResults);

  const camera = new Camera(videoSource.value, {
    onFrame: async () => {
      if (!selfieSegmentation) return;
      await selfieSegmentation.send({ image: videoSource.value });
    },
    width: widthRecordingVideo.value,
    height: heightRecordingVideo.value,
  });
  camera.start();

  videoOutput.value.hidden = true;
  canvasOutput.value.hidden = false;
};

const loadMediapipe = () => {
  if (selfieSegmentation) return;
  createSelfieSegmentation();
};

const setResultStream = async () => {
  const resultStream = canvasOutput.value.captureStream();
  videoOutput.value.srcObject = resultStream;
  await videoOutput.value.play();
};

const runRecord = async () => {
  recording.value = true;
  if (window.screen.width >= 1400) {
    widthRecordingVideo.value = 700;
    heightRecordingVideo.value = 525;
  } else if (window.screen.width >= 1200) {
    widthRecordingVideo.value = 500;
    heightRecordingVideo.value = 375;
  } else if (window.screen.width >= 992) {
    widthRecordingVideo.value = 400;
    heightRecordingVideo.value = 300;
  } else {
    widthRecordingVideo.value = 270;
    heightRecordingVideo.value = 203;
  }

  showRecord.value = true;
  context = canvasOutput.value.getContext('2d');

  await startVideoStream();
  loadMediapipe();
  await setResultStream();
};

onUnmounted(() => {
  stopTracks();
});

</script>
