<template>
  <div
    class="modal fade toggleModal"
    tabindex="-1"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content  bg-dark_grey">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ title }}
          </h5>
          <button
            type="button"
            class="btn-close bg-light"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div class="modal-body text-end">
          <div v-if="haveInput">
            <label class="col-form-label">{{ inputLabel }}</label>
            <div class="input-group has-validation">
              <textarea
                v-model="inputContent"
                type="text"
                :class="[{ 'invalid' : errors?.comment }, 'form-control', 'mb-3']"
                placeholder="Note"
                rows="5"
              />
            </div>
            <span
              v-if="errors?.comment"
              class="text-danger"
            >{{ errors?.comment[0] }}</span>
          </div>
          <button
            type="button"
            class="btn btn-outline-danger text-bold"
            data-bs-dismiss="modal"
            @click="emit('cancelCallback')"
          >
            Quitter
          </button>
          <button
            type="button"
            class="btn btn-purple-gradient text-bold ms-2"
            @click="emit('callback', inputContent)"
          >
            {{ actionButton }}
          </button>
        </div>
        <div class="modal-footer flex-column align-items-start">
          <div
            v-for="note in notes"
            :key="note.id"
          >
            <div>
              <div class="card-text-md text-start mt-3">
                {{ note?.author_first_name + ' ' + note?.author_second_name + ' - '
                  + normalizer.formatDate(note?.created_at) }}
              </div>
              <div class="bg-grey rounded-3 p-2 m-1 text-start">
                {{ note.comment }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import normalizer from '@/utils/normalizer';

const inputContent = ref('');

const props = defineProps({
  title: String,
  haveInput: Boolean,
  inputLabel: String,
  actionButton: String,
  notes: Array,
  errors: Object,
  emptyInput: Boolean,
});

const emit = defineEmits(['callback', 'cancelCallback', 'resetEmptyNoteInput']);

watch(() => props.emptyInput, (value) => {
  if (value) {
    inputContent.value = null;
    emit('resetEmptyNoteInput');
  }
});

</script>
