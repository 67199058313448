import Api from './Api';

export default {
  async getAll() {
    return Api.get('api/admin/list');
  },
  async create(data) {
    return Api.post('api/admin/create', data);
  },
};
