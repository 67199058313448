<template>
  <router-link
    :to="{ name: 'CompanyApplicationDetail',
           params: { offer_id: route.params.id,
                     matching_id: data?.matching_id } }"
  >
    <div class="card overflow-hidden">
      <img
        :src="`${VITE_API_URL}/${data?.picture}`"
        class="card-img-top fit-cover"
        alt="Photo de profil"
      >
      <div class="card-body d-flex flex-column justify-content-between">
        <div>
          <h4 class="card-text text-bold text-center">
            {{ data.first_name }} <br> {{ data.second_name }}
          </h4>
          <div
            v-if="data.recruiter_step === 'new'"
            class="card-text mt-4"
          >
            Date de candidature: {{ normalizer.formatDate(data?.dates.u_acceptation_date) }}
          </div>
          <div
            v-if="data.recruiter_step === 'accepted'"
            class="card-text mt-4"
          >
            Date de sélection: {{ normalizer.formatDate(data?.dates.r_acceptation_date) }}
          </div>
          <div
            v-if="data.recruiter_step === 'refused'"
            class="card-text mt-4"
          >
            Date de refus: {{ normalizer.formatDate(data?.dates.r_refusal_date) }}
          </div>
          <div
            v-if="data.recruiter_step === 'recruited'"
            class="card-text mt-4"
          >
            Date de recrutement: {{ normalizer.formatDate(data?.dates.recruitment_date) }}
          </div>
        </div>
        <div>
          <div class="mt-2 text-end">
            <div
              v-if="data.recruiter_step === 'new'"
              class="badge bg-blue"
            >
              Nouvelle candidature
            </div>
            <div
              v-if="data.recruiter_step === 'accepted'"
              class="badge bg-green"
            >
              Candidature shortlistée
            </div>
            <div
              v-if="data.recruiter_step === 'refused'"
              class="badge bg-danger"
            >
              Candidature refusée
            </div>
            <div
              v-if="data.recruiter_step === 'recruted'"
              class="badge bg-green"
            >
              Candidat recruté
            </div>
          </div>
          <hr class="w-150 ms-n5">
          <span
            class="score-bar-progress-label"
            :style="[{ 'left': [data.score === 100 ? (data.score*250/100 - 88) :
                       (data.score*250/100 - 75) > 0 ?
                         (data.score*250/100 - 75) :
                         5]+ 'px' },
                     [data.score > 75 ? { 'color': '#63c555' }
                       : [ data.score > 50 ? { 'color': '#ebfa74' }
                         : { 'color': '#f05745' }] ]] "
          >MATCHING {{ data.score }}%</span>
          <div class="progress score-bar-progress">
            <div
              class="progress-bar"
              role="progressbar"
              :style="[ { 'width': data.score + '%' },
                        [data.score > 75 ? { 'background-color': '#63c555' }
                          : [ data.score > 50 ? { 'background-color': '#ebfa74' }
                            : { 'background-color': '#f05745' }] ] ]"
              :aria-valuenow="data.score"
              aria-valuemin="0"
              aria-valuemax="100"
            />
          </div>
          <div />
        </div>
      </div>
    </div>
  </router-link>
</template>
<script setup>
import normalizer from '@/utils/normalizer';
import { useRoute } from 'vue-router';

const { VITE_API_URL } = import.meta.env;
const route = useRoute();
const props = defineProps({
  data: Object,
});

</script>
